import React from "react";
import {
  Box,
  Button,
  Drawer,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  ADMIN_NAV_ITEMS,
  CONTRACTOR_NAV_ITEMS,
  OEM_NAV_ITEMS,
} from "@/constants";
import { NavLink } from "react-router-dom";
import { useAuthStore } from "@/store/authStore.ts";
import { StyledIconButton } from "@/components/styled";
import SvgBurgerMenu from "@/assets/icons/SVGBurgerMenu.tsx";

export const NavigationWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "10px",
  overflowX: "auto",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    alignItems: "center",
  },
}));

const Navigation = () => {
  const role = useAuthStore((state) => state.role);
  const trainingCompleted = useAuthStore((state) => state.trainingCompleted);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const NavigationList = (
    <NavigationWrapperBox>
      {role === "administrator" &&
        ADMIN_NAV_ITEMS.map((el, i) => (
          <NavLink key={i} to={el.navTo}>
            {({ isActive }) => (
              <Button
                variant={"navigation"}
                sx={{ ...(isActive && { backgroundColor: "#005492" }) }}
              >
                {el?.label}
              </Button>
            )}
          </NavLink>
        ))}
      {role === "contractor" &&
        trainingCompleted &&
        CONTRACTOR_NAV_ITEMS.map((el, i) => (
          <NavLink key={i} to={el.navTo}>
            {({ isActive }) => (
              <Button
                variant={"navigation"}
                sx={{ ...(isActive && { backgroundColor: "#005492" }) }}
              >
                {el?.label}
              </Button>
            )}
          </NavLink>
        ))}
      {role === "oem" &&
        OEM_NAV_ITEMS.map((el, i) => (
          <NavLink key={i} to={el.navTo}>
            {({ isActive }) => (
              <Button
                variant={"navigation"}
                sx={{ ...(isActive && { backgroundColor: "#005492" }) }}
              >
                {el?.label}
              </Button>
            )}
          </NavLink>
        ))}
    </NavigationWrapperBox>
  );

  if (downMd) {
    return (
      <>
        <StyledIconButton onClick={toggleDrawer(true)}>
          <SvgBurgerMenu />
        </StyledIconButton>
        <Drawer
          PaperProps={{ sx: { padding: "16px", backgroundColor: "#003257" } }}
          open={open}
          onClose={toggleDrawer(false)}
        >
          {NavigationList}
        </Drawer>
      </>
    );
  } else {
    return NavigationList;
  }
};

export default Navigation;
