import React from "react";

const SvgStatisticArrow = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.3125 10.4999L5.25 6.56242L7.76208 9.0745C8.46423 7.69299 9.62743 6.53459 11.1535 5.85515L12.7522 5.14336M12.7522 5.14336L9.28645 3.81299M12.7522 5.14336L11.4218 8.6091"
        stroke="#005B68"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgStatisticArrow;
