import React, { useState } from "react";
import FormInput from "@/components/FormInput";
import { Box, Button, Typography } from "@mui/material";
import { StyledLabel } from "@/components/styled";
import SVGDots from "@/assets/icons/SVGDots.tsx";
import NoteActionsMenu from "@/components/TotalBalance/NoteActionsMenu.tsx";
import { useForm } from "react-hook-form";
import SVGCreatedAt from "@/assets/icons/SVGCreatedAt.tsx";
import SVGUpdatedAt from "@/assets/icons/SVGUpdatedAt.tsx";

type Props = {
  isNote: boolean;
  currentNote: string | null;
  handleDeleteNote: () => void;
  handleSaveNote: (values: { note: string }) => void;
  dateAndTime?: string;
  updatedAt?: string;
};
const EditableNote = ({
  dateAndTime,
  updatedAt,
  isNote,
  currentNote,
  handleDeleteNote,
  handleSaveNote,
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const { handleSubmit, control, setValue, watch } = useForm<{
    note: string | null;
  }>({ defaultValues: { note: currentNote } });
  const noteValue = watch("note");
  const [editMode, setEditMode] = useState(false);

  const onDeleteNote = () => {
    handleDeleteNote();
    setValue("note", "");
    handleCloseMenu();
  };

  const onSaveNote = (values: { note: any }) => {
    handleSaveNote(values);
    setEditMode(false);
  };

  const handleUpdateNote = () => {
    setEditMode(true);
    setValue("note", `${currentNote}`);
    handleCloseMenu();
  };

  return (
    <>
      {!isNote || editMode ? (
        <>
          <FormInput
            name={"note"}
            control={control}
            label={"Note"}
            placeholder={"Add note..."}
            multiline
            rows={4}
          />
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "flex-end",
            }}
          >
            {isNote && (
              <Button
                onClick={() => {
                  setEditMode(false);
                  setValue("note", currentNote);
                }}
                variant={"outlined"}
              >
                Cancel
              </Button>
            )}
            <Button
              disabled={!noteValue}
              onClick={handleSubmit(onSaveNote)}
              variant={"contained"}
            >
              Save
            </Button>
          </Box>
        </>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              gap: "8px",
              justifyContent: "space-between",
            }}
          >
            <StyledLabel>Note</StyledLabel>
            <Button
              onClick={handleOpenMenu}
              sx={{ padding: 0, minWidth: "initial" }}
              variant={"text"}
            >
              <SVGDots />
            </Button>
          </Box>
          <Box sx={{ marginTop: "8px", maxHeight: "120px", overflowY: "auto" }}>
            <Typography variant={"body1Medium"}>{currentNote}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              marginTop: "8px",
            }}
          >
            {dateAndTime && (
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <SVGCreatedAt />
                <Typography
                  variant={"subtitleRegular"}
                  sx={{ fontSize: "12px" }}
                >
                  {dateAndTime}
                </Typography>
              </Box>
            )}
            {updatedAt && (
              <Box sx={{ display: "flex", gap: "4px", alignItems: "center" }}>
                <SVGUpdatedAt />
                <Typography
                  variant={"subtitleRegular"}
                  sx={{ fontSize: "12px" }}
                >
                  {updatedAt}
                </Typography>
              </Box>
            )}
          </Box>
          <NoteActionsMenu
            currentNote={currentNote}
            handleUpdateNote={handleUpdateNote}
            anchorEl={anchorEl}
            openMenu={openMenu}
            handleCloseMenu={handleCloseMenu}
            onDeleteNote={onDeleteNote}
          />
        </Box>
      )}
    </>
  );
};

export default EditableNote;
