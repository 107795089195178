const SvgUpdatedAt = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 8.25C7.56385 8.25 6.38775 7.07443 6.38775 5.63775H4.88775C4.88775 7.90307 6.73565 9.75 9 9.75V8.25ZM6.38775 5.63775C6.38775 4.19369 7.5712 3 9 3V1.5C6.7283 1.5 4.88775 3.37981 4.88775 5.63775H6.38775ZM9 3C10.4361 3 11.6122 4.17557 11.6122 5.61225H13.1122C13.1122 3.34693 11.2644 1.5 9 1.5V3ZM11.6122 5.61225C11.6122 7.05631 10.4288 8.25 9 8.25V9.75C11.2717 9.75 13.1122 7.87019 13.1122 5.61225H11.6122ZM14.25 15H3.75V16.5H14.25V15ZM3.75 15H2.25C2.25 15.8267 2.92329 16.5 3.75 16.5V15ZM3.75 15V14.25H2.25V15H3.75ZM3.75 14.25C3.75 13.0142 4.76421 12 6 12V10.5C3.93579 10.5 2.25 12.1858 2.25 14.25H3.75ZM6 12H12V10.5H6V12ZM12 12C13.2358 12 14.25 13.0142 14.25 14.25H15.75C15.75 12.1858 14.0642 10.5 12 10.5V12ZM14.25 14.25V15H15.75V14.25H14.25ZM14.25 15V16.5C15.0767 16.5 15.75 15.8267 15.75 15H14.25Z"
        fill="#888684"
      />
    </svg>
  );
};

export default SvgUpdatedAt;
