import { create } from "zustand";
import { FileRecord, LogType, SessionType, UserType } from "@/types";
import {
  getAllLogsReq,
  getUnauthorizedReq,
  getUserLogsReq,
  getUserReq,
  getUserSessionsReq,
  getUsersIdsReq,
  getUsersReq,
} from "@/api/adminApi";
import { DateRange } from "rsuite/DateRangePicker";
import {
  getAllUploadedFilesReq,
  getAllUsersUploadedFilesReq,
  getUserUploadedFilesReq,
} from "@/api/fileApi";
import { getTemplatesReq } from "@/api/templateApi";
import { getRequestsLogsReq } from "@/api/requestsLogsApi";

type State = {
  users: {
    users: UserType[];
    totalUsersCount: number;
  };
  allLogs: {
    allLogs: LogType[];
    totalLogsCount: number;
  };
  unauthorizedLogs: {
    unauthorizedLogs: LogType[];
    totalUnauthorizedCount: number;
  };
  userLogs: {
    userLogs: LogType[];
    totalUserLogsCount: number;
  };
  userSessions: {
    userSessions: SessionType[];
    totalSessionsCount: number;
  };
  userUploadedFiles: {
    userUploadedFiles: FileRecord[];
    totalUserUploadedFiles: number;
  };
  allUploadedFiles: {
    allUploadedFiles: FileRecord[];
    totalUploadedFiles: number;
  };
  templateFiles: {
    templateFiles: FileRecord[];
    totalTemplateFilesCount: number;
  };
  requestsLogs: {
    requestsLogs: LogType[];
    totalRequestsLogs: number;
  };
  getUsersIds: (search?: string, filter?: string[], sort?: string) => void;
  user: UserType;
  profileCompletion: string;
  usersIds: number[];
};

type Actions = {
  getUsers: (
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
  ) => void;
  getAllLogs: (
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
    dateRangeValue: DateRange | null,
  ) => void;
  getUserLogs: (
    userId: UserType["id"],
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
    dateRangeValue: DateRange | null,
  ) => void;
  getUnauthorizedLogs: (
    page: number,
    pageLimit: number,
    search: string,
  ) => void;
  getUserSessions: (
    userId: UserType["id"],
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
  ) => void;
  getUserUploadedFiles: (
    userId: UserType["id"],
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
    dateRangeValue: DateRange | null,
  ) => void;
  getAllUploadedFiles: (
    page: number,
    pageLimit: number,
    search: string,
    sort: string,
    filter: string[],
    dateRangeValue: DateRange | null,
  ) => void;
  getAllUsersUploadedFiles: (
    page: number,
    pageLimit: number,
    search: string,
    sort: string,
    filter: string[],
    dateRangeValue: DateRange | null,
  ) => void;
  getTemplateFiles: (
    page: number,
    pageLimit: number,
    search: string,
    sort: string,
    filter: string[],
    dateRangeValue: DateRange | null,
  ) => void;
  getRequestsLogs: (
    page: number,
    pageLimit: number,
    search: string,
    filter: string[],
    sort: string,
  ) => void;
  getUser: (userId: UserType["id"]) => void;
  removeUser: (userId: UserType["id"]) => void;
  updateUsers: (
    userId: UserType["id"],
    updatedFields: Partial<UserType>,
  ) => void;
};

export const useAdminStore = create<State & Actions>((set) => ({
  users: { users: [], totalUsersCount: 0 },
  user: {} as UserType,
  profileCompletion: "",
  usersIds: [],
  allLogs: { allLogs: [], totalLogsCount: 0 },
  userLogs: { userLogs: [], totalUserLogsCount: 0 },
  unauthorizedLogs: {
    unauthorizedLogs: [],
    totalUnauthorizedCount: 0,
  },
  userSessions: {
    userSessions: [],
    totalSessionsCount: 0,
  },
  userUploadedFiles: {
    userUploadedFiles: [],
    totalUserUploadedFiles: 0,
  },
  allUploadedFiles: {
    allUploadedFiles: [],
    totalUploadedFiles: 0,
  },
  templateFiles: {
    templateFiles: [],
    totalTemplateFilesCount: 0,
  },
  requestsLogs: {
    requestsLogs: [],
    totalRequestsLogs: 0,
  },
  getUsers: async (page, pageLimit, search, filter, sort) => {
    try {
      const response = await getUsersReq(
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
      );
      set(() => ({
        users: {
          users: response?.data?.users,
          totalUsersCount: response?.data?.totalUsersCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUsersIds: async (search, filter, sort) => {
    try {
      const response = await getUsersIdsReq(search, filter, sort);
      set(() => ({
        usersIds: response.data?.usersIds,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getAllLogs: async (page, pageLimit, search, filter, sort, dateRangeValue) => {
    try {
      const response = await getAllLogsReq(
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
        dateRangeValue,
      );
      set(() => ({
        allLogs: {
          allLogs: response?.data?.logs,
          totalLogsCount: response?.data?.totalLogsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUserLogs: async (
    userId,
    page,
    pageLimit,
    search,
    filter,
    sort,
    dateRangeValue,
  ) => {
    try {
      const response = await getUserLogsReq(
        userId,
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
        dateRangeValue,
      );
      set(() => ({
        userLogs: {
          userLogs: response?.data?.logs,
          totalUserLogsCount: response?.data?.totalLogsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUserUploadedFiles: async (
    userId,
    page,
    pageLimit,
    search,
    filter,
    sort,
    dateRangeValue,
  ) => {
    try {
      const response = await getUserUploadedFilesReq(
        userId,
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
        dateRangeValue,
      );
      set(() => ({
        userUploadedFiles: {
          userUploadedFiles: response?.data?.files,
          totalUserUploadedFiles: response?.data?.totalFilesCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getAllUsersUploadedFiles: async (
    page,
    pageLimit,
    search,
    sort,
    filter,
    dateRangeValue,
  ) => {
    try {
      const response = await getAllUsersUploadedFilesReq(
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
        dateRangeValue,
      );
      set(() => ({
        userUploadedFiles: {
          userUploadedFiles: response?.data?.files,
          totalUserUploadedFiles: response?.data?.totalFilesCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getAllUploadedFiles: async (
    page,
    pageLimit,
    search,
    sort,
    filters,
    dateRangeValue,
  ) => {
    try {
      const response = await getAllUploadedFilesReq(
        `${page}`,
        pageLimit,
        search,
        sort,
        filters,
        dateRangeValue,
      );
      set(() => ({
        allUploadedFiles: {
          allUploadedFiles: response?.data?.projectDocumentations,
          totalUploadedFiles: response?.data?.totalProjectFilesCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getTemplateFiles: async (
    page,
    pageLimit,
    search,
    sort,
    filters,
    dateRangeValue,
  ) => {
    try {
      const response = await getTemplatesReq(
        `${page}`,
        pageLimit,
        search,
        sort,
        filters,
        dateRangeValue,
      );
      set(() => ({
        templateFiles: {
          templateFiles: response?.data?.templates,
          totalTemplateFilesCount: response?.data?.totalTemplatesCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUnauthorizedLogs: async (page, pageLimit, search) => {
    try {
      const response = await getUnauthorizedReq(`${page}`, pageLimit, search);
      set(() => ({
        unauthorizedLogs: {
          unauthorizedLogs: response?.data?.logs,
          totalUnauthorizedCount: response?.data?.totalLogsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUserSessions: async (
    userId: UserType["id"],
    page,
    pageLimit,
    search,
    filter,
    sort,
  ) => {
    try {
      const response = await getUserSessionsReq(
        userId,
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
      );
      set(() => ({
        userSessions: {
          userSessions: response.data?.sessions,
          totalSessionsCount: response.data?.totalSessionsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getRequestsLogs: async (page, pageLimit, search, filter, sort) => {
    try {
      const response = await getRequestsLogsReq(
        `${page}`,
        pageLimit,
        search,
        filter,
        sort,
      );
      set(() => ({
        requestsLogs: {
          requestsLogs: response.data?.logs,
          totalRequestsLogs: response.data?.totalLogsCount,
        },
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getUser: async (userId) => {
    try {
      const response = await getUserReq(userId);
      set(() => ({
        user: response?.data?.userData,
        profileCompletion: response?.data?.profileCompletion,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  removeUser: (userId) => {
    set((state) => ({
      users: {
        users: state?.users?.users?.filter((el) => el?.id !== userId),
        totalUsersCount: state?.users?.totalUsersCount - 1,
      },
    }));
  },
  updateUsers: (userId, updatedFields) => {
    set((state) => {
      const updatedUserList = state.users.users.map((user) =>
        user.id === userId ? { ...user, ...updatedFields } : user,
      );
      return {
        users: {
          ...state.users,
          users: updatedUserList,
        },
        user:
          state.user.id === userId
            ? { ...state.user, ...updatedFields }
            : state.user,
      };
    });
  },
}));
