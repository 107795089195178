import React from "react";

const SvgDataExchange = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5267 11.125V9.777C13.5267 8.934 12.8427 8.25 11.9997 8.25V8.25C11.1567 8.25 10.4727 8.934 10.4727 9.777V11.125"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 11.126H10.25C9.698 11.126 9.25 11.574 9.25 12.126V14.25C9.25 14.802 9.698 15.25 10.25 15.25H13.75C14.302 15.25 14.75 14.802 14.75 14.25V12.126C14.75 11.574 14.302 11.126 13.75 11.126Z"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 11.242C20 15.61 16.843 19.704 12.52 20.928C12.182 21.024 11.818 21.024 11.48 20.928C7.157 19.705 4 15.61 4 11.242V7.21399C4 6.40199 4.491 5.66999 5.243 5.36299L10.107 3.37299C11.321 2.87599 12.681 2.87599 13.894 3.37299L18.758 5.36299C19.509 5.66999 20 6.40199 20 7.21399V11.242Z"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgDataExchange;
