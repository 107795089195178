import React from "react";
import {
  Box,
  Button,
  Grid,
  styled,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Card from "@/components/Card";
import { StyledTableCell } from "@/components/styled";
import SVGUserGroup from "@/assets/icons/SVGUserGroup.tsx";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import SVGRadioCheckBox from "@/assets/icons/SVGRadioCheckBox.tsx";
import { PermissionCards } from "@/constants";
import RoleCard from "@/pages/admin-pages/UserManagementPage/components/RoleCard.tsx";

const RolesManagement = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid sx={{ width: "100%" }} item xs={12}>
      <Card>
        <Typography variant={"cardHeader2"}>Role Management</Typography>
        {!downLg && (
          <TableHead>
            <TableRow sx={{ display: "flex" }}>
              {["Permission", "Administrator", "Contractor", "OEM"].map(
                (el) => (
                  <StyledTableCell
                    key={el}
                    sx={{
                      width: "100%",
                      minWidth: el === "Permission" ? "320px" : "280px",
                    }}
                  >
                    {el}
                  </StyledTableCell>
                ),
              )}
            </TableRow>
          </TableHead>
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "24px",
            marginTop: "24px",
          }}
        >
          {PermissionCards.map((el) => (
            <RoleCard
              key={el?.title}
              icon={el.icon}
              title={el.title}
              permissions={el.permissions}
            />
          ))}
        </Box>
      </Card>
    </Grid>
  );
};

export default RolesManagement;
