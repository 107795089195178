import { FormControl, FormLabel, InputProps, Typography } from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { StyledInput } from "@/components/styled";
import { Currencies, Locales, useCurrencyFormat } from "input-currency-react";
import { ReactNode, useState } from "react";

type FormInputProps = InputProps & {
  name: string;
  control: Control<any, any>;
  label?: string;
  helperText?: ReactNode;
  controlProps?: Partial<ControllerProps>;
  defaultValue?: string;
};

const FormCurrencyInput = ({
  name,
  control,
  label,
  helperText,
  controlProps,
  defaultValue = "",
  ...props
}: FormInputProps) => {
  const [formattedValue, setFormattedValue] = useState(defaultValue);
  const isRequired = !!controlProps?.rules?.required;

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: "24px" }}
    >
      <FormLabel component="legend">
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: (theme) => theme.palette.textGray1,
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Typography>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) => (
          <CustomCurrencyInput
            onChangeEvent={(_: any, maskedValue: any, value: any) => {
              // Set max allowed value
              if (value?.length <= 15) {
                onChange(value);
                setFormattedValue(maskedValue);
              }
            }}
            options={{
              // style: "decimal",
              allowNegative: false,
              locale: Locales["English (United States)"],
              i18nCurrency: Currencies["US Dollar"],
            }}
            value={formattedValue}
            {...props}
          />
        )}
      />
      {helperText}
    </FormControl>
  );
};

export default FormCurrencyInput;

const CustomCurrencyInput = ({ ...props }: any) => {
  const [formattedValue, handleOnChange, handleOnKeyDown] = useCurrencyFormat(
    props?.value,
    {
      ...props?.options,
      onChangeCallBack: props?.onChangeEvent,
    },
  );
  return (
    <StyledInput
      onChange={handleOnChange}
      onKeyDown={handleOnKeyDown}
      value={formattedValue}
      fullWidth
      {...props}
    />
  );
};
