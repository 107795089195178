export const getFileStorageBadgeVariant = (
  status: "pending" | "approved" | "deleted" | "rejected",
) => {
  if (status === "pending") {
    return { neutral: true };
  }
  if (status === "deleted") {
    return { danger: true };
  }
  if (status === "rejected") {
    return { yellow: true };
  }

  return {};
};
