import React, { SVGProps } from "react";

const SvgTagClose = ({ ...props }: SVGProps<any>) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00008 12.8332C10.2217 12.8332 12.8334 10.2215 12.8334 6.99984C12.8334 3.77818 10.2217 1.1665 7.00008 1.1665C3.77842 1.1665 1.16675 3.77818 1.16675 6.99984C1.16675 10.2215 3.77842 12.8332 7.00008 12.8332ZM5.55641 4.73116L7.0001 6.17486L8.44376 4.7312L9.26872 5.55616L7.82506 6.99981L9.26872 8.44347L8.44376 9.26843L7.0001 7.82477L5.55641 9.26847L4.73145 8.44351L6.17514 6.99981L4.73145 5.55612L5.55641 4.73116Z"
        fill="#4E4B48"
      />
    </svg>
  );
};

export default SvgTagClose;
