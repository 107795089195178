import React from "react";
import { useForm } from "react-hook-form";
import Modal from "@/components/Modal";
import FormInput from "@/components/FormInput";
import { Box, Button } from "@mui/material";

type Props = {
  setOpen: (rejectionRequest: null | any) => void;
  rejectionRequest: any;
};

const RejectDataRequestModal = ({ setOpen, rejectionRequest }: Props) => {
  const { handleSubmit, reset, control, watch } = useForm<{
    rejectReason: string;
  }>({
    mode: "onChange",
  });

  const rejectReason = watch("rejectReason");

  const handleClose = () => {
    setOpen(null);
    reset();
  };

  const onSubmit = async () => {
    try {
      // await rejectFileReq(data);
      // await changeFileStatusReq(file?.id, "rejected");
      // await getFilesReq();
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={!!rejectionRequest?.id}
      onClose={handleClose}
      title={`Reject request ${rejectionRequest?.id}`}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          marginTop: "24px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          name={"rejectReason"}
          control={control}
          label={"Write a reason for rejection"}
          placeholder={"Reason"}
          multiline
          rows={4}
          controlProps={{ rules: { required: "Reject reason is required" } }}
        />
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            gap: "8px",
            width: "100%",
          }}
        >
          <Button
            variant={"danger"}
            sx={{ width: "100%" }}
            disabled={!rejectReason}
            type={"submit"}
          >
            Reject
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default RejectDataRequestModal;
