const SvgActivityAdded = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 4C19.7956 4 20.5587 4.31607 21.1213 4.87868C21.6839 5.44129 22 6.20435 22 7V17C22 17.7956 21.6839 18.5587 21.1213 19.1213C20.5587 19.6839 19.7956 20 19 20H5C4.20435 20 3.44129 19.6839 2.87868 19.1213C2.31607 18.5587 2 17.7956 2 17V7C2 6.20435 2.31607 5.44129 2.87868 4.87868C3.44129 4.31607 4.20435 4 5 4H19ZM20 10H4V17C4.00003 17.2449 4.08996 17.4813 4.25272 17.6644C4.41547 17.8474 4.63975 17.9643 4.883 17.993L5 18H19C19.2449 18 19.4813 17.91 19.6644 17.7473C19.8474 17.5845 19.9643 17.3603 19.993 17.117L20 17V10ZM17 13C17.2549 13.0003 17.5 13.0979 17.6854 13.2728C17.8707 13.4478 17.9822 13.687 17.9972 13.9414C18.0121 14.1958 17.9293 14.4464 17.7657 14.6418C17.6021 14.8373 17.3701 14.9629 17.117 14.993L17 15H14C13.7451 14.9997 13.5 14.9021 13.3146 14.7272C13.1293 14.5522 13.0178 14.313 13.0028 14.0586C12.9879 13.8042 13.0707 13.5536 13.2343 13.3582C13.3979 13.1627 13.6299 13.0371 13.883 13.007L14 13H17ZM19 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7V8H20V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6Z"
        fill="#005B68"
      />
    </svg>
  );
};

export default SvgActivityAdded;
