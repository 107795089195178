import React, { ReactNode } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import bgLogo from "@/assets/sdcp-big-logo.svg";
import SVGBackArrow from "@/assets/icons/SVGBackArrow.tsx";
import Header from "@/components/Header";

const BoxLayout = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  minHeight: "100vh",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#F9F9F9",
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  // maxWidth: "1800px",
  width: "100%",
  flexDirection: "row",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const BoxFormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  marginLeft: "80px",
  marginRight: "24px",
  maxHeight: "100vh",
  flex: 2,
  justifyContent: "center",
  alignItems: "center",
  paddingBottom: "30px",
  flexDirection: "column",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "start",
    margin: "32px 24px 0 24px",
    flex: 1,
  },
}));

const BoxImageWrapper = styled(Box)(() => ({
  display: "flex",
  flex: 3,
  padding: "8px",
  justifyContent: "center",
  alignItems: "center",
}));

const BoxImage = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  height: "100%",
  borderRadius: "40px",
  backgroundColor: "#E6F1FA",
  padding: "0 20px",
  justifyContent: "center",
  alignItems: "center",
  // maxHeight: "1060px",
}));

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  height: "100%",
  [theme.breakpoints.down("lg")]: {
    justifyContent: "start",
  },
}));

const BoxBackButtonWrapper = styled(Box)(({ theme }) => ({
  alignItems: "center",
  flexGrow: 1,
  width: "100%",
  paddingTop: "115px",
  paddingBottom: "15px",
  [theme.breakpoints.down("lg")]: {
    flexGrow: 0,
    padding: "20px 0",
    marginTop: "-32px",
  },
}));

const BoxBackButton = styled(Box)(() => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  width: "100%",
  cursor: "pointer",
}));

const AuthLayout = ({
  children,
  onBackButtonClick,
  backButtonText,
}: {
  children: ReactNode;
  onBackButtonClick?: () => void;
  backButtonText?: string;
}) => {
  const theme = useTheme();

  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <BoxLayout>
      <BoxContainer>
        {matchesDownLg && <Header />}
        <BoxFormWrapper>
          {onBackButtonClick && (
            <BoxBackButtonWrapper>
              <BoxBackButton onClick={onBackButtonClick}>
                <SVGBackArrow />
                <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                  {backButtonText}
                </Typography>
              </BoxBackButton>
            </BoxBackButtonWrapper>
          )}
          <ContentWrapper>{children}</ContentWrapper>
        </BoxFormWrapper>
        {!matchesDownLg && (
          <BoxImageWrapper>
            <BoxImage>
              <img style={{ width: "100%" }} src={bgLogo} alt={"#"} />
            </BoxImage>
          </BoxImageWrapper>
        )}
      </BoxContainer>
    </BoxLayout>
  );
};

export default AuthLayout;
