import React, { useEffect, useMemo } from "react";
import {
  Grid,
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledBadge,
  StyledRightActionButton,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import TablePagination from "@/components/TablePagination";
import useTablePagination from "@/hooks/useTablePagination.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import LogDetailModal from "@/pages/admin-pages/UserManagementPage/components/LogDetailModal.tsx";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import { createLogData } from "@/utils/createLogData.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import TableFilter from "@/components/TableFilter";
import {
  COMMON_SORT_ITEMS,
  LOGS_FILTER_ITEMS,
  LogsCSVHeaders,
} from "@/constants";
import TableSort from "@/components/TableSort";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";
import CommonTableActions from "@/components/CommonTableActions";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import { formatDate } from "@/utils/formatDate.ts";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

const AllLogsTable = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { allLogs, totalLogsCount } = useAdminStore((state) => state.allLogs);
  const getAllLogs = useAdminStore((state) => state.getAllLogs);

  const rows = useMemo(() => {
    return allLogs.map((el) => createLogData(el));
  }, [allLogs]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalLogsCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { dateRangeValue, handleDateRangeChange } = useDateRange();

  useEffect(() => {
    fetchDataWithLoading(getAllLogs, [
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      sort,
      dateRangeValue,
    ]);
  }, [
    page,
    rowsPerPage,
    searchValue,
    filters,
    sort,
    dateRangeValue,
    getAllLogs,
    fetchDataWithLoading,
  ]);
  return (
    <Grid sx={{ width: "100%" }} item xs={12}>
      <StyledTableCard>
        <CommonTableActions
          title={"Access Logs"}
          onDateRange={handleDateRangeChange}
          onSearch={handleDebounceSearchValue}
          filter={
            <TableFilter
              placeholder={"Result"}
              items={LOGS_FILTER_ITEMS}
              setFilterValues={handleFilterChange}
            />
          }
          sort={
            <TableSort
              placeholder={"Sort"}
              items={COMMON_SORT_ITEMS}
              setSortValue={handleSortChange}
            />
          }
          rightActions={
            <StyledRightActionButton
              onClick={() =>
                downloadCSV(transformLogToCSV(allLogs), "logs", LogsCSVHeaders)
              }
              startIcon={<SVGUpload />}
              sx={{
                whiteSpace: "nowrap",
                ...(!downLg && { minWidth: "240px" }),
              }}
              variant={"contained"}
            >
              {!downLg ? "Download CSV report" : "Report"}
            </StyledRightActionButton>
          }
        />
        <TableContainer>
          <StyledTable aria-label="customized table">
            <StyledTableHeader>
              <TableRow>
                <StyledTableCell>Log ID</StyledTableCell>
                <StyledTableCell>Account name</StyledTableCell>
                <StyledTableCell>IP Address</StyledTableCell>
                <StyledTableCell>Location</StyledTableCell>
                <StyledTableCell>Access type</StyledTableCell>
                <StyledTableCell>Result</StyledTableCell>
                <StyledTableCell>Time</StyledTableCell>
                <StyledTableCell>Event Log</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {rows.map((row) =>
                downSm ? (
                  <CollapsableRow
                    key={row.id}
                    title={row.logType}
                    firstSub={row.accountName}
                    secondSub={
                      <StyledBadge
                        sx={{ display: "inline", textWrap: "nowrap" }}
                        {...getLogStatusBadgeVariant(row.status ?? "")}
                      >
                        {getLogStatusBadgeLabel(row.status ?? "")}
                      </StyledBadge>
                    }
                  >
                    <CellWithHeader header={"Log ID"}>{row.id}</CellWithHeader>
                    <CellWithHeader header={"IP Address"}>
                      {row.ipAddress}
                    </CellWithHeader>
                    <CellWithHeader header={"Location"}>
                      {row.location}
                    </CellWithHeader>
                    <CellWithHeader header={"Time"}>
                      {formatDate(row.timestamp ?? "")}
                    </CellWithHeader>
                    <CellWithHeader header={"Event log"}>
                      {row.message}
                    </CellWithHeader>
                    <LogDetailModal
                      log={row}
                      getLogs={() =>
                        getAllLogs(
                          page + 1,
                          rowsPerPage,
                          searchValue,
                          filters,
                          sort,
                          dateRangeValue,
                        )
                      }
                    />
                  </CollapsableRow>
                ) : (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.id}</StyledTableCell>
                    <StyledTableCell>{row.accountName}</StyledTableCell>
                    <StyledTableCell>{row.ipAddress}</StyledTableCell>
                    <StyledTableCell>{row.location}</StyledTableCell>
                    <StyledTableCell>{row.logType}</StyledTableCell>
                    <StyledTableCell>
                      <StyledBadge
                        sx={{ display: "inline", textWrap: "nowrap" }}
                        {...getLogStatusBadgeVariant(row.status ?? "")}
                      >
                        {getLogStatusBadgeLabel(row.status ?? "")}
                      </StyledBadge>
                    </StyledTableCell>
                    <StyledTableCell>{row.timestamp}</StyledTableCell>
                    <StyledTableCell>{row.message}</StyledTableCell>
                    <StyledTableCell>
                      <LogDetailModal
                        log={row}
                        getLogs={() =>
                          getAllLogs(
                            page + 1,
                            rowsPerPage,
                            searchValue,
                            filters,
                            sort,
                            dateRangeValue,
                          )
                        }
                      />
                    </StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          totalCount={totalLogsCount}
        />
      </StyledTableCard>
    </Grid>
  );
};

export default AllLogsTable;
