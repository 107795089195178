import React from "react";
import { FileRecord, ProjectRecord, UserType } from "@/types";
import { StyledIconButton } from "@/components/styled";
import SVGTrashCan from "@/assets/icons/SVGTrashCan.tsx";
import {
  deleteProjectFileReq,
  restoreProjectFileReq,
  uploadProjectFileReq,
} from "@/api/projectsApi";
import SVGRefresh from "@/assets/icons/SVGRefresh.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useMediaQuery, useTheme } from "@mui/material";

const FileManagementRowActions = ({
  status,
  rowId,
  getFilesReq,
}: {
  status: FileRecord["status"];
  rowId: number;
  getFilesReq: () => void;
}) => {
  const role = useAuthStore((state) => state.role);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const restoreFile = async (fileId: FileRecord["id"]) => {
    try {
      await restoreProjectFileReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };

  const deleteFile = async (fileId: FileRecord["id"]) => {
    try {
      await deleteProjectFileReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };

  if (role === "administrator") {
    if (status === "deleted") {
      return (
        <StyledIconButton
          sx={{ ...(downSm && { flex: 1 }) }}
          onClick={() => {
            restoreFile(rowId);
          }}
          variant={!downSm ? "text" : "outlined"}
        >
          {!downSm ? <SVGRefresh /> : "Restore"}
        </StyledIconButton>
      );
    } else {
      return (
        <StyledIconButton
          sx={{ ...(downSm && { flex: 1 }) }}
          onClick={() => {
            deleteFile(rowId);
          }}
          variant={!downSm ? "text" : "dangerOutlined"}
        >
          {!downSm ? <SVGTrashCan /> : "Delete"}
        </StyledIconButton>
      );
    }
  }

  if (role === "contractor" || role === "oem") {
    if (status === "pending") {
      return (
        <StyledIconButton
          sx={{ ...(downSm && { flex: 1 }) }}
          onClick={() => {
            deleteFile(rowId);
          }}
          variant={!downSm ? "text" : "dangerOutlined"}
        >
          {!downSm ? <SVGTrashCan /> : "Delete"}
        </StyledIconButton>
      );
    }
  }
  return <></>;
};

export default FileManagementRowActions;
