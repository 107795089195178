import React, { useState } from "react";
import {
  Box,
  Button,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StyledMenu, StyledTableActionButton } from "@/components/styled";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import SvgSimpleCheckMark from "@/assets/icons/SVGSimpleCheckMark.tsx";
import SVGSort from "@/assets/icons/SVGSort.tsx";
import SVGFilter from "@/assets/icons/SVGFilter.tsx";

type Props = {
  placeholder: string;
  items: { value: string; label: string }[];
  setSortValue: (value: string) => void;
};

const TableSort = ({ placeholder, items, setSortValue }: Props) => {
  const [selectedItem, setSelectedItem] = useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (value: string) => {
    const newSelectedItem = selectedItem === value ? "" : value;
    setSelectedItem(newSelectedItem);
    setSortValue(newSelectedItem);
    handleClose();
  };

  const isSelected = (value: string) => selectedItem === value;

  return (
    <>
      <StyledTableActionButton
        onClick={handleClick}
        variant={selectedItem ? "outlined" : "outlinedGray"}
      >
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <SVGSort />
          {!downSm && (
            <Typography
              variant={"subtitleRegular"}
              sx={{
                color: "textLightGray2",
                whiteSpace: "nowrap",
              }}
            >
              {placeholder}
            </Typography>
          )}
        </Box>
      </StyledTableActionButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ "& .MuiPaper-root": { minWidth: "310px" } }}
      >
        {items.map((el) => {
          return (
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              key={el.value}
              onClick={() => handleClickItem(el.value)}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#4E4B48" }}
                >
                  {capitalizeFirstLetter(el.label ?? "")}
                </Typography>
              </Box>
              {isSelected(el.value) && <SvgSimpleCheckMark color={"#0076CE"} />}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default TableSort;
