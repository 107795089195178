import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";

export const getMappedFileType = (fileType: string) => {
  const fileTypeMap: { [key: string]: string } = {
    participantEnrollment: "Participant Enrollment",
    systemSizingTool: "System Sizing Tool",
  };

  return capitalizeFirstLetter(fileTypeMap[fileType] || fileType || "");
};
