import { TransactionType } from "@/types";
import { formatCurrency } from "@/utils/formatCurrency.ts";

export const getTransactionAmount = (
  amount: TransactionType["amount"],
  type: TransactionType["type"],
) => {
  const formattedCurrency = formatCurrency(amount / 100);
  switch (type) {
    case "deduct":
      return `-${formattedCurrency}`;
    case "add":
      return `+${formattedCurrency}`;
    default:
      return formattedCurrency;
  }
};
