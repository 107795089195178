import React, { SVGProps } from "react";

const SvgSimpleCheckMark = ({ ...props }: SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.6599 4.55454C17.8161 4.71081 17.9039 4.92273 17.9039 5.1437C17.9039 5.36467 17.8161 5.5766 17.6599 5.73287L8.29158 15.102C8.20646 15.1872 8.10539 15.2548 7.99414 15.3009C7.8829 15.3469 7.76366 15.3707 7.64325 15.3707C7.52284 15.3707 7.4036 15.3469 7.29236 15.3009C7.18111 15.2548 7.08004 15.1872 6.99492 15.102L2.33992 10.447C2.26249 10.3696 2.20107 10.2777 2.15917 10.1765C2.11727 10.0754 2.0957 9.96695 2.0957 9.85745C2.0957 9.74796 2.11727 9.63953 2.15917 9.53837C2.20107 9.43721 2.26249 9.34529 2.33992 9.26787C2.41734 9.19044 2.50926 9.12903 2.61042 9.08712C2.71158 9.04522 2.82 9.02366 2.9295 9.02366C3.039 9.02366 3.14742 9.04522 3.24858 9.08712C3.34974 9.12903 3.44166 9.19044 3.51908 9.26787L7.64408 13.3929L16.4808 4.55454C16.637 4.39831 16.8489 4.31055 17.0699 4.31055C17.2909 4.31055 17.5036 4.39831 17.6599 4.55454Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgSimpleCheckMark;
