import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import FormInput from "@/components/FormInput";
import { useForm } from "react-hook-form";
import { loginReq } from "@/api/authApi";
import { useNavigate } from "react-router-dom";
import { PAGE_ROUTES } from "@/constants";
import { StyledTypographyLink } from "@/components/styled";
import {
  ResetPasswordSteps,
  useResetPasswordStepsStore,
} from "@/pages/ResetPasswordPage/store/resetPasswordStepsStore.ts";
import { useAuthStore } from "@/store/authStore.ts";

const TypographyLabel = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  color: theme.palette.textGray1,
  paddingBottom: "2px",
}));

const LoginForm = () => {
  const { handleSubmit, control, setError, watch } = useForm<{
    email: string;
    password: string;
  }>();
  const email = watch("email");
  const password = watch("password");

  const setVerificationEmail = useAuthStore(
    (state) => state.setVerificationEmail,
  );

  const setCurrentStep = useResetPasswordStepsStore(
    (state) => state.setCurrentStep,
  );

  const navigate = useNavigate();

  const handleSubmitForm = async (values: {
    email: string;
    password: string;
  }) => {
    try {
      await loginReq(values);
      setVerificationEmail(values?.email);
      navigate("/verification");
    } catch (e: any) {
      const status = e?.response?.status;
      if (status === 401 || status === 404) {
        setError("password", {
          message: "Incorrect Email or Password",
        });
        setError("email", { message: "" });
      } else {
        const errorMessage = e?.response?.data?.error;
        setError("password", {
          message: errorMessage ? errorMessage : e?.message,
        });
        setError("email", { message: "" });
      }
    }
  };

  const handleForgotPasswordClick = () => {
    setCurrentStep(ResetPasswordSteps.GetLink);
    navigate("/password-reset");
  };
  return (
    <Box
      sx={{
        marginTop: "40px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <FormInput
        name={"email"}
        label={"Email"}
        placeholder={"Enter your email"}
        control={control}
      />
      <FormInput
        name={"password"}
        label={
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              width: "100%",
            }}
          >
            <TypographyLabel>Password</TypographyLabel>
            <StyledTypographyLink onClick={handleForgotPasswordClick}>
              Forgot password?
            </StyledTypographyLink>
          </Box>
        }
        placeholder={"Enter your password"}
        control={control}
        type={"password"}
      />
      <Button
        disabled={!email?.length || !password?.length}
        sx={{ width: "100%", marginTop: "24px" }}
        onClick={handleSubmit(handleSubmitForm)}
        variant={"contained"}
      >
        Log in
      </Button>
    </Box>
  );
};

export default LoginForm;
