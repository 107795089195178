import React from "react";

const SvgUpload = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1344_66576)">
        <path
          d="M20 15.0001C20.2652 15.0001 20.5196 15.1055 20.7071 15.293C20.8946 15.4806 21 15.7349 21 16.0001V20.0001C21 20.5306 20.7893 21.0393 20.4142 21.4144C20.0391 21.7894 19.5304 22.0001 19 22.0001H5C4.46957 22.0001 3.96086 21.7894 3.58579 21.4144C3.21071 21.0393 3 20.5306 3 20.0001V16.0001C3 15.7349 3.10536 15.4806 3.29289 15.293C3.48043 15.1055 3.73478 15.0001 4 15.0001C4.26522 15.0001 4.51957 15.1055 4.70711 15.293C4.89464 15.4806 5 15.7349 5 16.0001V20.0001H19V16.0001C19 15.7349 19.1054 15.4806 19.2929 15.293C19.4804 15.1055 19.7348 15.0001 20 15.0001ZM12.884 3.47414L16.95 7.54014C17.0455 7.63239 17.1217 7.74273 17.1741 7.86474C17.2265 7.98674 17.2541 8.11796 17.2553 8.25074C17.2564 8.38352 17.2311 8.5152 17.1808 8.6381C17.1305 8.76099 17.0563 8.87265 16.9624 8.96654C16.8685 9.06043 16.7568 9.13468 16.634 9.18497C16.5111 9.23525 16.3794 9.26055 16.2466 9.25939C16.1138 9.25824 15.9826 9.23065 15.8606 9.17824C15.7386 9.12584 15.6282 9.04965 15.536 8.95414L13 6.42014V16.0001C13 16.2654 12.8946 16.5197 12.7071 16.7073C12.5196 16.8948 12.2652 17.0001 12 17.0001C11.7348 17.0001 11.4804 16.8948 11.2929 16.7073C11.1054 16.5197 11 16.2654 11 16.0001V6.41914L8.464 8.95414C8.2754 9.1363 8.0228 9.2371 7.7606 9.23482C7.4984 9.23254 7.24759 9.12737 7.06218 8.94196C6.87677 8.75655 6.7716 8.50574 6.76933 8.24354C6.76705 7.98135 6.86784 7.72874 7.05 7.54014L11.116 3.47414C11.2321 3.35804 11.3699 3.26593 11.5216 3.20309C11.6732 3.14025 11.8358 3.10791 12 3.10791C12.1642 3.10791 12.3268 3.14025 12.4784 3.20309C12.6301 3.26593 12.7679 3.35804 12.884 3.47414Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1344_66576">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgUpload;
