import { create } from "zustand";

type TableSelectedRows = {
  selectedRows: Set<number>;
  isSelectAll: boolean;
};

type SelectedRowsStore = {
  tables: Record<string, TableSelectedRows>;
  selectRow: (tableId: string, id: number) => void;
  deselectRow: (tableId: string, id: number) => void;
  toggleSelectAll: (tableId: string) => void;
  isRowSelected: (tableId: string, id: number) => boolean;
  clearSelection: (tableId: string) => void;
  clearAllSelections: () => void;
  initializeTable: (tableId: string) => void;
};

export const useSelectedRowsStore = create<SelectedRowsStore>((set, get) => ({
  tables: {},

  initializeTable: (tableId) => {
    set((state) => {
      if (!state.tables[tableId]) {
        return {
          tables: {
            ...state.tables,
            [tableId]: { selectedRows: new Set<number>(), isSelectAll: false },
          },
        };
      }
      return state;
    });
  },

  selectRow: (tableId, id) =>
    set((state) => {
      const table = state.tables[tableId] || {
        selectedRows: new Set<number>(),
        isSelectAll: false,
      };
      const updatedRows = new Set(table.selectedRows);
      updatedRows.add(id);
      return {
        tables: {
          ...state.tables,
          [tableId]: {
            ...table,
            selectedRows: updatedRows,
            isSelectAll: false,
          },
        },
      };
    }),

  deselectRow: (tableId, id) =>
    set((state) => {
      const table = state.tables[tableId] || {
        selectedRows: new Set<number>(),
        isSelectAll: false,
      };
      const updatedRows = new Set(table.selectedRows);
      updatedRows.delete(id);
      return {
        tables: {
          ...state.tables,
          [tableId]: {
            ...table,
            selectedRows: updatedRows,
            isSelectAll: false,
          },
        },
      };
    }),

  toggleSelectAll: (tableId) =>
    set((state) => {
      const table = state.tables[tableId] || {
        selectedRows: new Set<number>(),
        isSelectAll: false,
      };
      table.isSelectAll = !table.isSelectAll;
      table.selectedRows = new Set<number>(); // Clear selected rows if select all is toggled
      return { tables: { ...state.tables, [tableId]: table } };
    }),

  isRowSelected: (tableId, id) => {
    const table = get().tables[tableId] || {
      selectedRows: new Set<number>(),
      isSelectAll: false,
    };
    return table.isSelectAll || table.selectedRows.has(id);
  },

  clearSelection: (tableId) =>
    set((state) => ({
      tables: {
        ...state.tables,
        [tableId]: { selectedRows: new Set<number>(), isSelectAll: false },
      },
    })),

  clearAllSelections: () => set({ tables: {} }),
}));
