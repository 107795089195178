import React from "react";

const SvgDownloadFile = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2714_70632)">
        <path
          d="M5 19C4.73478 19 4.48043 19.1054 4.29289 19.2929C4.10536 19.4804 4 19.7348 4 20C4 20.2652 4.10536 20.5196 4.29289 20.7071C4.48043 20.8946 4.73478 21 5 21H19C19.2652 21 19.5196 20.8946 19.7071 20.7071C19.8946 20.5196 20 20.2652 20 20C20 19.7348 19.8946 19.4804 19.7071 19.2929C19.5196 19.1054 19.2652 19 19 19H5ZM16.95 11.297C16.8571 11.204 16.7468 11.1303 16.6254 11.0799C16.504 11.0296 16.3739 11.0037 16.2425 11.0037C16.1111 11.0037 15.981 11.0296 15.8596 11.0799C15.7382 11.1303 15.6279 11.204 15.535 11.297L13 13.833V4C13 3.73478 12.8946 3.48043 12.7071 3.29289C12.5196 3.10536 12.2652 3 12 3C11.7348 3 11.4804 3.10536 11.2929 3.29289C11.1054 3.48043 11 3.73478 11 4V13.833L8.464 11.297C8.27636 11.1095 8.02192 11.0042 7.75665 11.0043C7.49138 11.0044 7.23701 11.1099 7.0495 11.2975C6.86199 11.4851 6.7567 11.7396 6.7568 12.0049C6.75689 12.2701 6.86236 12.5245 7.05 12.712L11.293 16.954C11.4805 17.1415 11.7348 17.2468 12 17.2468C12.2652 17.2468 12.5195 17.1415 12.707 16.954L16.95 12.712C17.043 12.6191 17.1167 12.5088 17.1671 12.3874C17.2174 12.266 17.2433 12.1359 17.2433 12.0045C17.2433 11.8731 17.2174 11.743 17.1671 11.6216C17.1167 11.5002 17.043 11.3899 16.95 11.297Z"
          fill="#0076CE"
        />
      </g>
      <defs>
        <clipPath id="clip0_2714_70632">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgDownloadFile;
