import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import SVGCalendar from "@/assets/icons/SVGCalendar.tsx";
import { formatDate } from "@/utils/formatDate.ts";
import {
  StyledIconButton,
  StyledTruncatedTypography,
} from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import dayjs from "dayjs";
import Modal from "@/components/Modal";
import EditArticle from "@/pages/NewsPage/components/EditArticle.tsx";
import ViewArticle from "@/pages/NewsPage/components/ViewArticle.tsx";
import DOMPurify from "dompurify";
import { ArticleType } from "@/types";
import { useAuthStore } from "@/store/authStore.ts";

type Props = {
  article: ArticleType;
};

const MAX_DESCRIPTION_LENGTH = 205;

const sanitizeAndStripHTML = (html: string) => {
  const sanitizedHTML = DOMPurify.sanitize(html);
  const div = document.createElement("div");
  div.innerHTML = sanitizedHTML;
  return div.textContent || div.innerText || "";
};

const truncateDescription = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + "...";
  }
  return text;
};

const NewsListItem = ({ article }: Props) => {
  const role = useAuthStore((state) => state.role);
  const isAdmin = role === "administrator";
  const { content, title, createdAt } = article;
  const plainTextDescription = truncateDescription(
    sanitizeAndStripHTML(content),
    MAX_DESCRIPTION_LENGTH,
  );

  const [openEditArticle, setOpenEditArticle] = useState(false);
  const [openViewArticle, setOpenViewArticle] = useState(false);

  const handleCloseEditArticle = () => setOpenEditArticle(false);
  const handleOpenEditArticle = () => setOpenEditArticle(true);
  const handleCloseViewArticle = () => setOpenViewArticle(false);

  return (
    <Box
      sx={{
        background: "#fff",
        border: "1px solid #EBECED",
        padding: "16px",
        borderRadius: "16px",
      }}
    >
      <StyledTruncatedTypography
        onClick={() => setOpenViewArticle(true)}
        sx={{
          color: "textGray5",
          fontWeight: 600,
          fontSize: "14px",
          cursor: "pointer",
        }}
      >
        {title}
      </StyledTruncatedTypography>{" "}
      <Typography
        sx={{
          color: "textLightGray5",
          fontWeight: 400,
          fontSize: "12px",
          marginTop: "12px",
        }}
      >
        {plainTextDescription}
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
          marginTop: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "8px",
            color: "textGray5",
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          <SVGCalendar />
          {dayjs(createdAt).tz("America/Los_Angeles").format("DD/MM/YY")}
        </Box>
        {isAdmin && (
          <StyledIconButton onClick={handleOpenEditArticle}>
            <SVGUserEdit />
          </StyledIconButton>
        )}
        <EditArticle
          article={article}
          open={openEditArticle}
          onClose={handleCloseEditArticle}
        />
        <ViewArticle
          article={article}
          open={openViewArticle}
          onClose={handleCloseViewArticle}
          handleOpenEditArticle={handleOpenEditArticle}
        />
      </Box>
    </Box>
  );
};

export default NewsListItem;
