import React, { useState } from "react";
import { DateRange } from "rsuite/DateRangePicker";

const useDateRange = () => {
  const [dateRangeValue, setDateRangeValue] = useState<DateRange | null>(null);
  const handleDateRangeChange = (value: DateRange | null) => {
    setDateRangeValue(value);
  };
  return { dateRangeValue, handleDateRangeChange };
};

export default useDateRange;
