import React, { useEffect, useRef } from "react";
import { StyledInput } from "@/components/styled";
import { Box, Typography, useTheme } from "@mui/material";
import { FieldError } from "react-hook-form";

type Props = {
  onChange: (res: string) => void;
  error?: FieldError;
};

const OtpInput: React.FC<Props> = ({ error, onChange }) => {
  const inputsRef = useRef<Array<HTMLInputElement>>([]);
  const { palette } = useTheme();

  useEffect(() => {
    inputsRef.current[0].focus();
  }, []);

  const sendResult = () => {
    const res = inputsRef.current.map((input) => input.value).join("");
    onChange && onChange(res);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = e;

    const nextElementSibling =
      e.target.parentElement?.nextElementSibling?.firstChild;

    if (value.length > 1) {
      e.target.value = value.charAt(0);
      nextElementSibling && (nextElementSibling as HTMLInputElement).focus();
    } else {
      if (value.match("[0-9]{1}")) {
        nextElementSibling && (nextElementSibling as HTMLInputElement).focus();
      } else {
        e.target.value = "";
      }
    }
    sendResult();
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const { key } = e;
    const target = e.target as HTMLInputElement;
    const previousElementSibling =
      target.parentElement?.previousElementSibling?.firstChild;
    if (key === "Backspace") {
      if (target.value === "" && previousElementSibling) {
        (previousElementSibling as HTMLInputElement).focus();
        e.preventDefault();
      } else {
        target.value = "";
      }
      sendResult();
    }
  };

  const handleOnFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    e.target.select();
  };

  const handleOnPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const pastedValue = e.clipboardData.getData("Text").slice(0, 6);

    pastedValue.split("").forEach((char, index) => {
      if (inputsRef.current[index]) {
        inputsRef.current[index].value = char;
      }
    });

    sendResult();
    inputsRef.current[Math.min(pastedValue.length, 5)].focus();
    e.preventDefault();
  };

  return (
    <>
      <Typography
        sx={{
          fontSize: "12px",
          fontWeight: 500,
          color: (theme) => theme.palette.textGray1,
          paddingBottom: "2px",
        }}
      >
        Code from email
      </Typography>
      <Box sx={{ display: "flex", gap: "8px" }}>
        {Array.from(Array(6).keys()).map((i) => (
          <StyledInput
            key={i}
            onChange={handleOnChange}
            onKeyDown={handleOnKeyDown}
            onFocus={handleOnFocus}
            onPaste={handleOnPaste}
            inputProps={{
              maxLength: 1,
              style: { textAlign: "center", padding: "12px" },
            }}
            type="tel"
            placeholder={"X"}
            inputRef={(el: HTMLInputElement) => (inputsRef.current[i] = el)}
            autoComplete={i === 0 ? "one-time-code" : "off"}
            sx={{
              height: "48px",
              width: "78px",
            }}
            error={!!error?.message}
          />
        ))}
      </Box>
      <Typography
        sx={{
          fontSize: "12px",
          color: palette.errorText,
          paddingTop: "4px",
          opacity: !!error?.message ? 1 : 0,
        }}
      >
        {error?.message || "helper text"}
      </Typography>
    </>
  );
};
export default OtpInput;
