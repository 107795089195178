import React from "react";
import useLoadingStore from "@/store/loadingStore.ts";
import { Box, CircularProgress } from "@mui/material";

const ProgressBar = () => {
  const pageLoading = useLoadingStore((state) => state.pageLoading);
  return pageLoading ? (
    <Box
      sx={{
        position: "fixed",
        top: "50%",
        zIndex: 100,
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <CircularProgress />
    </Box>
  ) : null;
};

export default ProgressBar;
