const SvgEditNote = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5302 4.03025L14.4697 2.96975C14.1765 2.6765 13.7017 2.6765 13.4092 2.96975L5.46975 10.9093C5.32875 11.0495 5.25 11.2408 5.25 11.4395V13.25H7.0605C7.25925 13.25 7.4505 13.1713 7.59075 13.0303L15.5302 5.09075C15.8235 4.79825 15.8235 4.3235 15.5302 4.03025V4.03025Z"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1824 6.4325L12.0674 4.3175"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 8.75V14.75C15.75 15.5788 15.0788 16.25 14.25 16.25H3.75C2.92125 16.25 2.25 15.5788 2.25 14.75V4.25C2.25 3.42125 2.92125 2.75 3.75 2.75H9.75"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgEditNote;
