import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelectedRowsStore } from "@/store/selectedRowsStore.ts";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useProjectsStore } from "@/store/projectsStore.ts";
import SVGDownloadFile from "@/assets/icons/SVGDownloadFile.tsx";
import { ProjectRecord } from "@/types";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { ProjectsCSVHeaders } from "@/constants";
import {
  getAllProjectsToExportReq,
  getProjectsToExportReq,
} from "@/api/projectsApi";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";

type Props = {
  search: string;
  filter: string[];
  tableId: string;
};

const ProjectsSelectedInfo = ({ search, filter, tableId }: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const selectedRows = useSelectedRowsStore(
    (state) => state.tables[tableId]?.selectedRows,
  );
  const isSelectAll = useSelectedRowsStore(
    (state) => state.tables[tableId]?.isSelectAll,
  );
  const initializeTable = useSelectedRowsStore(
    (state) => state.initializeTable,
  );

  const { totalProjectsCount } = useProjectsStore((state) => state.allProjects);

  const selectedRowsNumber = useMemo(
    () => (isSelectAll ? totalProjectsCount : selectedRows?.size || 0),
    [isSelectAll, selectedRows, totalProjectsCount],
  );

  const [isExporting, setIsExporting] = useState(false);

  const getProjectsToExport = useCallback(async () => {
    return isSelectAll
      ? getAllProjectsToExportReq(search, filter)
      : getProjectsToExportReq([...selectedRows]);
  }, [filter, isSelectAll, search, selectedRows]);

  const handleExportProjects = async () => {
    try {
      if (!isExporting) {
        setIsExporting(true);
        const response = await getProjectsToExport();
        const transformedData = response?.data?.projects?.map(
          (el: ProjectRecord) => ({
            id: el?.id,
            customerName: el?.customerName,
            address: el?.address,
            city: el?.city,
            zipCode: el?.zipCode,
            accountHash: el?.accountHash,
            customerEmail: el?.accountEmail,
            existingInverter: el?.existingInverter,
            solarKwdc: el?.solarKwdc,
            solarKwac: el?.solarKwac,
            solarAnnualProduction: el?.solarAnnualProduction,
            newSolarKwdc: el?.newSolarKwdc,
            newSolarKwac: el?.newSolarKwac,
            newSolarAnnualProduction: el?.newSolarAnnualProduction,
            incentiveStatus: capitalizeFirstLetter(
              replaceUnderscoresWithSpaces(el?.incentiveStatus ?? ""),
            ),
            enrollmentStatus: capitalizeFirstLetter(
              replaceUnderscoresWithSpaces(el?.enrolmentStatus ?? ""),
            ),
            upfrontIncentivePayment: formatCurrency(
              Number(el.upfrontIncentivePayment) / 100,
            ),
            contractor: el?.contractor,
            batteryOem: el?.batteryOem,
            batteryModel: el?.batteryModel,
            usableCapacity: el?.usableCapacity,
            numberOfBatteries: el?.numberOfBatteries,
            summerEnrollment: el?.summerEnrolment,
            winterEnrollment: el?.winterEnrolment,
            participation: capitalizeFirstLetter(
              replaceUnderscoresWithSpaces(el?.participation ?? ""),
            ),
            createdAt: formatDate(el?.createdAt),
          }),
        );
        downloadCSV(transformedData, "projects", ProjectsCSVHeaders);
      }
    } catch (e) {
      console.error(e);
    }
    finally {
        setIsExporting(false);
    }
  };

  // Initialize the table
  useEffect(() => {
    initializeTable(tableId);
  }, [initializeTable, tableId]);
  console.log({ selectedRowsNumber });
  if (!selectedRowsNumber) return null;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        justifyContent: "space-between",
        ...(downSm && { paddingLeft: "24px" }),
      }}
    >
      <Button
        onClick={handleExportProjects}
        startIcon={<SVGDownloadFile />}
        variant={"outlined"}
      >
        {isExporting ? "Exporting..." : "Export"} {selectedRowsNumber}{" "}
        {selectedRowsNumber > 1 ? "Projects" : "Project"}
      </Button>
    </Box>
  );
};

export default ProjectsSelectedInfo;
