import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SVGMessageWarningAlert from "@/assets/icons/SVGMessageWarningAlert.tsx";
import SVGCross from "@/assets/icons/SVGCross.tsx";
import { StyledIconButton } from "@/components/styled";

const WarningMessage = ({
  title,
  message,
}: {
  title: string;
  message: string;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [close, setClose] = useState(false);
  if (close) {
    return null;
  }
  return (
    <Box
      sx={{
        width: "100%",
        border: "2px solid #FFDFB0",
        padding: "24px",
        borderRadius: "16px",
        backgroundColor: "#FFF5E6",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "10px",
          }}
        >
          <SVGMessageWarningAlert
            style={{ ...(downSm && { width: "100%" }) }}
          />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant={"cardHeader3"} sx={{ fontWeight: 700 }}>
              {title}
            </Typography>
            <Typography variant={"bodyRegular"} sx={{ fontWeight: 600 }}>
              {message}
            </Typography>
          </Box>
        </Box>
        <StyledIconButton
          sx={{
            height: "30px",
            color: "textGray4",
            minWidth: "24px",
            padding: 0,
          }}
          onClick={() => setClose(true)}
        >
          <SVGCross style={{ ...(downSm && { width: "100%" }) }} />
        </StyledIconButton>
      </Box>
    </Box>
  );
};

export default WarningMessage;
