import React, { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { Line } from "react-chartjs-2";
import {
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { getAnalysisData } from "@/api/analysisApi";
import { AnalysisDataTypeItems, DaysMap, MonthsMap } from "@/constants"; // Add DaysMap to your constants
import { StyledSwitch } from "@/components/styled";
import Card from "@/components/Card";
import DataTypeSelect from "@/components/AnalysisChart/DataTypeSelect.tsx";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const monthsInOrder = [
  MonthsMap.January,
  MonthsMap.February,
  MonthsMap.March,
  MonthsMap.April,
  MonthsMap.May,
  MonthsMap.June,
  MonthsMap.July,
  MonthsMap.August,
  MonthsMap.September,
  MonthsMap.October,
  MonthsMap.November,
  MonthsMap.December,
];

const daysInOrder = [
  DaysMap.Sunday,
  DaysMap.Monday,
  DaysMap.Tuesday,
  DaysMap.Wednesday,
  DaysMap.Thursday,
  DaysMap.Friday,
  DaysMap.Saturday,
];

const AnalysisChart = () => {
  const { palette } = useTheme();
  const [showLine1, setShowLine1] = useState(true);
  const [showLine2, setShowLine2] = useState(true);
  const [showLine3, setShowLine3] = useState(true);
  const [dataType, setDataType] = useState<"monthly" | "weekly" | "yearly">(
    "monthly",
  );
  const [availableYears, setAvailableYears] = useState<number[]>([]);

  const budgetData = useBudgetStore((state) => state.budgetData);
  const [analysisData, setAnalysisData] = useState<any>({});

  useEffect(() => {
    if (budgetData?.id) {
      const fetchAnalysis = async () => {
        try {
          const response = await getAnalysisData(dataType);
          setAnalysisData(response?.data);

          if (dataType === "yearly") {
            const years = Object.keys(response?.data)
              .filter((key) => key !== "success")
              .map((year) => parseInt(year, 10));
            setAvailableYears(years);
          }
        } catch (e) {
          console.error(e);
        }
      };
      fetchAnalysis();
    }
  }, [budgetData, dataType]);

  const getData = () => {
    if (dataType === "monthly") {
      return {
        labels: monthsInOrder,
        datasets: [
          {
            label: "Remaining",
            data: monthsInOrder.map(
              (month) => analysisData[month]?.Remaining ?? 0,
            ),
            borderColor: palette.chartFirstColor,
            backgroundColor: palette.chartFirstColor,
            hidden: !showLine1,
          },
          {
            label: "Reserved",
            data: monthsInOrder.map(
              (month) => analysisData[month]?.Reserved ?? 0,
            ),
            borderColor: palette.chartSecondColor,
            backgroundColor: palette.chartSecondColor,
            hidden: !showLine2,
          },
          {
            label: "Paid",
            data: monthsInOrder.map((month) => analysisData[month]?.Paid ?? 0),
            borderColor: palette.chartThirdColor,
            backgroundColor: palette.chartThirdColor,
            hidden: !showLine3,
          },
        ],
      };
    } else if (dataType === "weekly") {
      return {
        labels: daysInOrder,
        datasets: [
          {
            label: "Remaining",
            data: daysInOrder.map((day) => analysisData[day]?.Remaining ?? 0),
            borderColor: palette.chartFirstColor,
            backgroundColor: palette.chartFirstColor,
            hidden: !showLine1,
          },
          {
            label: "Reserved",
            data: daysInOrder.map((day) => analysisData[day]?.Reserved ?? 0),
            borderColor: palette.chartSecondColor,
            backgroundColor: palette.chartSecondColor,
            hidden: !showLine2,
          },
          {
            label: "Paid",
            data: daysInOrder.map((day) => analysisData[day]?.Paid ?? 0),
            borderColor: palette.chartThirdColor,
            backgroundColor: palette.chartThirdColor,
            hidden: !showLine3,
          },
        ],
      };
    } else {
      const yearsInOrder = availableYears;
      return {
        labels: yearsInOrder,
        datasets: [
          {
            label: "Remaining",
            data: yearsInOrder.map(
              (year) => analysisData[year]?.Remaining ?? 0,
            ),
            borderColor: palette.chartFirstColor,
            backgroundColor: palette.chartFirstColor,
            hidden: !showLine1,
          },
          {
            label: "Reserved",
            data: yearsInOrder.map((year) => analysisData[year]?.Reserved ?? 0),
            borderColor: palette.chartSecondColor,
            backgroundColor: palette.chartSecondColor,
            hidden: !showLine2,
          },
          {
            label: "Paid",
            data: yearsInOrder.map((year) => analysisData[year]?.Paid ?? 0),
            borderColor: palette.chartThirdColor,
            backgroundColor: palette.chartThirdColor,
            hidden: !showLine3,
          },
        ],
      };
    }
  };

  const data = getData();

  const options = {
    scales: {
      y: {
        title: {
          display: true,
          text: "Dollars",
        },
        ticks: {
          stepSize: 500000,
          callback: function (value: any) {
            return `$${(value / 100000000).toFixed(1)}M`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = "";
            if (context.dataset.label) {
              label += `${context.dataset.label}: $${(context.parsed.y / 100000000).toFixed(2)}M\n`;
            }
            return label;
          },
        },
      },
    },
  };

  return (
    <Card
      title={
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          Analysis
          <DataTypeSelect
            selectedValue={dataType}
            placeholder={capitalizeFirstLetter(dataType)}
            setSelectedValue={(value: "monthly" | "weekly" | "yearly") =>
              setDataType(value)
            }
            items={AnalysisDataTypeItems}
          />
        </Box>
      }
    >
      <Box
        sx={{
          display: "flex",
          marginTop: "16px",
          marginBottom: "24px",
          gap: "16px",
        }}
      >
        <Box sx={{ display: "flex", gap: "8px" }}>
          <StyledSwitch
            variant={"first"}
            checked={showLine1}
            onChange={() => setShowLine1(!showLine1)}
          />
          <Typography variant={"helperText"} style={{ fontSize: "16px" }}>
            Remaining
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <StyledSwitch
            variant={"second"}
            checked={showLine2}
            onChange={() => setShowLine2(!showLine2)}
          />
          <Typography variant={"helperText"} style={{ fontSize: "16px" }}>
            Reserved
          </Typography>
        </Box>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <StyledSwitch
            variant={"third"}
            checked={showLine3}
            onChange={() => setShowLine3(!showLine3)}
          />
          <Typography variant={"helperText"} style={{ fontSize: "16px" }}>
            Paid
          </Typography>
        </Box>
      </Box>

      <Box
        sx={{ overflowY: "auto", display: "flex", flexDirection: "columns" }}
      >
        <Line
          data={data as ChartData<"line">}
          options={options}
          style={{ maxHeight: "340px" }}
        />
      </Box>
    </Card>
  );
};

export default AnalysisChart;
