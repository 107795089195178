import Card from "@/components/Card";
import { Box, Grid } from "@mui/material";
import BudgetChart from "@/components/BudgetWidget/BudgetChart.tsx";
import BudgetInformation from "@/components/BudgetWidget/BudgetInformation.tsx";
import { useEffect } from "react";
import { useBudgetStore } from "@/store/budgetStore.ts";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

const BudgetWidget = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const widgetData = useBudgetStore((state) => state.budgetData);
  const getBudgetData = useBudgetStore((state) => state.getBudgetData);
  useEffect(() => {
    fetchDataWithLoading(getBudgetData, []);
  }, [fetchDataWithLoading, getBudgetData]);
  return (
    <Card
      title={
        <Box display="flex" alignItems="center" gap={"8px"}>
          Budget Status
        </Box>
      }
    >
      <Grid container columns={12} sx={{ marginTop: "24px" }}>
        <Grid item xs={12} md={4}>
          <BudgetChart widgetData={widgetData} />
        </Grid>
        <Grid item xs={12} md={8}>
          <BudgetInformation widgetData={widgetData} />
        </Grid>
      </Grid>
    </Card>
  );
};

export default BudgetWidget;
