import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import FormInput from "@/components/FormInput";
import { useForm } from "react-hook-form";
import { Box, Button, Modal as MuiModal } from "@mui/material";
import FormTextEditor from "@/components/FormTextEditor";
import SVGDelete from "@/assets/icons/SVGDelete.tsx";
import DangerModal from "@/components/DangerModal";
import { useNewsStore } from "@/store/newsStore.ts";
import { ArticleType } from "@/types";
import { updateArticleReq } from "@/api/adminApi";
import { useAuthStore } from "@/store/authStore.ts";

export type ArticleForm = {
  title: string;
  content: string;
};

type Props = {
  article: ArticleType;
  open: boolean;
  onClose: () => void;
};

const EditArticle = ({ open, onClose, article }: Props) => {
  const role = useAuthStore((state) => state.role);
  const isAdmin = role === "administrator";
  const updateArticle = useNewsStore((state) => state.updateArticle);
  const deleteArticle = useNewsStore((state) => state.deleteArticle);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleClose = () => {
    reset();
    onClose();
  };

  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: { isValid },
  } = useForm<ArticleForm>({
    mode: "onChange",
  });

  const title = watch("title");
  const content = watch("content");

  const onSubmit = async (data: ArticleForm) => {
    try {
      await updateArticle(article?.id, data);
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteArticle = async () => {
    try {
      await deleteArticle(article?.id);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    reset(article);
  }, [article]);

  return (
    <>
      <Modal
        boxSx={{ maxWidth: "1000px" }}
        disableEnforceFocus
        open={open}
        onClose={handleClose}
        title={"Edit Article"}
        additionalButtons={
          isAdmin ? (
            <Box>
              <Button
                onClick={() => setOpenDeleteModal(true)}
                sx={{
                  width: "48px",
                  height: "48px",
                  minWidth: "initial",
                  padding: 0,
                }}
                variant={"dangerOutlined"}
              >
                <SVGDelete />
              </Button>
            </Box>
          ) : null
        }
      >
        <form
          style={{
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            marginTop: "24px",
          }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <Box
            sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}
          >
            <FormInput
              name={"title"}
              control={control}
              label={"Title"}
              placeholder={"Write your title here..."}
              controlProps={{ rules: { required: "Required" } }}
            />
            <FormTextEditor
              name={"content"}
              control={control}
              label={"Content"}
              controlProps={{ rules: { required: "Required" } }}
            />
          </Box>
          <Box sx={{ display: "flex", paddingTop: "20px", gap: "16px" }}>
            <Button variant={"outlined"} sx={{ width: "100%" }} type={"submit"}>
              Cancel
            </Button>
            <Button
              variant={"contained"}
              sx={{ width: "100%" }}
              type={"submit"}
              disabled={!title || !content}
            >
              Save
            </Button>
          </Box>
        </form>
      </Modal>
      <MuiModal open={openDeleteModal} onClose={handleCloseDeleteModal}>
        <>
          <DangerModal
            onClick={handleDeleteArticle}
            onClose={handleCloseDeleteModal}
            title={`Delete Article?`}
            subtitle={`This step cannot be undone.`}
            buttonText={"Delete"}
          />
        </>
      </MuiModal>
    </>
  );
};

export default EditArticle;
