import { ReactNode } from "react";
import { Box, Button, styled } from "@mui/material";

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "16px",
  padding: "0 32px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

export const ButtonsSection = ({
  onCancel,
  actionButton,
}: {
  onCancel: () => void;
  actionButton: ReactNode;
}) => {
  return (
    <Box
      sx={{
        paddingTop: "20px",
        borderTop: "1px solid #EDEDED",
        marginX: "-32px",
      }}
    >
      <BoxWrapper>
        <Button sx={{ width: "100%" }} variant={"outlined"} onClick={onCancel}>
          Cancel
        </Button>
        {actionButton}
      </BoxWrapper>
    </Box>
  );
};

export default ButtonsSection;
