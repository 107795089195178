import React, { useEffect, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledBadge,
  StyledRightActionButton,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import useTablePagination from "@/hooks/useTablePagination.ts";
import TablePagination from "@/components/TablePagination";
import Card from "@/components/Card";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import LogDetailModal from "@/pages/admin-pages/UserManagementPage/components/LogDetailModal.tsx";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import { createLogData } from "@/utils/createLogData.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import CommonTableActions from "@/components/CommonTableActions";
import TableFilter from "@/components/TableFilter";
import {
  COMMON_SORT_ITEMS,
  LOGS_FILTER_ITEMS,
  LogsCSVHeaders,
} from "@/constants";
import TableSort from "@/components/TableSort";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import { getSessionStatusBadgeVariant } from "@/utils/getSessionStatusBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatDate } from "@/utils/formatDate.ts";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

const LogTable = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const userId = useAdminStore((state) => state.user?.id);
  const { userLogs, totalUserLogsCount } = useAdminStore(
    (state) => state.userLogs,
  );
  const getUserLogs = useAdminStore((state) => state.getUserLogs);

  const rows = useMemo(() => {
    return userLogs.map((el) => createLogData(el));
  }, [userLogs]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalUserLogsCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { dateRangeValue, handleDateRangeChange } = useDateRange();

  useEffect(() => {
    if (userId) {
      fetchDataWithLoading(getUserLogs, [
        userId,
        page + 1,
        rowsPerPage,
        searchValue,
        filters,
        sort,
        dateRangeValue,
      ]);
    }
  }, [
    userId,
    page,
    rowsPerPage,
    searchValue,
    filters,
    sort,
    dateRangeValue,
    fetchDataWithLoading,
    getUserLogs,
  ]);
  return (
    <StyledTableCard>
      <CommonTableActions
        title={"Access Logs"}
        onDateRange={handleDateRangeChange}
        onSearch={handleDebounceSearchValue}
        filter={
          <TableFilter
            placeholder={"Result"}
            items={LOGS_FILTER_ITEMS}
            setFilterValues={handleFilterChange}
          />
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
        rightActions={
          <StyledRightActionButton
            onClick={() =>
              downloadCSV(transformLogToCSV(userLogs), "logs", LogsCSVHeaders)
            }
            startIcon={<SVGUpload />}
            sx={{
              whiteSpace: "nowrap",
              ...(!downLg && { minWidth: "240px" }),
            }}
            variant={"contained"}
          >
            {!downLg ? "Download CSV report" : "Report"}
          </StyledRightActionButton>
        }
      />
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Log ID</StyledTableCell>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>IP Address</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Access type</StyledTableCell>
              <StyledTableCell>Result</StyledTableCell>
              <StyledTableCell>Time</StyledTableCell>
              <StyledTableCell>Event Log</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  key={row.id}
                  title={row.logType}
                  firstSub={row.id}
                  secondSub={
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  }
                >
                  <CellWithHeader header={"IP Address"}>
                    {row.ipAddress}
                  </CellWithHeader>
                  <CellWithHeader header={"Location"}>
                    {row.location}
                  </CellWithHeader>
                  <CellWithHeader header={"Time"}>
                    {formatDate(row.timestamp ?? "")}
                  </CellWithHeader>
                  <CellWithHeader header={"Event log"}>
                    {row.message}
                  </CellWithHeader>
                  <LogDetailModal
                    log={row}
                    getLogs={() =>
                      getUserLogs(
                        userId,
                        page + 1,
                        rowsPerPage,
                        searchValue,
                        filters,
                        sort,
                        dateRangeValue,
                      )
                    }
                  />
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.ipAddress}</StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>{row.logType}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>{row.timestamp}</StyledTableCell>
                  <StyledTableCell>{row.message}</StyledTableCell>
                  <StyledTableCell>
                    <LogDetailModal
                      log={row}
                      getLogs={() =>
                        getUserLogs(
                          userId,
                          page + 1,
                          rowsPerPage,
                          searchValue,
                          filters,
                          sort,
                          dateRangeValue,
                        )
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalUserLogsCount}
      />
    </StyledTableCard>
  );
};

export default LogTable;
