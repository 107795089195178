import {
  Box,
  Button,
  InputBase,
  Menu,
  styled,
  Switch,
  SwitchProps,
  Table,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import theme from "../../theme.tsx";

export const StyledDivider = styled(Box)(() => ({
  height: "2px",
  width: "100%",
  backgroundColor: "#EDEDED",
}));

export const StyledInput = styled(InputBase)<{
  active?: boolean;
  error?: boolean;
  password?: boolean;
  iconstart?: boolean;
}>(({ theme, active, error, password, iconstart }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    position: "relative",
    border: "1px solid",
    borderColor: error
      ? theme.palette.errorText
      : active
        ? theme.palette.chartFirstColor
        : theme.palette.textLightGray1,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.textGray2,
    borderRadius: "12px",
    padding: `12px ${password ? "47px" : "16px"} 12px ${iconstart ? "54px" : "16px"}`,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderColor: theme.palette.chartFirstColor,
      borderRadius: "12px",
    },
  },
}));

export const StyledModalBox = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#fff",
  maxWidth: "570px",
  width: "100%",
  padding: "32px",
  minWidth: "340px",
  borderRadius: "24px",
  [theme.breakpoints.down("lg")]: {
    width: "auto",
    padding: "16px",
  },
}));
export const StyledDetailItem = styled(Box)(() => ({
  display: "flex",
  gap: "8px",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    flexDirection: "Column",
  },
}));

export const StyledBadge = styled(Box)<{
  danger?: boolean;
  neutral?: boolean;
  yellow?: boolean;
  grey?: boolean;
}>(({ danger, neutral, yellow, grey }) => ({
  padding: "6px 12px",
  borderRadius: "8px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  textAlign: "center",
  fontWeight: 600,
  border: grey ? "1px solid #888684" : "none",
  color: danger
    ? "#B53700"
    : neutral
      ? "#0076CE"
      : yellow
        ? "#B56C00"
        : grey
          ? "#4E4B48"
          : "#005B68",
  backgroundColor: danger
    ? "#FFEDE6"
    : neutral
      ? "#E6F1FA"
      : yellow
        ? "#FFF5E6"
        : grey
          ? "#fff"
          : "#E6F6F8",
  [theme.breakpoints.down("sm")]: {
    padding: "4px 8px",
  },
}));
export const StyledLabel = styled(Typography)(() => ({
  fontSize: "12px",
  fontWeight: 500,
  color: theme.palette.textGray1,
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "12px",
    border: `1px solid ${theme.palette.textLightGray1}`,
    minWidth: "225px",
  },
}));

export const StyledSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))<{ variant: "first" | "second" | "third" }>(({ theme, variant }) => ({
  width: 40,
  height: 24,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 4,
    transitionDuration: "300ms",
    color:
      variant === "first"
        ? theme.palette.chartFirstColor
        : variant === "second"
          ? theme.palette.chartSecondColor
          : theme.palette.chartThirdColor,
    "& + .MuiSwitch-track": {
      border: "2px solid",
      borderRadius: "14px",
      borderColor:
        variant === "first"
          ? theme.palette.chartFirstColor
          : variant === "second"
            ? theme.palette.chartSecondColor
            : theme.palette.chartThirdColor,
    },
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor:
          variant === "first"
            ? theme.palette.chartFirstColor
            : variant === "second"
              ? theme.palette.chartSecondColor
              : theme.palette.chartThirdColor,
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        variant === "first"
          ? "#fff"
          : variant === "second"
            ? theme.palette.chartSecondColor
            : theme.palette.chartThirdColor,
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 16,
    height: 16,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#fff",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

export const StyledTypographyLink = styled(Typography)(({ theme }) => ({
  fontSize: "12px",
  fontWeight: 500,
  color: theme.palette.linkText,
  textDecoration: "underline",
  cursor: "pointer",
}));

export const BoxHeaderInner = styled(Box)(({ theme }) => ({
  height: "60px",
  width: "100%",
  backgroundColor: "#003257",
  position: "absolute",
  top: 0,
  zIndex: 0,
  margin: "0 -80px",
  [theme.breakpoints.down("xl")]: {
    margin: "0 -40px",
  },
  [theme.breakpoints.down("lg")]: {
    margin: "0 -24px",
  },
}));

export const StyledTableRow = styled(TableRow)(() => ({
  border: 0,
  "&:nth-of-type(even)": {
    backgroundColor: "#F9F9F9",
    td: {
      backgroundColor: "#F9F9F9",
    },
  },
  // hide last border
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#888684",
    fontSize: "12px",
    fontWeight: "600",
    paddingTop: "15px",
    paddingBottom: "15px",
    whiteSpace: "nowrap",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    color: "#354052",
    fontWeight: "500",
    paddingTop: "25px",
    paddingBottom: "25px",
    whiteSpace: "nowrap",
    border: 0,
  },
}));

export const StyledPinnedCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    color: "#354052",
    fontWeight: "500",
    paddingTop: "25px",
    paddingBottom: "25px",
    whiteSpace: "nowrap",
    position: "absolute",
    right: 0,
    background: "#fff",
    border: 0,
    maxHeight: "82px",
  },
}));

export const StyledTableCellTruncated = styled(StyledTableCell)(() => ({
  maxWidth: "450px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
}));

export const BoxAction = styled(Box)(() => ({
  display: "flex",
  cursor: "pointer",
}));

export const BoxActionsWrapper = styled(Box)(() => ({
  padding: "16px 0 24px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: "8px",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    padding: "16px 24px 24px 24px",
  },
}));

export const BoxSearchWrapper = styled(Box)(() => ({
  display: "flex",
  gap: "16px",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    gap: "8px",
  },
}));

export const StyledIconButton = styled(Button)(() => ({
  minWidth: "initial",
  minHeight: "initial",
  padding: "8px",
}));

export const StyledPageWrapperBox = styled(Box)(() => ({
  position: "relative",
  zIndex: 10,
  display: "flex",
  width: "100%",
  marginTop: "28px",
}));

export const StyledRowActionsWrapperBox = styled(Box)(() => ({
  display: "flex",
  gap: "6px",
  justifyContent: "flex-end",
  [theme.breakpoints.down("sm")]: {
    justifyContent: "start",
  },
}));

export const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    slotProps={{
      popper: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, -30],
            },
          },
        ],
      },
    }}
    {...props}
    arrow
    classes={{ popper: className }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.textGray4,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.textGray4,
    fontSize: "14px",
    fontWeight: 400,
    padding: "8px 16px",
  },
}));

export const StyledArrowButton = styled(Button)(({ theme }) => ({
  width: "64px",
  height: "64px",
  borderRadius: "12px",
  backgroundColor: "#fff",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.down("xl")]: {
    width: "48px",
    height: "48px",
    minWidth: "initial",
    top: 0,
  },
}));

export const StyledTruncatedTypography = styled(Typography)({
  whiteSpace: "nowrap",
  display: "block",
  overflow: "hidden",
  textOverflow: "ellipsis",
});
export const StyledRightActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    minWidth: "initial",
    height: "40px",
    minHeight: "initial",
    padding: "12px",
  },
}));

export const StyledTableActionButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    padding: "12px",
  },
}));

export const StyledTableHeader = styled(TableHead)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    display: "none",
  },
}));

export const StyledTableCard = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
  [theme.breakpoints.down("sm")]: {
    padding: "24px 0",
  },
}));
export const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 700,
  [theme.breakpoints.down("sm")]: {
    minWidth: "initial",
  },
}));
