import React from "react";
import { Box } from "@mui/material";

const SvgWarningModal = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "99px",
        boxShadow: "0px 24px 34px 10px #F5A62333",
        overflow: "hidden",
      }}
    >
      <svg
        width="92"
        height="92"
        viewBox="24 0 92 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_625_155914)">
          <rect
            x="24.5"
            y="0.5"
            width="92"
            height="92"
            rx="46"
            fill="#E8B528"
            shapeRendering="crispEdges"
          />
          <path
            d="M70.5002 24.8333C58.5618 24.8333 48.8335 34.5616 48.8335 46.5C48.8335 58.4383 58.5618 68.1666 70.5002 68.1666C82.4385 68.1666 92.1668 58.4383 92.1668 46.5C92.1668 34.5616 82.4385 24.8333 70.5002 24.8333ZM68.8752 37.8333C68.8752 36.945 69.6118 36.2083 70.5002 36.2083C71.3885 36.2083 72.1252 36.945 72.1252 37.8333V48.6666C72.1252 49.555 71.3885 50.2916 70.5002 50.2916C69.6118 50.2916 68.8752 49.555 68.8752 48.6666V37.8333ZM72.4935 55.99C72.3852 56.2716 72.2335 56.4883 72.0385 56.705C71.8218 56.9 71.5835 57.0516 71.3235 57.16C71.0635 57.2683 70.7818 57.3333 70.5002 57.3333C70.2185 57.3333 69.9368 57.2683 69.6768 57.16C69.4168 57.0516 69.1785 56.9 68.9618 56.705C68.7668 56.4883 68.6152 56.2716 68.5068 55.99C68.3985 55.73 68.3335 55.4483 68.3335 55.1666C68.3335 54.885 68.3985 54.6033 68.5068 54.3433C68.6152 54.0833 68.7668 53.845 68.9618 53.6283C69.1785 53.4333 69.4168 53.2816 69.6768 53.1733C70.1968 52.9566 70.8035 52.9566 71.3235 53.1733C71.5835 53.2816 71.8218 53.4333 72.0385 53.6283C72.2335 53.845 72.3852 54.0833 72.4935 54.3433C72.6018 54.6033 72.6668 54.885 72.6668 55.1666C72.6668 55.4483 72.6018 55.73 72.4935 55.99Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_625_155914"
            x="0.5"
            y="0.5"
            width="140"
            height="140"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="10"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_625_155914"
            />
            <feOffset dy="24" />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0.960784 0 0 0 0 0.65098 0 0 0 0 0.137255 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_625_155914"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_625_155914"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};

export default SvgWarningModal;
