export const getEnrollmentBadgeVariant = (
  status:
    | "active"
    | "pending"
    | "archived"
    | "rejected"
    | "correction_needed"
    | "contractor_updated",
) => {
  if (status === "pending" || status === "correction_needed") {
    return { yellow: true };
  }

  if (status === "archived" || status === "rejected") {
    return { danger: true };
  }

  if (status === "contractor_updated") {
    return { grey: true };
  }
  return {};
};
