import React, { useState } from "react";

const useTableSort = () => {
  const [sort, setSort] = useState("createdAtDesc");
  const handleSortChange = (values: string) => {
    setSort(values);
  };
  return { sort, handleSortChange };
};

export default useTableSort;
