import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import Modal from "@/components/Modal";
import { DetailItem } from "@/components/TotalBalance/ActionDetailsModal.tsx";
import SVGGoTo from "@/assets/icons/SVGGoTo.tsx";
import {
  StyledBadge,
  StyledDivider,
  StyledRowActionsWrapperBox,
  StyledTableCell,
  StyledTableCellTruncated,
  StyledTooltip,
} from "@/components/styled";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import EditableNote from "@/components/EditableNote";
import { getDataRequestBadgeVariant } from "@/utils/getDataRequesBadgeVariant.ts";
import { CellWithHeader } from "@/components/CollapsableRow";
import SVGDataInfo from "@/assets/icons/SVGDataInfo.tsx";
import { updateDataRequestLogNoteReq } from "@/api/requestsLogsApi";
import { DataRequestLogRowType } from "@/pages/admin-pages/DataRequestsPage/components/DataRequestsTable.tsx";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";

const DataRequestDetailModal = ({
  dataRequest,
  getLogs,
}: {
  dataRequest: DataRequestLogRowType;
  getLogs: () => void;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [currentRequest, setCurrentRequest] = useState(dataRequest);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteNote = async () => {
    try {
      if (dataRequest?.id) {
        const response = await updateDataRequestLogNoteReq(dataRequest?.id, {
          note: "",
        });
        setCurrentRequest(response?.data?.log);
        getLogs();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleSaveNote = async (values: { note: string }) => {
    try {
      if (dataRequest?.id) {
        const response = await updateDataRequestLogNoteReq(dataRequest?.id, {
          note: values?.note,
        });
        setCurrentRequest(response?.data?.updatedLog);
        getLogs();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      {!downSm && (
        <>
          <StyledTooltip
            title={dataRequest?.note?.length < 55 ? null : dataRequest?.note}
            enterDelay={500}
          >
            <StyledTableCellTruncated
              sx={{ cursor: "pointer" }}
              onClick={() => setOpen(true)}
            >
              {dataRequest?.note}
            </StyledTableCellTruncated>
          </StyledTooltip>
          <StyledTableCell onClick={() => setOpen(true)}>
            <StyledRowActionsWrapperBox>
              <SVGDataInfo style={{ cursor: "pointer" }} />
            </StyledRowActionsWrapperBox>
          </StyledTableCell>
        </>
      )}
      {downSm && (
        <Box onClick={() => setOpen(true)}>
          <CellWithHeader header={"Detailed description"}>
            {dataRequest?.note}
          </CellWithHeader>
          <Button sx={{ width: "100%", marginTop: "8px" }} variant={"outlined"}>
            Log details
          </Button>
        </Box>
      )}

      <Modal
        open={open}
        onClose={handleClose}
        title={`Request ${dataRequest?.id}`}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginTop: "24px",
          }}
        >
          <DetailItem
            field={"Status"}
            value={
              <StyledBadge
                {...getLogStatusBadgeVariant(dataRequest?.status ?? "")}
                sx={{ display: "inline", textWrap: "nowrap" }}
              >
                {getLogStatusBadgeLabel(dataRequest?.status ?? "")}
              </StyledBadge>
            }
          />
          <DetailItem
            field={"Request type"}
            value={dataRequest?.requestType ?? ""}
          />
          <DetailItem
            field={"Detailed description"}
            value={dataRequest?.note ?? ""}
          />
          <StyledDivider />
          <DetailItem
            field={"Account Name"}
            value={
              <Box
                onClick={() => {
                  if (dataRequest?.userId)
                    navigate(`/user-profile/${dataRequest?.userId}`);
                }}
                sx={{ display: "flex", gap: "8px", cursor: "pointer" }}
              >
                <>{dataRequest?.accountName ?? ""}</>
                {dataRequest?.userId && <SVGGoTo />}
              </Box>
            }
          />
          <DetailItem
            field={"Account email"}
            value={dataRequest?.email ?? ""}
          />
          <DetailItem
            field={"Account role"}
            value={
              <StyledBadge
                {...getBadgeVariant(dataRequest?.role ?? null, null)}
              >
                {capitalizeFirstLetter(dataRequest?.role ?? "")}
              </StyledBadge>
            }
          />
          <StyledDivider />
          <DetailItem
            field={"IP Address"}
            value={dataRequest?.ipAddress ?? ""}
          />
          <DetailItem field={"Location"} value={dataRequest?.location ?? ""} />
          <DetailItem
            field={"Date & Time"}
            value={dataRequest?.dateTime ?? ""}
          />
          <StyledDivider />
          <EditableNote
            handleDeleteNote={handleDeleteNote}
            handleSaveNote={handleSaveNote}
            currentNote={currentRequest?.note ?? ""}
            isNote={!!currentRequest?.note}
          />
        </Box>
      </Modal>
    </>
  );
};

export default DataRequestDetailModal;
