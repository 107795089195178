import {
  Box,
  Button,
  Modal as MuiModal,
  ModalProps,
  styled,
  SxProps,
  Typography,
} from "@mui/material";
import SVGCross from "@/assets/icons/SVGCross.tsx";
import React, { ReactNode } from "react";

const BoxWrapper = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "570px",
  width: "100%",
  backgroundColor: "#fff",
  padding: "32px",
  borderRadius: "24px",
  minWidth: "340px",
  display: "flex",
  flexDirection: "column",
  overflow: "auto",
  maxHeight: "calc(100% - 50px)",
  [theme.breakpoints.down("lg")]: {
    width: "auto",
    padding: "16px",
  },
}));

const TitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "32px",
  overflow: "hidden",
  textOverflow: "ellipsis",
  [theme.breakpoints.down("lg")]: {
    fontSize: "23px",
  },
}));
const SubtitleTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  fontSize: "32px",
  color: theme.palette.textLightGray2,
  [theme.breakpoints.down("lg")]: {
    fontSize: "23px",
  },
}));

const BoxHeadersWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  overflow: "hidden",
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
    gap: "4px",
  },
}));

type Props = ModalProps & {
  title?: ReactNode;
  subtitle?: ReactNode;
  additionalButtons?: ReactNode;
  boxSx?: SxProps;
};

const Modal = ({
  open,
  onClose,
  title,
  children,
  subtitle = "",
  additionalButtons,
  boxSx,
  ...props
}: Props) => {
  return (
    <MuiModal open={open} onClose={onClose} {...props}>
      <BoxWrapper sx={boxSx}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: "8px",
            alignItems: "center",
          }}
        >
          <BoxHeadersWrapper>
            <TitleTypography>{title}</TitleTypography>
            {subtitle && <SubtitleTypography>{subtitle}</SubtitleTypography>}
          </BoxHeadersWrapper>
          <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
            {additionalButtons}
            <Button
              sx={{
                width: "48px",
                height: "48px",
                minWidth: "initial",
                padding: 0,
              }}
              onClick={(e: any) => onClose?.(e, "backdropClick")}
              variant={"outlined"}
            >
              <SVGCross color={"#0076CE"} />
            </Button>
          </Box>
        </Box>
        {children}
      </BoxWrapper>
    </MuiModal>
  );
};

export default Modal;
