import React from "react";
import Card from "@/components/Card";
import { Box, Button, styled, Typography } from "@mui/material";
import SVGInfo from "@/assets/icons/SVGInfo.tsx";
import SVGArrowRight from "@/assets/icons/SVGArrowRight.tsx";
import TrainingVideo from "@/pages/ContractorDashboardPage/components/TrainingVideo.tsx";

const TypographySubtitle = styled(Typography)(({ theme }) => ({
  fontSize: "18px",
  fontWeight: 600,
  marginTop: "24px",
  [theme.breakpoints.down("md")]: {},
}));

const text =
  "• Program Details\n" +
  "• Eligibility Requirements\n" +
  "• Incentives\n" +
  "• Communication Strategies\n" +
  "\n" +
  "Upon successful completion, you'll be authenticated for eligibility and incentive confirmation webpages. This training is vital for maintaining our service standards.\n" +
  "\n" +
  "After finishing, you'll be redirected to the next step: the GIS map. Confirm that you have watched the video and completed the training by clicking the button below.";

const TrainingCard = () => {
  return (
    <Card>
      <Typography sx={{ fontWeight: 600, fontSize: "20px", color: "#4E4B48" }}>
        Continue learning
      </Typography>
      <TypographySubtitle>
        Welcome to Our Online Training Program for Sales Staff
      </TypographySubtitle>
      <Typography sx={{ marginTop: "12px" }} variant={"bodyRegular"}>
        Complete our essential online training module to effectively communicate
        our program's details, eligibility, and incentives to potential
        customers. This training is crucial for ensuring you provide accurate
        information and excellent service.
      </Typography>
      <Typography sx={{ marginTop: "12px" }} variant={"bodyRegular"}>
        Training Highlights:
      </Typography>
      <Box sx={{ whiteSpace: "pre-wrap", fontSize: "16px", fontWeight: 400 }}>
        {text}
      </Box>
      <TrainingVideo />
    </Card>
  );
};

export default TrainingCard;
