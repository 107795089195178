import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import dayjs from "dayjs";
import { BudgetType } from "@/types";

const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingTop: "40px",
  [theme.breakpoints.up("md")]: {
    paddingLeft: "64px",
  },
}));

const BudgetInformation = ({ widgetData }: { widgetData: BudgetType }) => {
  const remaining = widgetData?.remainingBudget / 100 || 0;
  const reserved = widgetData?.reservedFunds / 100 || 0;
  const paid = widgetData?.paidOutAmounts / 100 || 0;
  const totalBudget = widgetData?.totalBudget / 100 || 0;

  const remainingPercentage = ((remaining / totalBudget) * 100 || 0).toFixed(1);
  const reservedPercentage = ((reserved / totalBudget) * 100 || 0).toFixed(1);
  const paidPercentage = ((paid / totalBudget) * 100 || 0).toFixed(1);

  return (
    <BoxWrapper>
      <InformationItem
        amount={formatCurrency(remaining)}
        percentage={`Remaining (${remainingPercentage}%)`}
        color={"chartFirstColor"}
      />
      <InformationItem
        amount={formatCurrency(reserved)}
        percentage={`Reserved (${reservedPercentage}%)`}
        color={"chartSecondColor"}
      />
      <InformationItem
        amount={formatCurrency(paid)}
        percentage={`Paid (${paidPercentage}%)`}
        color={"chartThirdColor"}
      />
      <Box
        height={"2px"}
        width={"100%"}
        sx={{ backgroundColor: "#EDEDED", marginTop: "8px" }}
      />
      <Box sx={{ marginTop: "8px" }}>
        <Box
          gap={"8px"}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant={"bodyRegular"}>Total</Typography>
          <Typography variant={"h3"}>{formatCurrency(totalBudget)}</Typography>
        </Box>
        <Typography
          variant={"subtitleRegular"}
          sx={{ marginTop: "27px", display: "block" }}
        >
          Updated on:{" "}
          {dayjs(widgetData?.updatedAt).format("MMM D, YYYY, h:mm A")}
        </Typography>
      </Box>
    </BoxWrapper>
  );
};

export default BudgetInformation;

export const InformationItem = ({
  amount,
  percentage,
  color,
}: {
  amount: string;
  percentage: string;
  color: string;
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        gap={"8px"}
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "16px",
            height: "16px",
            borderRadius: "99px",
            backgroundColor: color,
          }}
        />
        <Typography variant={"bodyRegular"}>{percentage}</Typography>
      </Box>
      <Typography sx={{ fontWidth: 500, fontSize: "18px" }}>
        {amount}
      </Typography>
    </Box>
  );
};
