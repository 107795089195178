import { Box, Button, FormControl, FormLabel, Typography } from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { useEffect, useState } from "react";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";
import { getFileIcon } from "@/utils/getFileIcon.tsx";
import { FILE_SIZE_10MB } from "@/constants";

type FormFileUploadProps = {
  name: string;
  control: Control<any, any>;
  controlProps?: Partial<ControllerProps>;
  label?: string;
  author?: string;
  currentFileInfo?: {
    fileName: string;
    size: string;
  };
  maxFileSize?: number;
};

const FormFileUpload = ({
  name,
  control,
  controlProps,
  label,
  currentFileInfo,
  maxFileSize = FILE_SIZE_10MB,
}: FormFileUploadProps) => {
  const [fileInfo, setFileInfo] = useState<{
    name: string;
    size: number;
  } | null>(null);
  const [error, setError] = useState<string | null>(null);
  const isRequired = !!controlProps?.rules?.required;

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (file: File | null) => void,
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxFileSize) {
        setError("The file is too big");
        setFileInfo(null);
        onChange(null);
      } else {
        setFileInfo({ name: file.name, size: file.size });
        setError(null);
        onChange(file);
      }
    } else {
      setFileInfo(null);
      setError(null);
      onChange(null);
    }
  };

  useEffect(() => {
    if (currentFileInfo) {
      setFileInfo({
        name: currentFileInfo?.fileName,
        size: Number(currentFileInfo?.size),
      });
    } else {
      setFileInfo(null);
    }
  }, [currentFileInfo]);

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: "24px" }}
    >
      <FormLabel component="legend">
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#373533",
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value } }) => (
          <>
            <Box sx={{ display: "flex", alignItems: "center", gap: "16px" }}>
              <Button
                variant="outlinedGray"
                component="label"
                endIcon={!value && <SVGUpload />}
                sx={{
                  textTransform: "none",
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {!value && "Upload File"}
                {fileInfo && (
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      overflow: "hidden",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      {getFileIcon(fileInfo.name)}
                    </Box>
                    <Box sx={{ overflow: "hidden", width: "100%" }}>
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "14px",
                        }}
                      >
                        {fileInfo.name}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          fontWeight: 600,
                          color: "#888684",
                        }}
                      >
                        {formatBytes(fileInfo.size)}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <input
                  type="file"
                  accept=".pdf,.csv,.xlsx,.xlsb,.docx,.png,.jpg,.doc"
                  hidden
                  onChange={(e) => handleFileChange(e, onChange)}
                />
              </Button>
            </Box>
            {error && (
              <Typography sx={{ color: "red", fontSize: "12px" }}>
                {error}
              </Typography>
            )}
          </>
        )}
      />
    </FormControl>
  );
};

export default FormFileUpload;

// Utility function to format file size
export const formatBytes = (bytes: number, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};
