import React, { ReactNode, useState } from "react";
import { Box, Collapse, SxProps, TableRow } from "@mui/material";
import {
  StyledIconButton,
  StyledTableRow,
  StyledTruncatedTypography,
} from "@/components/styled";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import SVGSelectArrowUp from "@/assets/icons/SVGSelectArrowUp.tsx";
import FileComponent from "@/components/FileComponent";
import { getFileIcon } from "@/utils/getFileIcon.tsx";
import RowCheckbox from "@/pages/ProjectsPage/components/RowCheckbox.tsx";

type Props = {
  fileName?: string;
  title: ReactNode;
  firstSub: ReactNode;
  secondSub: ReactNode;
  children: ReactNode;
  navigateTo?: () => void;
  secondSubSx?: SxProps;
  rowId?: number;
  showCheckbox?: boolean;
  tableId?: string;
};

const CollapsableRow = ({
  title,
  firstSub,
  secondSub,
  fileName,
  navigateTo,
  children,
  secondSubSx,
  rowId,
  showCheckbox,
  tableId,
}: Props) => {
  const [open, setOpen] = useState(false);
  return (
    <Box>
      <Box
        sx={{
          padding: `10px ${showCheckbox ? "10px" : "24px"}`,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
          {fileName && getFileIcon(fileName)}
          {showCheckbox && tableId && (
            <RowCheckbox tableId={tableId} rowId={rowId} />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "4px",
              maxWidth: "220px",
              ...secondSubSx,
            }}
          >
            <StyledTruncatedTypography
              onClick={() => {
                if (navigateTo) {
                  navigateTo();
                }
              }}
              variant={"body1Small"}
            >
              {title}
            </StyledTruncatedTypography>
            <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
              <StyledTruncatedTypography variant={"body2Small"}>
                {firstSub} •
              </StyledTruncatedTypography>
              {secondSub}
            </Box>
          </Box>
        </Box>
        <StyledIconButton
          sx={{ border: 0 }}
          variant={"outlinedGray"}
          onClick={() => setOpen(!open)}
        >
          {open ? <SVGSelectArrowUp /> : <SVGSelectArrowDown />}
        </StyledIconButton>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            padding: "10px 24px",
          }}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

export default CollapsableRow;

export const CellWithHeader = ({
  header,
  children,
}: {
  header: string;
  children: ReactNode;
}) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
      <StyledTruncatedTypography variant={"body2Small"}>
        {header}
      </StyledTruncatedTypography>
      <StyledTruncatedTypography
        sx={{ whiteSpace: "normal" }}
        variant={"body1Small"}
      >
        {children}
      </StyledTruncatedTypography>
    </Box>
  );
};
