import dayjs from "dayjs";

export const formatRelativeDate = (dateString: string) => {
  const date = dayjs(dateString);

  if (date.isToday()) {
    return "Today";
  } else if (date.isYesterday()) {
    return "Yesterday";
  } else {
    return date.format("MMMM DD, YYYY");
  }
};
