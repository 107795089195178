import React from "react";
import { Control } from "react-hook-form";
import FormFileUpload from "@/components/FormUploadInput";
import FormInput from "@/components/FormInput";
import { FileRecord, UserType } from "@/types";

const ThirdContractorForm = ({
  control,
  license,
  certification,
}: {
  control: Control;
  license?: any;
  certification?: any;
}) => {
  return (
    <>
      <FormFileUpload
        name="license"
        control={control}
        label="License"
        currentFileInfo={license}
      />
      <FormFileUpload
        name="certification"
        control={control}
        label="Certification"
        currentFileInfo={certification}
      />
      <FormInput
        name={"welcomeMessage"}
        control={control}
        label={"Welcome message"}
        placeholder={"Type here..."}
        multiline
        rows={4}
      />
    </>
  );
};

export default ThirdContractorForm;
