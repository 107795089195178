import React from "react";
import { StyledModalBox } from "@/components/styled";
import { Box, Button, Typography } from "@mui/material";
import SvgSuccessModal from "@/assets/icons/SVGSuccessModal.tsx";
import { TitleTypography } from "@/pages/ContractorDashboardPage/components/NextStepModal.tsx";
import SVGDangerModal from "@/assets/icons/SVGDangerModal.tsx";

type Props = {
  title: string;
  subtitle: string;
  onClose: () => void;
  onClick: () => void;
  buttonText?: string;
  variant?: "red" | "yellow";
};

const DangerModal = ({
  title,
  subtitle,
  onClose,
  buttonText,
  onClick,
  variant,
}: Props) => {
  return (
    <StyledModalBox>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <SVGDangerModal variant={variant} />
        <TitleTypography sx={{ marginTop: "32px" }}>{title}</TitleTypography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "12px",
            textAlign: "center",
          }}
        >
          {subtitle}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "12px",
            width: "100%",
            marginTop: "32px",
          }}
        >
          <Button variant={"outlined"} sx={{ width: "100%" }} onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant={"danger"}
            sx={{
              width: "100%",
            }}
            onClick={onClick}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </StyledModalBox>
  );
};

export default DangerModal;
