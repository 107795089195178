import { SVGProps } from "react";

const SvgCross = (props: SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_535_2280)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9998 13.414L17.6568 19.071C17.8454 19.2532 18.098 19.354 18.3602 19.3517C18.6224 19.3494 18.8732 19.2443 19.0586 19.0588C19.2441 18.8734 19.3492 18.6226 19.3515 18.3604C19.3538 18.0982 19.253 17.8456 19.0708 17.657L13.4138 12L19.0708 6.34303C19.253 6.15443 19.3538 5.90182 19.3515 5.63963C19.3492 5.37743 19.2441 5.12662 19.0586 4.94121C18.8732 4.7558 18.6224 4.65063 18.3602 4.64835C18.098 4.64607 17.8454 4.74687 17.6568 4.92903L11.9998 10.586L6.34282 4.92903C6.15337 4.75137 5.90224 4.65439 5.64255 4.65861C5.38287 4.66283 5.13502 4.76791 4.95143 4.95162C4.76785 5.13533 4.66294 5.38326 4.65891 5.64295C4.65488 5.90263 4.75203 6.1537 4.92982 6.34303L10.5858 12L4.92882 17.657C4.83331 17.7493 4.75713 17.8596 4.70472 17.9816C4.65231 18.1036 4.62473 18.2348 4.62357 18.3676C4.62242 18.5004 4.64772 18.6321 4.698 18.755C4.74828 18.8779 4.82254 18.9895 4.91643 19.0834C5.01032 19.1773 5.12197 19.2516 5.24487 19.3018C5.36777 19.3521 5.49944 19.3774 5.63222 19.3763C5.765 19.3751 5.89622 19.3475 6.01823 19.2951C6.14023 19.2427 6.25058 19.1665 6.34282 19.071L11.9998 13.414Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_535_2280">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgCross;
