import { StyledMenu } from "@/components/styled";
import { Box, MenuItem, MenuProps, Snackbar, Typography } from "@mui/material";
import SVGCopyText from "@/assets/icons/SVGCopyText.tsx";
import SVGEditNote from "@/assets/icons/SVGEditNote.tsx";
import SVGDelete from "@/assets/icons/SVGDelete.tsx";
import { TransactionType } from "@/types";
import { useState } from "react";

type Props = {
  anchorEl: MenuProps["anchorEl"];
  openMenu: boolean;
  handleCloseMenu: () => void;
  handleUpdateNote: () => void;
  onDeleteNote: () => void;
  currentNote: string | null;
};
const NoteActionsMenu = ({
  anchorEl,
  openMenu,
  handleCloseMenu,
  handleUpdateNote,
  currentNote,
  onDeleteNote,
}: Props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopyText = () => {
    if (currentNote) {
      navigator.clipboard
        .writeText(currentNote)
        .then(() => {
          setOpenSnackbar(true);
        })
        .catch((err) => {
          console.error("Failed to copy note: ", err);
        });
    }
    handleCloseMenu();
  };
  return (
    <>
      <StyledMenu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleCopyText}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SVGCopyText />
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              Copy text
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={handleUpdateNote}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SVGEditNote />
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              Edit note
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem onClick={onDeleteNote}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SVGDelete />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#FF4D00" }}
            >
              Delete note
            </Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        open={openSnackbar}
        onClose={() => setOpenSnackbar(false)}
        message="Copied"
        autoHideDuration={800}
      />
    </>
  );
};

export default NoteActionsMenu;
