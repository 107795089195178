import Layout from "@/components/Layout";
import { Box, Grid, styled } from "@mui/material";
import BudgetWidget from "@/components/BudgetWidget";
import LatestUpdates from "@/components/LatestUpdates";
import AnalysisChart from "@/components/AnalysisChart";
import TotalBalance from "@/components/TotalBalance";
import { BoxHeaderInner } from "@/components/styled";

const HomePage = () => {
  return (
    <Layout>
      <Grid
        sx={{
          marginTop: "4px",
          position: "relative",
          zIndex: 10,
          display: "flex",
        }}
        container
        columns={12}
        columnSpacing={"24px"}
        rowSpacing={"24px"}
      >
        <Grid sx={{ width: "100%" }} item xs={12} md={9}>
          <Grid
            container
            rowSpacing={"24px"}
            columnSpacing={"24px"}
            columns={12}
          >
            <Grid item xs={12} lg={12} xl={8}>
              <BudgetWidget />
            </Grid>
            <Grid item xs={12} lg={12} xl={4}>
              <LatestUpdates />
            </Grid>
            <Grid item xs={12} md={12}>
              <AnalysisChart />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "auto",
            width: "100%",
          }}
          item
          xs={12}
          md={3}
        >
          <TotalBalance />
        </Grid>
      </Grid>
      <BoxHeaderInner />
    </Layout>
  );
};

export default HomePage;
