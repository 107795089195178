import React from "react";
import { Checkbox } from "@mui/material";
import { useSelectedRowsStore } from "@/store/selectedRowsStore.ts";

const RowCheckbox = ({ tableId, rowId }: { tableId: string; rowId: any }) => {
  const isSelected = useSelectedRowsStore((state) =>
    state.isRowSelected(tableId, rowId),
  );
  const selectRow = useSelectedRowsStore((state) => state.selectRow);
  const deselectRow = useSelectedRowsStore((state) => state.deselectRow);

  const toggleRowSelection = () => {
    if (isSelected) {
      deselectRow(tableId, rowId);
    } else {
      selectRow(tableId, rowId);
    }
  };

  return <Checkbox checked={isSelected} onChange={toggleRowSelection} />;
};

export default RowCheckbox;
