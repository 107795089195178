import React, { useState } from "react";

const useDebouncedSearch = (
  handleChangePage: (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void,
) => {
  const [searchValue, setSearchValue] = useState("");
  const handleDebounceSearchValue = (value: string) => {
    setSearchValue(value);
    handleChangePage(null, 0);
  };

  return { searchValue, handleDebounceSearchValue };
};

export default useDebouncedSearch;
