import React, { useState } from "react";
import { Box, Collapse, Grid, Typography } from "@mui/material";
import Card from "@/components/Card";
import { UserType } from "@/types";
import { DetailItem } from "@/components/TotalBalance/ActionDetailsModal.tsx";
import { StyledIconButton } from "@/components/styled";
import SVGSelectArrowUp from "@/assets/icons/SVGSelectArrowUp.tsx";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import UpdateUserAdminWrapper from "@/pages/admin-pages/UserManagementPage/components/UpdateUserModal/UpdateUserAdminWrapper.tsx";

const ManufacturerInfo = ({ user }: { user: UserType }) => {
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);
  const toggleExpand = (index: number) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };
  return (
    <Card>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant={"cardHeader2"}>
          Manufacturer information
        </Typography>
        <Box>
        <UpdateUserAdminWrapper userId={user?.id} iconOnly/>
        </Box>
      </Box>
      <Grid
        container
        columns={12}
        columnSpacing={"175px"}
        sx={{ marginTop: "24px" }}
      >
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <DetailItem
              field={"Manufacturer Brand"}
              value={user?.manufactoringBrand ?? ""}
            />
            <DetailItem
              field={"Contact Name(s)"}
              value={user?.contactName ?? ""}
            />
            <DetailItem
              field={"Contact Email(s)"}
              value={user?.contactName ?? ""}
            />
            <DetailItem
              field={"Integrated DERMs"}
              value={user?.dermsList ?? ""}
            />
            <DetailItem
              field={"API Integrations"}
              value={user?.apiIntegrations ?? ""}
            />
            <DetailItem
              field={"Support IEEE 2030.5 protocol"}
              value={user?.ieeeProtocol ? "Yes" : "No"}
            />
            <DetailItem
              field={"BESS Operator maintain telemetry data interval "}
              value={user?.maintainingTelemetryDataInterval ?? ""}
            />
            <DetailItem
              field={"ACH Information"}
              value={user?.achInformation ?? ""}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
            <DetailItem field={"Battery model"} value={""} />
            <Box sx={{ marginTop: "6px" }}>
              {user?.battery?.map((el, index) => (
                <Box key={el.id}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                      {el?.batteryModel}
                    </Typography>
                    <StyledIconButton onClick={() => toggleExpand(index)}>
                      {expandedIndices.includes(index) ? (
                        <SVGSelectArrowUp color={"#4E4B48"} />
                      ) : (
                        <SVGSelectArrowDown />
                      )}
                    </StyledIconButton>
                  </Box>
                  <Collapse
                    sx={{ paddingTop: "16px" }}
                    in={expandedIndices.includes(index)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "24px",
                        marginBottom: "24px",
                      }}
                    >
                      <DetailItem
                        field={"Battery Type"}
                        value={el?.batteryType ?? ""}
                      />
                      <DetailItem
                        field={"Battery Location"}
                        value={el?.batteryLocations ?? ""}
                      />
                      <DetailItem
                        field={"Inverted Models"}
                        value={el?.inverterModels ?? ""}
                      />
                    </Box>
                  </Collapse>
                </Box>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ManufacturerInfo;
