import React from "react";
import Card from "@/components/Card";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useUserStore } from "@/store/userStore.ts";

const TypographyTitle = styled(Typography)(({ theme }) => ({
  color: "#4E4B48",
  fontWeight: 700,
  fontSize: "36px",
  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
    fontWeight: 600,
  },
}));

const TypographySubtitle = styled(Box)(({ theme }) => ({
  color: "#716F6D",
  fontWeight: 500,
  fontSize: "18px",
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

const WelcomeCard = () => {
  const theme = useTheme();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));
  const user = useUserStore((state) => state.user);
  return (
    <Card>
      <Box sx={{ display: "flex", gap: "24px" }}>
        {!matchesDownLg && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "72px",
              height: "72px",
              backgroundColor: "#EDF1F3",
              borderRadius: "8px",
              fontSize: "28px",
            }}
          >
            👋
          </Box>
        )}
        <Box>
          <TypographyTitle>
            Welcome, {user?.fullName}!{matchesDownLg && "👋"}
          </TypographyTitle>
          <TypographySubtitle>
            This training ensures you can effectively inform and engage
            customers
          </TypographySubtitle>
        </Box>
      </Box>
    </Card>
  );
};

export default WelcomeCard;
