import React, { ReactNode, useMemo, useState } from "react";
import {
  Modal as MuiModal,
  TableBody,
  tableCellClasses,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { UserType } from "@/types";
import {
  StyledBadge,
  StyledIconButton,
  StyledPinnedCell,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import SVGTrashCan from "@/assets/icons/SVGTrashCan.tsx";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import { getStatusItems } from "@/utils/getStatusItem.ts";
import { deleteUserReq, restoreUserReq, updateUserReq } from "@/api/adminApi";
import { useAdminStore } from "@/store/adminStore.ts";
import dayjs from "dayjs";
import { getRoleLabel } from "@/utils/getRoleLabel.tsx";
import DangerModal from "@/components/DangerModal";
import UploadProjectFile from "@/components/UploadProjectFile";
import { uploadFileReq } from "@/api/fileApi";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import UpdateUserAdminWrapper from "@/pages/admin-pages/UserManagementPage/components/UpdateUserModal/UpdateUserAdminWrapper.tsx";
import SVGRefresh from "@/assets/icons/SVGRefresh.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import { formatDate } from "@/utils/formatDate.ts";

function createData(
  accountId: number,
  name: string,
  company: string,
  email: string,
  role: UserType["role"],
  status: UserType["status"],
  lastLogin: string,
  createdAt: string,
) {
  return {
    accountId,
    name,
    company,
    email,
    role,
    status,
    lastLogin: lastLogin ? dayjs(lastLogin).format("MMMM D, YYYY") : "",
    createdAt: dayjs(createdAt).format("MMMM D, YYYY"),
  };
}

type Props = {
  users: UserType[];
  pagination: ReactNode;
  navigateToUser: (userId: number) => void;
};

const AccountManagementTable = ({
  users,
  pagination,
  navigateToUser,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [deleteUserId, setDeleteUserId] = useState(0);
  const updateUsers = useAdminStore((state) => state.updateUsers);

  const handleCloseModal = () => setDeleteUserId(0);

  const handleDeleteUser = async () => {
    try {
      const response = await deleteUserReq(deleteUserId);
      setDeleteUserId(0);
      updateUsers(deleteUserId, response?.data?.user);
    } catch (e) {
      console.error(e);
    }
  };

  const rows = useMemo(() => {
    return users?.map(
      ({
        id,
        companyName,
        lastLogin,
        fullName,
        role,
        email,
        status,
        createdAt,
      }) =>
        createData(
          id,
          fullName,
          companyName ?? "",
          email,
          role,
          status,
          lastLogin,
          createdAt,
        ),
    );
  }, [users]);

  const uploadFile = async (userId: UserType["id"], data: FormData) => {
    try {
      await uploadFileReq(userId, data);
    } catch (e) {
      console.error(e);
    }
  };

  const updateUserStatus = async (userId: UserType["id"], value: any) => {
    try {
      return await updateUserReq(userId, { status: value } as any);
    } catch (e) {
      console.error(e);
      throw e;
    }
  };
  const handleRestoreUser = async (userId: UserType["id"]) => {
    try {
      const response = await restoreUserReq(userId);
      updateUsers(userId, response?.data?.user);
    } catch (e) {
      console.error(e);
    }
  };

  const isDeleted = (status: string) => status === "deleted";

  return (
    <>
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Account ID</StyledTableCell>
              <StyledTableCell>Name</StyledTableCell>
              <StyledTableCell>Company</StyledTableCell>
              <StyledTableCell>Role</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Creation date</StyledTableCell>
              <StyledTableCell>Last login</StyledTableCell>
              <StyledTableCell sx={{ minWidth: "150px" }}></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows?.map((row) =>
              downSm ? (
                <CollapsableRow
                  key={row.accountId}
                  title={row.name}
                  navigateTo={() => navigateToUser(row.accountId)}
                  firstSub={row.email}
                  secondSub={
                    <>
                      {!isDeleted(row.status) && (
                        <BadgeSelect
                          badgeVariantFn={(value) =>
                            getBadgeVariant(null, value)
                          }
                          currentSelectedItem={row.status}
                          items={getStatusItems(row.role)}
                          onSelectItem={(value) =>
                            updateUserStatus(row.accountId, value)
                          }
                        />
                      )}
                      {isDeleted(row.status) && (
                        <StyledBadge
                          sx={{ display: "inline-flex", textWrap: "nowrap" }}
                          {...getBadgeVariant(null, row.status)}
                        >
                          {capitalizeFirstLetter(row.status)}
                        </StyledBadge>
                      )}
                    </>
                  }
                >
                  <CellWithHeader header={"Account ID"}>
                    {row.accountId}
                  </CellWithHeader>
                  <CellWithHeader header={"Creation date"}>
                    {formatDate(row.createdAt)}
                  </CellWithHeader>
                  <CellWithHeader header={"Last login"}>
                    {row.lastLogin}
                  </CellWithHeader>
                  {!isDeleted(row.status) && (
                    <StyledRowActionsWrapperBox>
                      <UploadProjectFile
                        uploadFile={(data) => uploadFile(row.accountId, data)}
                      />
                      <UpdateUserAdminWrapper userId={row.accountId} />

                      <StyledIconButton
                        variant={downSm ? "dangerOutlined" : "text"}
                        sx={{ flex: 1 }}
                        onClick={() => setDeleteUserId(row.accountId)}
                      >
                        {downSm ? "Delete" : <SVGTrashCan />}
                      </StyledIconButton>
                    </StyledRowActionsWrapperBox>
                  )}
                  {isDeleted(row.status) && (
                    <StyledRowActionsWrapperBox>
                      <StyledIconButton
                        variant={downSm ? "outlined" : "text"}
                        sx={{ flex: 1 }}
                        onClick={() => handleRestoreUser(row?.accountId)}
                      >
                        {downSm ? "Restore" : <SVGRefresh />}
                      </StyledIconButton>
                    </StyledRowActionsWrapperBox>
                  )}
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.accountId}>
                  <StyledTableCell component="th" scope="row">
                    {row.accountId}
                  </StyledTableCell>
                  <StyledTableCell
                    onClick={() => navigateToUser(row.accountId)}
                    sx={{ cursor: "pointer" }}
                  >
                    {row.name}
                  </StyledTableCell>
                  <StyledTableCell>{row.company}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getBadgeVariant(row.role, null)}
                    >
                      {getRoleLabel(row.role)}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>
                    {!isDeleted(row.status) && (
                      <BadgeSelect
                        badgeVariantFn={(value) => getBadgeVariant(null, value)}
                        currentSelectedItem={row.status}
                        items={getStatusItems(row.role)}
                        onSelectItem={(value) =>
                          updateUserStatus(row.accountId, value)
                        }
                      />
                    )}
                    {isDeleted(row.status) && (
                      <StyledBadge
                        sx={{ display: "inline-flex", textWrap: "nowrap" }}
                        {...getBadgeVariant(null, row.status)}
                      >
                        {capitalizeFirstLetter(row.status)}
                      </StyledBadge>
                    )}
                  </StyledTableCell>
                  <StyledTableCell>{row.createdAt}</StyledTableCell>
                  <StyledTableCell>{row.lastLogin}</StyledTableCell>
                  <StyledPinnedCell
                    sx={{
                      [`&.${tableCellClasses.body}`]: { paddingTop: "20px" },
                    }}
                  >
                    {!isDeleted(row.status) && (
                      <StyledRowActionsWrapperBox>
                        <UploadProjectFile
                          uploadFile={(data) => uploadFile(row.accountId, data)}
                        />
                        <UpdateUserAdminWrapper userId={row.accountId} />

                        <StyledIconButton
                          onClick={() => setDeleteUserId(row.accountId)}
                        >
                          <SVGTrashCan />
                        </StyledIconButton>
                      </StyledRowActionsWrapperBox>
                    )}
                    {isDeleted(row.status) && (
                      <StyledRowActionsWrapperBox>
                        <StyledIconButton
                          onClick={() => handleRestoreUser(row?.accountId)}
                        >
                          <SVGRefresh />
                        </StyledIconButton>
                      </StyledRowActionsWrapperBox>
                    )}
                  </StyledPinnedCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <MuiModal open={!!deleteUserId} onClose={handleCloseModal}>
        <>
          <DangerModal
            onClick={handleDeleteUser}
            onClose={handleCloseModal}
            title={`Delete Account?`}
            subtitle={`This step cannot be undone.`}
            buttonText={"Delete"}
          />
        </>
      </MuiModal>
      {pagination}
    </>
  );
};

export default AccountManagementTable;
