import { UserType } from "@/types";

export const getStatusItems = (role: UserType["role"]) => {
  const items: { value: UserType["status"]; label: string; color: string }[] = [
    { value: "approved", label: "approved", color: "#007586" },
    { value: "pending_approval", label: "pending approval", color: "#E88A00" },
    { value: "suspended", label: "suspended", color: "#616263" },
  ];

  if (role !== "administrator") {
    items.splice(2, 0, {
      value: "passed_training",
      label: "passed training",
      color: "#383FB6",
    });
  }

  return items;
};
