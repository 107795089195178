import React from "react";
import { Box, Button } from "@mui/material";
import Modal from "@/components/Modal";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import DOMPurify from "dompurify";
import { ArticleType } from "@/types";
import { useAuthStore } from "@/store/authStore.ts";

type Props = {
  open: boolean;
  onClose: () => void;
  handleOpenEditArticle: () => void;
  article: ArticleType;
};

const ViewArticle = ({
  open,
  onClose,
  handleOpenEditArticle,
  article,
}: Props) => {
  const role = useAuthStore((state) => state.role);
  const isAdmin = role === "administrator";
  const handleClose = () => {
    onClose();
  };
  return (
    <Modal
      boxSx={{
        maxWidth: "1000px",
        width: { xs: "calc(100% - 35px)" },
      }}
      disableEnforceFocus
      open={open}
      onClose={handleClose}
      title={article?.title}
      additionalButtons={
        isAdmin ? (
          <Box>
            <Button
              onClick={() => {
                handleOpenEditArticle();
                handleClose();
              }}
              sx={{
                width: "48px",
                height: "48px",
                minWidth: "initial",
                padding: 0,
              }}
              variant={"outlined"}
            >
              <SVGUserEdit />
            </Button>
          </Box>
        ) : null
      }
    >
      <Box sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
        <Box
          sx={{ marginTop: "32px" }}
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(article?.content),
          }}
        />
      </Box>
    </Modal>
  );
};

export default ViewArticle;
