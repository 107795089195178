import React from "react";
import { Checkbox } from "@mui/material";
import { useSelectedRowsStore } from "@/store/selectedRowsStore.ts";

const GeneralCheckbox = ({ tableId }: { tableId: string }) => {
  const isSelectAll = useSelectedRowsStore(
    (state) => state.tables?.[tableId]?.isSelectAll,
  );
  const selectedRows = useSelectedRowsStore(
    (state) => state.tables?.[tableId]?.selectedRows?.size,
  );
  const toggleSelectAll = useSelectedRowsStore(
    (state) => state.toggleSelectAll,
  );

  return (
    <Checkbox
      checked={isSelectAll}
      indeterminate={!isSelectAll && selectedRows > 0}
      onChange={() => toggleSelectAll(tableId)}
    />
  );
};

export default GeneralCheckbox;
