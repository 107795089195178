import {
  Box,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Typography,
  useTheme,
  styled,
} from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { ReactNode } from "react";

type FormRadioListProps = {
  name: string;
  control: Control<any, any>;
  label?: ReactNode;
  controlProps?: Partial<ControllerProps>;
  items: { value: string; label: string }[];
};

const RadioItemLabel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}));

const FormRadioList = ({
  name,
  control,
  label,
  controlProps,
  items,
}: FormRadioListProps) => {
  const { palette } = useTheme();
  const isRequired = !!controlProps?.rules?.required;

  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <FormLabel component="legend" sx={{ width: "100%", display: "flex" }}>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#373533",
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}{" "}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <Box>
            <RadioGroup
              row
              value={value}
              onChange={(e) => onChange(e.target.value)}
            >
              {items.map((item) => (
                <RadioItemLabel key={item.value}>
                  <Radio
                    value={item.value}
                    sx={{ color: "#0076CE" }}
                    checked={value === item.value}
                  />
                  <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                    {item.label}
                  </Typography>
                </RadioItemLabel>
              ))}
            </RadioGroup>
            <Typography
              sx={{
                fontSize: "12px",
                color: palette.errorText,
                paddingTop: "4px",
                opacity: !!error?.message ? 1 : 0,
              }}
            >
              {error?.message || "helper text"}
            </Typography>
          </Box>
        )}
      />
    </FormControl>
  );
};

export default FormRadioList;
