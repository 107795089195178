import React from "react";

const SvgTraining = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 21H4C2.895 21 2 20.105 2 19V5C2 3.895 2.895 3 4 3H20C21.105 3 22 3.895 22 5V19C22 20.105 21.105 21 20 21Z"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2 8H22"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.7793 5.49012C4.7763 5.49012 4.7743 5.49212 4.7743 5.49512C4.7743 5.49812 4.7763 5.50012 4.7793 5.50012C4.7823 5.50012 4.7843 5.49812 4.7843 5.49512C4.7843 5.49212 4.7823 5.49012 4.7793 5.49012"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.32227 5.49012C7.31927 5.49012 7.31727 5.49212 7.31727 5.49512C7.31727 5.49812 7.32027 5.50012 7.32227 5.50012C7.32527 5.50012 7.32727 5.49812 7.32727 5.49512C7.32727 5.49212 7.32527 5.49012 7.32227 5.49012"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.86133 5.49012C9.85833 5.49012 9.85633 5.49212 9.85633 5.49512C9.85633 5.49812 9.85833 5.50012 9.86133 5.50012C9.86433 5.50012 9.86633 5.49812 9.86633 5.49512C9.86633 5.49212 9.86433 5.49012 9.86133 5.49012"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9262 12L14.0002 16L10.0002 13L7.07422 17"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgTraining;
