const SvgActivityDeducted = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 4C19.7652 3.99996 20.5015 4.29233 21.0583 4.81728C21.615 5.34224 21.9501 6.06011 21.995 6.824L22 7V17C22 17.7652 21.7077 18.5015 21.1827 19.0583C20.6578 19.615 19.9399 19.9501 19.176 19.995L19 20H5C4.23479 20 3.49849 19.7077 2.94174 19.1827C2.38499 18.6578 2.04989 17.9399 2.005 17.176L2 17V7C1.99996 6.23479 2.29233 5.49849 2.81728 4.94174C3.34224 4.38499 4.06011 4.04989 4.824 4.005L5 4H19ZM20 10H4V17C4.00003 17.2449 4.08996 17.4813 4.25272 17.6644C4.41547 17.8474 4.63975 17.9643 4.883 17.993L5 18H19C19.2449 18 19.4813 17.91 19.6644 17.7473C19.8474 17.5845 19.9643 17.3603 19.993 17.117L20 17V10ZM14.707 11.464L16.535 13.293C16.7225 13.4805 16.8278 13.7348 16.8278 14C16.8278 14.2652 16.7225 14.5195 16.535 14.707L14.707 16.535C14.6148 16.6305 14.5044 16.7067 14.3824 16.7591C14.2604 16.8115 14.1292 16.8391 13.9964 16.8403C13.8636 16.8414 13.7319 16.8161 13.609 16.7658C13.4861 16.7155 13.3745 16.6413 13.2806 16.5474C13.1867 16.4535 13.1125 16.3419 13.0622 16.219C13.0119 16.0961 12.9866 15.9644 12.9877 15.8316C12.9889 15.6988 13.0165 15.5676 13.0689 15.4456C13.1213 15.3236 13.1975 15.2132 13.293 15.121L13.413 15H9C8.73478 15 8.48043 14.8946 8.29289 14.7071C8.10536 14.5196 8 14.2652 8 14C8 13.7348 8.10536 13.4804 8.29289 13.2929C8.48043 13.1054 8.73478 13 9 13H13.414L13.293 12.879C13.1054 12.6915 12.9999 12.4371 12.9998 12.1719C12.9997 11.9066 13.105 11.6521 13.2925 11.4645C13.48 11.2769 13.7344 11.1714 13.9996 11.1713C14.2649 11.1712 14.5194 11.2765 14.707 11.464ZM19 6H5C4.73478 6 4.48043 6.10536 4.29289 6.29289C4.10536 6.48043 4 6.73478 4 7V8H20V7C20 6.73478 19.8946 6.48043 19.7071 6.29289C19.5196 6.10536 19.2652 6 19 6Z"
        fill="#8C2A00"
      />
    </svg>
  );
};

export default SvgActivityDeducted;
