import React from "react";

const SvgArchive = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.9999 7H2.99988C2.44788 7 1.99988 6.552 1.99988 6V4C1.99988 3.448 2.44788 3 2.99988 3H20.9999C21.5519 3 21.9999 3.448 21.9999 4V6C21.9999 6.552 21.5519 7 20.9999 7Z"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99988 11H14.9999"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.6669 7V19C20.6669 20.105 19.7719 21 18.6669 21H5.33289C4.22789 21 3.33289 20.105 3.33289 19V7"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgArchive;
