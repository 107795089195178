import { FileRecord, UserType } from "@/types";
import axios from "@/libs/axios.ts";
import { DateRange } from "rsuite/DateRangePicker";
import dayjs from "dayjs";

export const uploadTemplateReq = async (body: FormData) =>
  axios.post(`/template`, body);
export const deleteTemplateReq = async (templateId: FileRecord["id"]) =>
  axios.delete(`/template/${templateId}`);
export const restoreTemplateReq = async (templateId: FileRecord["id"]) =>
  axios.post(`/template/${templateId}/restore`);
export const updateTemplateReq = async (
  templateId: FileRecord["id"],
  body: { type: string },
) => axios.patch(`/template/${templateId}`, body);

export const getTemplatesReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  sort: string,
  filter = [] as string[],
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    sort,
    filter: filter.join(","),
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/template/list`, { params });
};
