import React, { useMemo, useState } from "react";
import { Box, debounce, InputProps, styled } from "@mui/material";
import { StyledInput } from "@/components/styled";
import SVGSearch from "@/assets/icons/SVGSearch.tsx";

const BoxIcon = styled(Box)(() => ({
  position: "absolute",
  left: "15px",
  top: 0,
  transform: "translateY(50%)",
  display: "flex",
  cursor: "pointer",
}));

const StyledSearchInput = styled(StyledInput)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    "& .MuiInputBase-input": {
      minWidth: "43px",
      paddingLeft: "44px",
      "&:placeholder-shown": {
        textOverflow: "ellipsis",
        overflow: "hidden", // Ensure overflow is hidden
        whiteSpace: "nowrap", // Prevent text wrapping
      },
    },
  },
}));

const SearchInput = ({
  onDebounce,
  ...props
}: { onDebounce: (value: string) => void } & InputProps) => {
  const [inputValue, setInputValue] = useState("");

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500,
      ),
    [],
  );

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <StyledSearchInput
        value={inputValue}
        iconstart
        fullWidth
        onChange={(e) => {
          const newValue = e.target.value;
          setInputValue(e.target.value);
          handleDebouncedValue(newValue, onDebounce);
        }}
        {...props}
        placeholder={"Search..."}
      />
      <BoxIcon>
        <SVGSearch />
      </BoxIcon>
    </Box>
  );
};

export default SearchInput;
