import axios from "@/libs/axios.ts";

export async function downloadFile(url: string, fileName: string) {
  try {
    // Fetch the file as a Blob
    const response = await axios.get(url, {
      responseType: "blob",
    });

    // Create a new Blob object using the response data
    const blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });

    // Create a temporary link element
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;

    // Append the link to the document and trigger a click to start the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
  } catch (error) {
    console.error("Failed to download file:", error);
  }
}
