import React from "react";
import { Box, Grid } from "@mui/material";
import BudgetWidget from "@/components/BudgetWidget";
import LatestUpdates from "@/components/LatestUpdates";
import AnalysisChart from "@/components/AnalysisChart";
import TotalBalance from "@/components/TotalBalance";
import Layout from "@/components/Layout";
import { BoxHeaderInner } from "@/components/styled";
import WelcomeCard from "@/pages/ContractorDashboardPage/components/WelcomeCard.tsx";
import StatusCard from "@/pages/ContractorDashboardPage/components/StatusCard.tsx";
import TrainingCard from "@/pages/ContractorDashboardPage/components/TrainingCard.tsx";

const ContractorDashboardPage = () => {
  return (
    <Layout>
      <Grid
        sx={{
          marginTop: "4px",
          position: "relative",
          zIndex: 10,
          display: "flex",
        }}
        container
        columns={12}
        columnSpacing={"24px"}
        rowSpacing={"24px"}
      >
        <Grid sx={{ width: "100%" }} item xs={12} md={9}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: "24px",
            }}
          >
            <WelcomeCard />
            <StatusCard />
            <TrainingCard />
          </Box>
        </Grid>
      </Grid>
      <BoxHeaderInner />
    </Layout>
  );
};

export default ContractorDashboardPage;
