import React, { useCallback, useEffect, useMemo } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import {
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BoxSearchWrapper,
  StyledRightActionButton,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import TablePagination from "@/components/TablePagination";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import { FileRecord, TemplateRowType } from "@/types";
import FileTableItem from "@/components/FileTableItem";
import { useAuthStore } from "@/store/authStore.ts";
import {
  deleteTemplateReq,
  restoreTemplateReq,
  uploadTemplateReq,
} from "@/api/templateApi";
import { formatBytes } from "@/components/FormUploadInput";
import TableFilter from "@/components/TableFilter";
import {
  COMMON_SORT_ITEMS,
  FilesManagementCSVHeaders,
  TEMPLATE_TYPES_FILTER_ITEMS,
} from "@/constants";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import CommonTableActions from "@/components/CommonTableActions";
import TableSort from "@/components/TableSort";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import SVGDownload from "@/assets/icons/SVGDownload.tsx";
import UploadProjectFile from "@/components/UploadProjectFile";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import TemplatesActionsWrapper from "@/pages/admin-pages/FileManagementPage/copmonents/TemplateManagement/TemplatesActionsWrapper.tsx";
import { getMappedFileType } from "@/utils/getMappedFileType.ts";

function createTemplateData(
  id: number,
  fileName: string,
  fileSize: string,
  createdAt: string,
  deletedAt: string | null,
  fileType: string,
  accountName: string,
  accountEmail: string,
  url: string,
): TemplateRowType {
  return {
    id,
    fileName,
    fileSize: fileSize,
    deletedAt,
    createdAt: createdAt,
    fileType,
    accountName,
    accountEmail,
    url,
  };
}

const TemplateManagementTable = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const fetchDataWithLoading = useFetchDataWithLoading();

  const role = useAuthStore((state) => state.role);

  const { templateFiles, totalTemplateFilesCount } = useAdminStore(
    (state) => state.templateFiles,
  );
  const getTemplateFiles = useAdminStore((state) => state.getTemplateFiles);
  const rows = useMemo(() => {
    return templateFiles?.map(
      ({
        id,
        fileName,
        size,
        createdAt,
        deletedAt,
        type,
        accountName,
        accountEmail,
        url,
      }) =>
        createTemplateData(
          id,
          fileName,
          size,
          createdAt,
          deletedAt,
          type,
          accountName ?? "",
          accountEmail,
          url,
        ),
    );
  }, [templateFiles]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalTemplateFilesCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { dateRangeValue, handleDateRangeChange } = useDateRange();
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);

  const getFilesReq = useCallback(() => {
    fetchDataWithLoading(getTemplateFiles, [
      page + 1,
      rowsPerPage,
      searchValue,
      sort,
      filters,
      dateRangeValue,
    ]);
  }, [
    fetchDataWithLoading,
    getTemplateFiles,
    page,
    rowsPerPage,
    searchValue,
    sort,
    filters,
    dateRangeValue,
  ]);

  useEffect(() => {
    getFilesReq();
  }, [getFilesReq]);
  const deleteFile = async (fileId: FileRecord["id"]) => {
    try {
      await deleteTemplateReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };

  const restoreFile = async (fileId: FileRecord["id"]) => {
    try {
      await restoreTemplateReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };

  const uploadFile = async (data: FormData) => {
    try {
      await uploadTemplateReq(data);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };
  const isAdmin = role === "administrator";
  return (
    <StyledTableCard sx={{ width: "100%" }}>
      <CommonTableActions
        title={"Templates"}
        onSearch={handleDebounceSearchValue}
        onDateRange={handleDateRangeChange}
        filter={
          isAdmin ? (
            <TableFilter
              placeholder={"Types"}
              items={TEMPLATE_TYPES_FILTER_ITEMS}
              setFilterValues={handleFilterChange}
            />
          ) : null
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
        rightActions={
          <BoxSearchWrapper sx={{ justifyContent: "flex-end" }}>
            <StyledRightActionButton
              sx={{ whiteSpace: "nowrap", gap: "6px" }}
              onClick={() => {
                downloadCSV(
                  transformLogToCSV(templateFiles as any),
                  "files",
                  FilesManagementCSVHeaders,
                );
              }}
              variant={"outlined"}
            >
              <SVGDownload />
              {!downSm && "Download CSV report"}
            </StyledRightActionButton>
            {isAdmin && (
              <UploadProjectFile
                showProjectSelect={false}
                isButton={true}
                uploadFile={uploadFile}
              />
            )}
          </BoxSearchWrapper>
        }
      />
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>File name</StyledTableCell>
              <StyledTableCell>File size</StyledTableCell>
              <StyledTableCell>File type</StyledTableCell>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>Account email</StyledTableCell>
              <StyledTableCell>Upload date</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  rowId={row.id}
                  key={row.id}
                  title={row.fileName}
                  fileName={row.fileName}
                  firstSub={getMappedFileType(row.fileType)}
                  secondSubSx={{ maxWidth: "185px" }}
                  secondSub={<></>}
                >
                  <CellWithHeader header={"File size"}>
                    {row.fileSize}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Name"}>
                    {row.accountName}
                  </CellWithHeader>
                  <CellWithHeader header={"Account Email"}>
                    {row.accountEmail}
                  </CellWithHeader>
                  <CellWithHeader header={"Upload date"}>
                    {formatDate(row.createdAt)}
                  </CellWithHeader>
                  <TemplatesActionsWrapper
                    isDeleted={!!row.deletedAt}
                    isAdmin={isAdmin}
                    row={row}
                    getFilesReq={getFilesReq}
                    deleteFile={() => deleteFile(row.id)}
                    restoreFile={() => restoreFile(row.id)}
                  />
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>
                    <FileTableItem
                      url={`/template/${row.id}`}
                      fileName={row.fileName}
                    />
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatBytes(Number(row.fileSize))}
                  </StyledTableCell>
                  <StyledTableCell>
                    {getMappedFileType(row.fileType)}
                  </StyledTableCell>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.accountEmail}</StyledTableCell>
                  <StyledTableCell>{formatDate(row.createdAt)}</StyledTableCell>
                  <StyledTableCell>
                    <StyledRowActionsWrapperBox>
                      <TemplatesActionsWrapper
                        isDeleted={!!row.deletedAt}
                        isAdmin={isAdmin}
                        row={row}
                        getFilesReq={getFilesReq}
                        deleteFile={() => deleteFile(row.id)}
                        restoreFile={() => restoreFile(row.id)}
                      />
                    </StyledRowActionsWrapperBox>
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalTemplateFilesCount}
      />
    </StyledTableCard>
  );
};

export default TemplateManagementTable;
