import { create } from "zustand";
import { UserType } from "@/types";
import { getMeReq } from "@/api/userApi";

type State = {
  user: UserType;
  profileCompletion: string;
};

type Actions = {
  getMe: () => void;
  updateUser: (body: Partial<UserType>) => void;
};

export const useUserStore = create<State & Actions>((set) => ({
  user: {} as UserType,
  profileCompletion: "0",
  getMe: async () => {
    try {
      const response = await getMeReq();
      set(() => ({
        user: response?.data?.user,
        profileCompletion: response?.data?.profileCompletion,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  updateUser: (body) => {
    set((state) => ({
      user: { ...state.user, ...body },
    }));
  },
}));
