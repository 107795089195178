import React, { useCallback, useMemo } from "react";
import { RowsPerPageOptions } from "@/constants";

const useTablePagination = (rows = [] as any[], totalRows: number) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(RowsPerPageOptions[0]);

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = useMemo(() => {
    return page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalRows) : 0;
  }, [page, rowsPerPage, totalRows]);

  const handleChangePage = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
    },
    [],
  );

  const handleChangeRowsPerPage = useCallback((event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const paginatedRows = useMemo(() => {
    return rowsPerPage > 0
      ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : rows;
  }, [page, rows, rowsPerPage]);

  return {
    emptyRows,
    handleChangeRowsPerPage,
    handleChangePage,
    page,
    rowsPerPage,
    paginatedRows,
  };
};

export default useTablePagination;
