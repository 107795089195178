import axios from "@/libs/axios.ts";
import { TransferActionType } from "@/types";

export const getBudgetList = async () => axios.get(`/budget`);
export const addTotalBudget = async (body: { amount: number; note?: string }) =>
  axios.post(`/budget/transactions/add`, body);

export const deductTotalBudget = async (body: {
  amount: number;
  note?: string;
}) => axios.post(`/budget/transactions/deduct`, body);
export const transferBudget = async (body: {
  amount: number;
  note?: string;
  from: TransferActionType;
  to: TransferActionType;
}) => axios.post(`/budget/transactions/transfer`, body);

export const getTransactionsList = async () =>
  axios.get(`/budget/transactions/`);
export const getAllTransactionsReq = async () =>
  axios.get(`/budget/transactions/csv`);

export const editNoteRequest = async (
  transactionId: number,
  body: { note: string },
) => axios.put(`/budget/transactions/note/${transactionId}`, body);

export const deleteNoteRequest = async (transactionId: number) =>
  axios.delete(`/budget/transactions/note/${transactionId}`);
