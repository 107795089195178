import React, { useState } from "react";
import { StyledBadge, StyledMenu } from "@/components/styled";
import {
  Box,
  MenuItem,
  Modal as MuiModal,
  styled,
  Typography,
} from "@mui/material";
import { useUserStore } from "@/store/userStore.ts";
import { useAuthStore } from "@/store/authStore.ts";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import { getRoleLabel } from "@/utils/getRoleLabel.tsx";
import SVGDelete from "@/assets/icons/SVGDelete.tsx";
import SVGLogout from "@/assets/icons/SVGLogout.tsx";
import { useNavigate } from "react-router-dom";
import SVGPrivacyPolicy from "@/assets/icons/SVGPrivacyPolicy.tsx";
import NextStepModal from "@/pages/ContractorDashboardPage/components/NextStepModal.tsx";

const AvatarBox = styled(Box)(() => ({
  minWidth: "48px",
  height: "48px",
  borderRadius: "99px",
  backgroundColor: "#b0caff",
  cursor: "pointer",
}));

const ProfileButton = () => {
  const user = useUserStore((state) => state.user);
  const logout = useAuthStore((state) => state.logout);
  const role = useAuthStore((state) => state.role);

  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const handleCloseLogoutModal = () => {
    setOpenLogoutModal(false);
  };

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logoutAndRedirect = () => {
    logout();
    navigate("/login");
  };

  const redirectToPrivacyPolicy = () => {
    navigate("/privacy-policy");
  };

  return (
    <>
      <AvatarBox onClick={handleClick} />
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ "& .MuiPaper-root": { minWidth: "310px" } }}
      >
        <Box
          onClick={() => navigate("/user-profile")}
          sx={{
            padding: "8px 16px",
            display: "flex",
            gap: "10px",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <AvatarBox />
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Typography variant={"cardHeader2"} sx={{ fontSize: "16px" }}>
              {user?.fullName}
            </Typography>
            <StyledBadge
              sx={{ display: "inline", textWrap: "nowrap" }}
              {...getBadgeVariant(role, null)}
            >
              {getRoleLabel(role!)}
            </StyledBadge>
          </Box>
        </Box>
        <MenuItem
            sx={{ padding: "12px 16px" }}
            onClick={redirectToPrivacyPolicy}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SVGPrivacyPolicy />
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              Privacy Policy
            </Typography>
          </Box>
        </MenuItem>
        <MenuItem
          sx={{ padding: "12px 16px" }}
          onClick={() => setOpenLogoutModal(true)}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <SVGLogout />
            <Typography
              sx={{ fontSize: "14px", fontWeight: 600, color: "#FF4D00" }}
            >
              Log out
            </Typography>
          </Box>
        </MenuItem>
      </StyledMenu>
      <MuiModal open={openLogoutModal} onClose={handleCloseLogoutModal}>
        <>
          <NextStepModal
            onClose={handleCloseLogoutModal}
            title={`Log out`}
            subtitle={`You can return by logging back in`}
            buttonText={"Logout"}
            closeButtonText={"Cancel"}
            nextButtonVariant={"danger"}
            onClick={logoutAndRedirect}
          />
        </>
      </MuiModal>
    </>
  );
};

export default ProfileButton;
