import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import { BoxActionsWrapper, StyledPageWrapperBox } from "@/components/styled";
import { Box, Button, Typography } from "@mui/material";
import SearchInput from "@/components/SearchInput";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import NewsList from "@/pages/NewsPage/components/NewsList.tsx";
import AddNewArticle from "@/pages/NewsPage/components/AddNewArticle.tsx";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import { useNewsStore } from "@/store/newsStore.ts";

const NewsPage = () => {
  const getNews = useNewsStore((state) => state.getNews);
  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch(
    () => {},
  );
  const fetchDataWithLoading = useFetchDataWithLoading();

  useEffect(() => {
    fetchDataWithLoading(getNews, [searchValue]);
  }, [fetchDataWithLoading, searchValue]);
  return (
    <Layout>
      <Typography
        sx={{
          zIndex: "20",
          fontSize: "24px",
          fontWeight: 500,
          marginTop: "24px",
        }}
      >
        News
      </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "24px 0",
          gap: "12px",
        }}
      >
        <Box sx={{ maxWidth: "400px", width: "100%" }}>
          <SearchInput
            sx={{ "& .MuiInputBase-input": { backgroundColor: "#fff" } }}
            onDebounce={handleDebounceSearchValue}
          />
        </Box>
        <AddNewArticle />
      </Box>
      <NewsList />
    </Layout>
  );
};

export default NewsPage;
