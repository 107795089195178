import { FileRecord, ProjectRecord, UserType } from "@/types";
import axios from "@/libs/axios.ts";
import { DateRange } from "rsuite/DateRangePicker";
import dayjs from "dayjs";

export const updateProject = async (
  projectId: ProjectRecord["id"],
  body: FormData,
) => {
  return axios.patch(`/project/update/${projectId}`, body);
};

export const archiveProjectReq = async (projectId: ProjectRecord["id"]) =>
  axios.delete(`/project/archive/${projectId}`);

export const deleteProjectReq = async (projectId: ProjectRecord["id"]) =>
  axios.delete(`/project/delete/${projectId}`);

export const restoreProjectReq = async (projectId: ProjectRecord["id"]) =>
  axios.post(`/project/restore/${projectId}`);

export const createProjectReq = async (body: FormData) => {
  return axios.post(`/project/create`, body);
};
export const restoreProjectFileReq = async (fileId: FileRecord["id"]) => {
  return axios.post(`/project/document/${fileId}`);
};

export const getAllProjectsReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
  };
  return axios.get(`/project/list`, { params });
};

export const getProjectsByAccountNameReq = async (search = "") => {
  const params = {
    search,
  };
  return axios.get(`/project/list`, { params });
};

export const getProjectsFilesReq = async (
  projectId: ProjectRecord["id"],
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/project/document/${projectId}`, { params });
};

export const uploadProjectFileReq = async (
  projectId: ProjectRecord["id"],
  body: FormData,
) => axios.post(`/project/document/add/${projectId}`, body);

export const getProjectReq = async (projectId: ProjectRecord["id"]) => {
  return axios.get(`/project/${projectId}`);
};

export const deleteProjectFileReq = async (fileId: FileRecord["id"]) => {
  return axios.delete(`/project/document/${fileId}`);
};

export const updateProjectFileReq = async (
  fileId: FileRecord["id"],
  body: Partial<FileRecord>,
) => {
  return axios.patch(`/project/document/${fileId}`, body);
};

export const getProjectIdsReq = async (
  search = "",
  filter = [] as string[],
) => {
  const params = {
    ...(search && { search }),
    ...(filter?.length && { filter: filter.join(",") }),
    ids: true,
  };
  return axios.get(`/project/list`, { params });
};
export const getAllProjectsToExportReq = async (
  search = "",
  filter = [] as string[],
) => {
  const params = {
    ...(search && { search }),
    ...(filter?.length && { filter: filter.join(",") }),
    csv: true,
  };
  return axios.get(`/project/list`, { params });
};

export const getProjectsToExportReq = (projectIds: number[]) => {
  const params = {
    ids: projectIds?.join(","),
  };
  return axios.get(`/project/csv`, { params });
};
