import React, { ReactNode, useState } from "react";
import Card from "@/components/Card";
import {
  Box,
  Button,
  styled,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import { StyledTableCell, StyledTableRow } from "@/components/styled";
import SVGRadioCheckBox from "@/assets/icons/SVGRadioCheckBox.tsx";
import SvgSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import SvgSelectArrowUp from "@/assets/icons/SVGSelectArrowUp.tsx";

const BoxCircle = styled(Box)(() => ({
  display: "flex",
  borderRadius: "99px",
  border: "1px solid #0076CE",
  width: "24px",
  height: "24px",
}));

type Props = {
  icon: ReactNode;
  title: string;
  permissions: {
    label: string;
    admin: boolean;
    contractor: boolean;
    oem: boolean;
  }[];
};

const RoleCard = ({ icon, title, permissions }: Props) => {
  const [openSection, setOpenSection] = useState(true);
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Card sx={{ border: "1px solid #EDEDED", padding: "0" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 16px",
        }}
      >
        <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
          {icon}
          <Typography variant={"cardHeader3"}>{title}</Typography>
        </Box>
        <Button
          onClick={() => setOpenSection(!openSection)}
          sx={{
            minWidth: "initial",
            padding: "8px",
            minHeight: "initial",
          }}
          variant={"outlined"}
        >
          {openSection ? <SvgSelectArrowUp /> : <SVGSelectArrowDown />}
        </Button>
      </Box>
      {openSection && (
        <Box sx={{ width: "100%", overflowX: "auto" }}>
          {downLg && (
            <TableHead>
              <TableRow sx={{ display: "flex" }}>
                {["Permission", "Administrator", "Contractor", "OEM"].map(
                  (el) => (
                    <StyledTableCell
                      key={el}
                      sx={{
                        width: "100%",
                        minWidth: el === "Permission" ? "320px" : "280px",
                      }}
                    >
                      {el}
                    </StyledTableCell>
                  ),
                )}
              </TableRow>
            </TableHead>
          )}
          <TableBody sx={{ display: "flex", flexDirection: "column" }}>
            {permissions?.map(({ label, admin, contractor, oem }) => (
              <StyledTableRow key={label} sx={{ display: "flex" }}>
                <StyledTableCell sx={{ width: "100%", minWidth: "320px" }}>
                  {label}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "100%", minWidth: "280px" }}>
                  {admin ? <SVGRadioCheckBox /> : <BoxCircle />}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "100%", minWidth: "280px" }}>
                  {contractor ? <SVGRadioCheckBox /> : <BoxCircle />}
                </StyledTableCell>
                <StyledTableCell sx={{ width: "100%", minWidth: "280px" }}>
                  {oem ? <SVGRadioCheckBox /> : <BoxCircle />}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Box>
      )}
    </Card>
  );
};

export default RoleCard;
