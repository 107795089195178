import React, { useCallback } from "react";
import ProfileInfoCard from "@/components/ProfileInfoCard";
import ManufacturerInfo from "@/components/ManufacturerInfo";
import UploadedFilesTable from "@/components/UploadedFilesTable";
import { UserType } from "@/types";
import { useAdminStore } from "@/store/adminStore.ts";
import useTablePagination from "@/hooks/useTablePagination.ts";
import useDateRange from "@/hooks/useDateRange.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import TablePagination from "@/components/TablePagination";
import UploadTableFilesActions from "@/components/UploadedFilesTable/UploadTableFIlesActions.tsx";
import UpdateUserAdminWrapper from "@/pages/admin-pages/UserManagementPage/components/UpdateUserModal/UpdateUserAdminWrapper.tsx";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

const ProfileInfoWrapper = ({ user }: { user: UserType }) => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const profileCompletion = useAdminStore((state) => state.profileCompletion);
  const { userUploadedFiles, totalUserUploadedFiles } = useAdminStore(
    (state) => state.userUploadedFiles,
  );
  const getUserUploadedFiles = useAdminStore(
    (state) => state.getUserUploadedFiles,
  );

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(userUploadedFiles, totalUserUploadedFiles);
  const { handleDateRangeChange, dateRangeValue } = useDateRange();
  const { handleDebounceSearchValue, searchValue } =
    useDebouncedSearch(handleChangePage);
  const { handleSortChange, sort } = useTableSort();
  const { handleFilterChange, filters } = useTableFilter(handleChangePage);

  const getFilesReq = useCallback(() => {
    fetchDataWithLoading(getUserUploadedFiles, [
      user?.id,
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      sort,
      dateRangeValue,
    ]);
  }, [
    fetchDataWithLoading,
    getUserUploadedFiles,
    user?.id,
    page,
    rowsPerPage,
    searchValue,
    filters,
    sort,
    dateRangeValue,
  ]);

  return (
    <>
      <ProfileInfoCard
        user={user}
        profileCompletion={profileCompletion}
        updateProfileComponent={
          <UpdateUserAdminWrapper getFilesReq={getFilesReq} userId={user?.id} iconOnly/>
        }
      />
      {user?.role === "oem" && <ManufacturerInfo user={user} />}
      <UploadedFilesTable
        userUploadedFiles={userUploadedFiles}
        getFilesReq={getFilesReq}
        tableActions={
          <UploadTableFilesActions
            onSearch={handleDebounceSearchValue}
            onDateRange={handleDateRangeChange}
            onSort={handleSortChange}
            onFilter={handleFilterChange}
          />
        }
        pagination={
          <TablePagination
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            totalCount={totalUserUploadedFiles}
          />
        }
      />
    </>
  );
};

export default ProfileInfoWrapper;
