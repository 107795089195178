import { Box, styled, SxProps, Typography } from "@mui/material";
import { ReactNode } from "react";

const BoxCard = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: "16px",
  padding: "24px",
  display: "flex",
  flexDirection: "column",
  overflowY: "auto",
}));
const Card = ({
  sx,
  children,
  title,
}: {
  sx?: SxProps;
  children: ReactNode;
  title?: ReactNode;
}) => {
  return (
    <BoxCard sx={sx}>
      <Typography sx={{ fontWight: 500, fontSize: "18px" }}>{title}</Typography>
      {children}
    </BoxCard>
  );
};

export default Card;
