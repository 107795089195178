import { LogType } from "@/types";
import { formatDate } from "@/utils/formatDate.ts";

export const createLogData = ({
  id,
  userId,
  accountName,
  message,
  timestamp,
  ipAddress,
  location,
  status,
  role,
  note,
  email,
  logType,
}: Partial<LogType>): Partial<LogType> => {
  return {
    id,
    userId,
    accountName,
    message,
    ipAddress,
    location,
    status,
    role,
    note,
    email,
    logType,
    timestamp: formatDate(timestamp as string),
  };
};
