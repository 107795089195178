import React from "react";
import Card from "@/components/Card";
import { Box, styled, Typography } from "@mui/material";
import { useUserStore } from "@/store/userStore.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import { StyledBadge } from "@/components/styled";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";

const BoxBadge = styled(Box)(({ theme }) => ({
  padding: "12px 24px",
  borderRadius: "8px",
  backgroundColor: "#FFF5E6",
  color: "#B56C00",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "18px",
  fontWeight: 600,
  [theme.breakpoints.down("md")]: {},
}));

const StatusCard = () => {
  const user = useUserStore((state) => state.user);

  return (
    <Card>
      <Typography variant={"cardHeader2"}>Your Status</Typography>
      <Box sx={{ display: "inline-flex", marginTop: "24px" }}>
        <StyledBadge
          sx={{
            padding: "12px 24px",
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "18px",
            fontWeight: 600,
          }}
          {...getBadgeVariant(null, user?.status)}
        >
          {replaceUnderscoresWithSpaces(
            capitalizeFirstLetter(user?.status ?? ""),
          )}
        </StyledBadge>
      </Box>
    </Card>
  );
};

export default StatusCard;
