import React, { useEffect } from "react";
import AuthLayout from "@/components/AuthLayout";
import Card from "@/components/Card";
import { Button, Typography, useMediaQuery, useTheme } from "@mui/material";
import LoginForm from "@/pages/LoginPage/components/LoginForm.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import {
  ResetPasswordSteps,
  useResetPasswordStepsStore,
} from "@/pages/ResetPasswordPage/store/resetPasswordStepsStore.ts";
import { useNavigate } from "react-router-dom";

const AccountLockedPage = () => {
  const theme = useTheme();

  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const setCurrentStep = useResetPasswordStepsStore(
    (state) => state.setCurrentStep,
  );
  const navigate = useNavigate();

  const verificationEmail = useAuthStore((state) => state.verificationEmail);

  useEffect(() => {
    if (!verificationEmail) return navigate("/login");
  }, [verificationEmail]);

  const handleForgotPasswordClick = () => {
    setCurrentStep(ResetPasswordSteps.GetLink);
    navigate("/password-reset");
  };
  return (
    <AuthLayout>
      <Card
        sx={{ ...(!matchesDownLg && { maxWidth: "570px" }), width: "100%" }}
        title={<Typography variant={"cardHeader"}>Account Locked</Typography>}
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#000",
            fontWeight: 400,
            marginTop: "8px",
          }}
        >
          After 5 unsuccessful attempts, your account has been locked due. To
          regain access, you must reset your password. You will not be able to
          log in until this is done.
        </Typography>
        <Button
          sx={{ width: "100%", marginTop: "24px" }}
          onClick={handleForgotPasswordClick}
          variant={"contained"}
        >
          Reset Password
        </Button>
      </Card>
    </AuthLayout>
  );
};

export default AccountLockedPage;
