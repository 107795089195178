import React from "react";
import { BoxHeaderInner, StyledPageWrapperBox } from "@/components/styled";
import Layout from "@/components/Layout";
import Card from "@/components/Card";
import { Box, Typography } from "@mui/material";
import { SUPPORT_EMAIL } from "@/constants";

const privacyPolicyTextFirstPart =
  "Welcome to San Diego Community Power (SDCP). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you visit our website or use our services.\n" +
  "\n" +
  "1. Information We Collect\n" +
  "Personal Information:\n" +
  "• Name\n" +
  "• Email address\n" +
  "• Phone number\n" +
  "• Mailing address\n" +
  "• Account numbers\n" +
  "• IP address\n" +
  "• Usage data\n" +
  "Non-Personal Information:\n" +
  "• Browser type\n" +
  "• Operating system\n" +
  "• Website usage data\n" +
  "• Cookies and similar tracking technologies\n" +
  "\n" +
  "2. How We Use Your Information\n" +
  "We use the information we collect for various purposes, including:\n" +
  "• Providing and maintaining our services\n" +
  "• Notifying you about changes to our services\n" +
  "• Allowing you to participate in interactive features of our service\n" +
  "• Providing customer support\n" +
  "• Gathering analysis or valuable information to improve our services\n" +
  "• Monitoring the usage of our services\n" +
  "• Detecting, preventing, and addressing technical issues\n" +
  "• Ensuring compliance with legal obligations\n" +
  "\n" +
  "3. Sharing Your Information\n" +
  "We may share your personal information with:\n" +
  "• Service providers who assist us in operating our services\n" +
  "• Legal and regulatory authorities if required by law\n" +
  "• Third parties in connection with any merger, sale of company assets, financing, or acquisition of all or a portion of our business\n" +
  "• Other third parties with your consent\n" +
  "\n" +
  "4. Data Security\n" +
  "We employ a variety of security measures to ensure the protection of your personal information. These include:\n" +
  "• Encryption of sensitive data\n" +
  "• Regular security audits\n" +
  "• Access controls to restrict unauthorized access\n" +
  "\n" +
  "5. Your Data Protection Rights\n" +
  "You have the following data protection rights:\n" +
  "• Access: You can request copies of your personal data.\n" +
  "• Correction: You can request correction of inaccurate or incomplete data.\n" +
  "• Deletion: You can request deletion of your personal data under certain conditions.\n" +
  "• Restriction: You can request restriction of processing your personal data under certain conditions.\n" +
  "• Object: You can object to processing your personal data under certain conditions.\n" +
  "• Data Portability: You can request transfer of your personal data to another organization or directly to you under certain conditions.\n" +
  "\n" +
  "6. Cookies and Tracking Technologies\n" +
  "We use cookies and similar tracking technologies to track the activity on our services and hold certain information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our services.\n" +
  "\n" +
  "7. Changes to This Privacy Policy\n" +
  "We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.\n" +
  "\n" +
  "8. Contact Us\n" +
  "If you have any questions about this Privacy Policy, please contact us:\n" +
  "\n";

const privacyPolicyTextSecondPart =
  "\n" +
  "Thank you for trusting San Diego Community Power with your personal information. Your privacy and security are important to us.";

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <Typography
        sx={{ zIndex: "20", fontSize: "24px", fontWeight: 500, color: "#fff" }}
      >
        Privacy Policy
      </Typography>
      <StyledPageWrapperBox>
        <Card sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box
              sx={{
                whiteSpace: "pre-wrap",
                fontSize: "16px",
                fontWeight: 400,
                maxWidth: "650px",
                width: "100%",
              }}
            >
              <Box sx={{ marginBottom: "12px" }}>
                <Typography variant={"bodyMedium"}>
                  Effective Date: 05.06.2024
                </Typography>
              </Box>
              {privacyPolicyTextFirstPart}
              <Box sx={{ display: "flex" }}>
                Email: <a href={`mailto: ${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
              </Box>
              {privacyPolicyTextSecondPart}
            </Box>
          </Box>
        </Card>
      </StyledPageWrapperBox>
      <BoxHeaderInner sx={{ height: "100px" }} />
    </Layout>
  );
};

export default PrivacyPolicyPage;
