import React, { useEffect } from "react";
import { useAuthStore } from "@/store/authStore.ts";
import { useNavigate } from "react-router-dom";

const useRedirectByRoles = () => {
  const isAuth = useAuthStore((state) => state.isAuth);
  const userRole = useAuthStore((state) => state.role);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuth) {
      switch (userRole) {
        case "administrator": {
          navigate("/");
          break;
        }
        case "contractor": {
          navigate("/contractor-dashboard");
          break;
        }
        case "oem": {
          navigate("/projects");
          break;
        }
        default: {
          navigate("/");
          break;
        }
      }
    }
  }, [isAuth, userRole]);
};

export default useRedirectByRoles;
