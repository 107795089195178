import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";
import { getAllTransactionsReq } from "@/api/budgetWidgetApi";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import { LogsCSVHeaders, TransactionsCSVHeaders } from "@/constants";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { TransactionType } from "@/types";
import { formatCurrency } from "@/utils/formatCurrency.ts";

const ExportTransactions = () => {
  const budgetData = useBudgetStore((state) => state.budgetData);
  const [isExporting, setIsExporting] = useState(false);

  const handleExportTransactions = async () => {
    try {
      if (!isExporting) {
        setIsExporting(true);
        if (budgetData?.id) {
          const response = await getAllTransactionsReq();
          const transformedData = response?.data?.transactions?.map(
            (el: TransactionType) => ({
              id: el?.id,
              dateAndTime: formatDate(el?.createdAt),
              type: capitalizeFirstLetter(el?.type),
              from: el?.from ?? "",
              to: el?.to ?? "",
              amount: formatCurrency(el?.amount / 100),
              email: el?.email,
              note: el?.note ?? "",
            }),
          );
          downloadCSV(transformedData, "transactions", TransactionsCSVHeaders);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setIsExporting(false);
  };
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 0,
        width: "100%",
        left: 0,
        padding: "0 24px 16px 24px",
      }}
    >
      <Button
        onClick={handleExportTransactions}
        startIcon={<SVGUpload />}
        sx={{ width: "100%" }}
        variant={"contained"}
      >
        {isExporting ? "Exporting..." : "Export to CSV"}
      </Button>
    </Box>
  );
};

export default ExportTransactions;
