import React, { ReactNode, useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BoxAction,
  StyledBadge,
  StyledIconButton,
  StyledRightActionButton,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import SVGTrashCan from "@/assets/icons/SVGTrashCan.tsx";
import useTablePagination from "@/hooks/useTablePagination.ts";
import TablePagination from "@/components/TablePagination";
import dayjs from "dayjs";
import { getFileIcon } from "@/utils/getFileIcon.tsx";
import { getFileStatusBadgeVariant } from "@/utils/getFileStatusBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatDate } from "@/utils/formatDate.ts";
import Card from "@/components/Card";
import UploadTableFilesActions from "@/components/UploadedFilesTable/UploadTableFIlesActions.tsx";
import useDateRange from "@/hooks/useDateRange.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import SVGDownloadFile from "@/assets/icons/SVGDownloadFile.tsx";
import { formatBytes } from "@/components/FormUploadInput";
import { downloadFile } from "@/utils/downloadFile.ts";
import { FileRecord } from "@/types";
import CertificationCard from "@/components/CertificationCard";
import { deleteFileReq } from "@/api/fileApi";
import { getFileStorageBadgeVariant } from "@/utils/getFileStorageBadgeVariant.ts";
import { useAuthStore } from "@/store/authStore.ts";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import LogDetailModal from "@/pages/admin-pages/UserManagementPage/components/LogDetailModal.tsx";

function createData(
  id: number,
  fileName: string,
  fileSize: string,
  createdAt: string,
  status: string,
  url: string,
) {
  return {
    id,
    fileName,
    fileSize: formatBytes(Number(fileSize)),
    status,
    createdAt: dayjs(createdAt).format("MMMM D, YYYY"),
    url,
  };
}

const UploadedFilesTable = ({
  userUploadedFiles,
  getFilesReq,
  tableActions,
  pagination,
}: {
  userUploadedFiles: FileRecord[];
  getFilesReq: () => void;
  tableActions: ReactNode;
  pagination: ReactNode;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const user = useAdminStore((state) => state.user);
  const role = useAuthStore((state) => state.role);

  const userId = user?.id;

  const rows = useMemo(() => {
    return userUploadedFiles?.map(
      ({ id, fileName, size, createdAt, status, url }) =>
        createData(id, fileName, size, createdAt, status as any, url),
    );
  }, [userUploadedFiles]);
  useEffect(() => {
    if (userId) {
      getFilesReq();
    }
  }, [getFilesReq]);

  const deleteFile = async (fileId: FileRecord["id"]) => {
    try {
      await deleteFileReq(fileId);
      getFilesReq();
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <>
      <StyledTableCard>
        <Typography sx={{ paddingLeft: "24px" }} variant={"cardHeader2"}>
          Files Uploaded
        </Typography>
        {tableActions}
        <TableContainer>
          <StyledTable aria-label="customized table">
            <StyledTableHeader>
              <TableRow>
                <StyledTableCell>File name</StyledTableCell>
                <StyledTableCell>File size</StyledTableCell>
                <StyledTableCell>Upload date</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell></StyledTableCell>
              </TableRow>
            </StyledTableHeader>
            <TableBody>
              {rows.map((row) =>
                downSm ? (
                  <CollapsableRow
                    key={row.id}
                    title={row.fileName}
                    fileName={row.fileName}
                    firstSub={row.fileSize}
                    secondSub={
                      <StyledBadge
                        sx={{ display: "inline", textWrap: "nowrap" }}
                        {...getFileStorageBadgeVariant(row.status as any)}
                      >
                        {capitalizeFirstLetter(row.status)}
                      </StyledBadge>
                    }
                  >
                    <CellWithHeader header={"Upload date"}>
                      {formatDate(row.createdAt)}
                    </CellWithHeader>
                    <StyledRowActionsWrapperBox>
                      <StyledRightActionButton
                        sx={{ flex: 1 }}
                        variant={"contained"}
                        onClick={() => {
                          downloadFile(`file/${row.id}`, row.fileName);
                        }}
                      >
                        Download file
                      </StyledRightActionButton>
                      {row.status !== "deleted" && role === "administrator" && (
                        <StyledRightActionButton
                          sx={{ flex: 1 }}
                          variant={"dangerOutlined"}
                          onClick={() => {
                            deleteFile(row.id);
                          }}
                        >
                          Delete
                        </StyledRightActionButton>
                      )}
                    </StyledRowActionsWrapperBox>
                  </CollapsableRow>
                ) : (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        {getFileIcon(row.fileName)}
                        {row.fileName}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell>{row.fileSize}</StyledTableCell>
                    <StyledTableCell>
                      {formatDate(row.createdAt)}
                    </StyledTableCell>

                    <StyledTableCell>
                      <StyledBadge
                        sx={{ display: "inline", textWrap: "nowrap" }}
                        {...getFileStorageBadgeVariant(row.status as any)}
                      >
                        {capitalizeFirstLetter(row.status)}
                      </StyledBadge>
                    </StyledTableCell>
                    <StyledTableCell>
                      <StyledRowActionsWrapperBox>
                        <StyledIconButton
                          onClick={() => {
                            downloadFile(`file/${row.id}`, row.fileName);
                          }}
                        >
                          <SVGDownloadFile />
                        </StyledIconButton>
                        {row.status !== "deleted" &&
                          role === "administrator" && (
                            <StyledIconButton
                              onClick={() => {
                                deleteFile(row.id);
                              }}
                            >
                              <SVGTrashCan />
                            </StyledIconButton>
                          )}
                      </StyledRowActionsWrapperBox>
                    </StyledTableCell>
                  </StyledTableRow>
                ),
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        {pagination}
      </StyledTableCard>
    </>
  );
};

export default UploadedFilesTable;
