import { useState } from "react";
import { useForm } from "react-hook-form";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import {
  Box,
  Button,
  MenuItem,
  Modal as MuiModal,
  Typography,
} from "@mui/material";
import Modal from "@/components/Modal";
import FormCurrencyInput from "@/components/FormCurrencyInput";
import FormInput from "@/components/FormInput";
import ButtonsSection from "@/components/Modal/ButtonsSection.tsx";
import SvgTransfer from "@/assets/icons/SVGTransfer.tsx";
import FormSelect from "@/components/FormSelect";
import SuccessOperationModal from "@/components/SuccessOperationModal";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { transferBudget } from "@/api/budgetWidgetApi";
import { TransferActionType } from "@/types";
import { getTransactionLabel } from "@/utils/getTransactionLabel.ts";

const renderMenuItem = (value: string, header: string, amount: number) => {
  return (
    <MenuItem key={value} sx={{ borderRadius: "12px" }} value={value}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {header}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          Available {formatCurrency(amount / 100)}
        </Typography>
      </Box>
    </MenuItem>
  );
};

type FundsFormType = {
  funds: string;
  note: string;
  from: string;
  to: string;
};
const TransferFunds = () => {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const budgetData = useBudgetStore((state) => state.budgetData);
  const setBudgetData = useBudgetStore((state) => state.setBudgetData);
  const getTransactions = useBudgetStore((state) => state.getTransactions);

  const { handleSubmit, reset, control, watch } = useForm<FundsFormType>();
  const funds = watch("funds");
  const from = watch("from");
  const to = watch("to");
  const onSubmit = async (data: any) => {
    try {
      const amount = Math.round(Number(data?.funds) * 100);
      const response = await transferBudget({
        amount,
        note: data?.note,
        from: data?.from,
        to: data?.to,
      });
      getTransactions();
      setBudgetData(response?.data?.updatedBudget);
      setOpen(false);
      setOpenSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };
  const handleClose = () => {
    reset({ funds: "", note: "", from: "", to: "" });
    setOpen(false);
  };

  const isFundsEmpty = !(Number(funds) * 100 > 0);

  const items = [
    {
      header: "Remaining",
      amount: budgetData?.remainingBudget,
      value: TransferActionType.remaining,
    },
    {
      header: "Reserved",
      amount: budgetData?.reservedFunds,
      value: TransferActionType.reserved,
    },
    {
      header: "Paid",
      amount: budgetData?.paidOutAmounts,
      value: TransferActionType.paid,
    },
  ];

  const currentBalance = items?.find((el) => el?.value === from)?.amount;

  const formattedCurrentBalance = formatCurrency(Number(currentBalance) / 100); // Format the current balance
  const formattedNewBalance = formatCurrency(
    (Number(currentBalance) - Number(funds) * 100) / 100,
  ); // Format the new balance

  const fromItems = items?.filter((el) => el?.value !== to);
  const toItems = items?.filter((el) => el?.value !== from);

  const onCloseSuccessModal = () => {
    reset({ funds: "", note: "", from: "", to: "" });
    setOpenSuccess(false);
  };

  return (
    <>
      <Button
        startIcon={<SvgTransfer />}
        sx={{ width: "100%" }}
        variant={"outlined"}
        onClick={() => setOpen(true)}
      >
        Transfer
      </Button>
      <Modal open={open} onClose={handleClose} title={"Transfer funds"}>
        <>
          <FormSelect
            placeholder={"Select category"}
            name={"from"}
            control={control}
            label={"From"}
          >
            {fromItems?.map(({ value, header, amount }) =>
              renderMenuItem(value, header, amount),
            )}
          </FormSelect>
          <FormSelect
            placeholder={"Select category"}
            name={"to"}
            control={control}
            label={"To"}
          >
            {toItems?.map(({ value, header, amount }) =>
              renderMenuItem(value, header, amount),
            )}
          </FormSelect>
          <FormCurrencyInput
            name={"funds"}
            control={control}
            label={"Amount of funds"}
            placeholder={"$00.00"}
            disabled={!from || !to}
            helperText={
              !isFundsEmpty && (
                <>
                  <Typography
                    sx={{ display: "block", marginTop: "2px" }}
                    variant={"helperText"}
                  >
                    Available: {formattedCurrentBalance}
                  </Typography>
                  <Typography sx={{ display: "block" }} variant={"helperText"}>
                    Balance after operation: {formattedNewBalance}
                  </Typography>
                </>
              )
            }
          />
          <FormInput
            name={"note"}
            control={control}
            label={"Note"}
            placeholder={"Add note..."}
            multiline
            rows={4}
          />
          <ButtonsSection
            onCancel={handleClose}
            actionButton={
              <Button
                sx={{ width: "100%" }}
                onClick={handleSubmit(onSubmit)}
                variant={"contained"}
                disabled={
                  isFundsEmpty || Number(funds) * 100 > Number(currentBalance)
                }
              >
                Transfer funds
              </Button>
            }
          />
        </>
      </Modal>
      <MuiModal open={openSuccess} onClose={onCloseSuccessModal}>
        <>
          <SuccessOperationModal
            onClose={onCloseSuccessModal}
            title={`Funds successfully ${getTransactionLabel("transfer")}`}
            subtitle={`Balance of ${items?.find((el) => el?.value === from)?.header} funds is ${formattedCurrentBalance}`}
          />
        </>
      </MuiModal>
    </>
  );
};

export default TransferFunds;
