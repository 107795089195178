import React, { ReactNode } from "react";
import {
  Box,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchInput from "@/components/SearchInput";
import { BoxActionsWrapper, BoxSearchWrapper } from "@/components/styled";
import TableDateRange from "@/components/TableDateRange";
import { DateRange } from "rsuite/DateRangePicker";
import Card from "@/components/Card";

export const BoxActionsCard = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItem: "center",
  gap: "8px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 24px",
  },
}));

type Props = {
  onSearch: (value: string) => void;
  filter?: ReactNode;
  sort?: ReactNode;
  onDateRange?: (value: DateRange | null) => void;
  rightActions?: ReactNode;
  title: string;
};

const CommonTableActions = ({
  onSearch,
  filter,
  sort,
  onDateRange,
  rightActions,
  title,
}: Props) => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <>
      <BoxActionsCard>
        <Typography sx={{ width: "100%" }} variant={"cardHeader2"}>
          {title}
        </Typography>
        {downLg && rightActions}
      </BoxActionsCard>
      <BoxActionsWrapper>
        <BoxSearchWrapper>
          <Box sx={{ maxWidth: "300px", width: "100%" }}>
            <SearchInput onDebounce={onSearch} />
          </Box>
          {filter}
          {sort}
          {onDateRange && <TableDateRange setDateRangeValue={onDateRange} />}
        </BoxSearchWrapper>
        {!downLg && rightActions}
      </BoxActionsWrapper>
    </>
  );
};

export default CommonTableActions;
