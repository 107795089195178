import axios from "@/libs/axios.ts";

export const getAnalysisData = async (
  dataType: "monthly" | "weekly" | "yearly",
) => {
  const params = {
    analysisType: dataType,
  };
  return axios.get(`/budget/transactions/statistics`, { params });
};
