import { create } from "zustand";

export enum ResetPasswordSteps {
  GetLink,
  CreateNewPassword,
}

type State = {
  currentStep: ResetPasswordSteps;
};

type Actions = {
  setCurrentStep: (currentStep: ResetPasswordSteps) => void;
  setPrevNextStep: (step: 1 | -1) => void;
};

export const useResetPasswordStepsStore = create<State & Actions>((set) => ({
  currentStep: ResetPasswordSteps.GetLink,
  setCurrentStep: (currentStep) => {
    set(() => ({
      currentStep: currentStep,
    }));
  },
  setPrevNextStep: (step) => {
    set((state) => ({
      currentStep: state.currentStep + step,
    }));
  },
}));
