import React from "react";
import { Box, Button, styled, Typography } from "@mui/material";
import SVGProtectedCheckMark from "@/assets/icons/SVGProtectedCheckMark.tsx";
import { Link } from "react-router-dom";
import ManageCookiesModal from "@/components/PrivacyPolicyBar/ManageCookiesModal.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { StyledRightActionButton } from "@/components/styled";

const BoxWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  gap: "48px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: "24px",
  },
}));
const BoxButtonsWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "12px",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    width: "100%",
  },
}));

const BoxBar = styled(Box)(({ theme }) => ({
  position: "fixed",
  width: "100%",
  bottom: 0,
  padding: "48px",
  backgroundColor: "#E6F1FA",
  zIndex: 100,
  display: "flex",
  [theme.breakpoints.down("md")]: {
    padding: "24px",
  },
}));

const PrivacyPolicyBar = () => {
  const acceptCookies = useAuthStore((state) => state.acceptCookies);
  return (
    <BoxBar>
      <BoxWrapper>
        <Box sx={{ display: "flex", gap: "12px" }}>
          <SVGProtectedCheckMark style={{ minWidth: "36px" }} />
          <Typography
            sx={{ fontSize: "14px", fontWeight: 500, color: "textGray2" }}
          >
            We use cookies to enhance your browsing experience, analyze site
            traffic, and personalize content. By clicking 'Accept All Cookies,'
            you agree to our use of cookies. To manage your cookie preferences
            or learn more about how we use cookies, please click 'Manage
            Settings' or visit our{" "}
            <Link
              style={{ textDecoration: "underline", color: "#0076CE" }}
              to={"/privacy-policy"}
            >
              Privacy Policy
            </Link>
            .
          </Typography>
        </Box>
        <BoxButtonsWrapper>
          <ManageCookiesModal />
          <StyledRightActionButton
            onClick={() => {
              acceptCookies({ essentialCookies: true });
            }}
            variant={"outlined"}
            sx={{ whiteSpace: "nowrap", width: "100%" }}
          >
            Decline All Cookies
          </StyledRightActionButton>
          <StyledRightActionButton
            onClick={() => {
              acceptCookies({ essentialCookies: true });
            }}
            variant={"contained"}
            sx={{ whiteSpace: "nowrap", width: "100%" }}
          >
            Accept All Cookies
          </StyledRightActionButton>
        </BoxButtonsWrapper>
      </BoxWrapper>
    </BoxBar>
  );
};

export default PrivacyPolicyBar;
