const SvgCreatedAt = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0745 2.30025C13.2658 2.8365 15.6995 5.6055 15.6995 8.949C15.6995 12.6773 12.6778 15.699 8.94953 15.699C5.60603 15.699 2.83703 13.2652 2.30078 10.074"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.29999 7.82475C2.37124 7.401 2.48149 6.99225 2.62774 6.59925"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6473 3.7485C4.31955 4.01925 4.0188 4.32 3.74805 4.64775"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.82413 2.30025C7.40038 2.3715 6.99088 2.4825 6.59863 2.62875"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.29325 5.25V9.29325H6"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCreatedAt;
