import axios from "@/libs/axios.ts";

export const loginReq = async (body: { email: string; password: string }) =>
  axios.post(`/user/login`, body);

export const sendResetPasswordLinkReq = async (body: { email: string }) =>
  axios.post(`/user/forgot-password`, body);
export const resetPasswordReq = async (body: {
  token: string;
  password: string;
}) => axios.post(`/user/password-reset`, body);

export const verificationReq = async (body: {
  code: string;
  email: string;
  rememberMe: boolean;
}) => axios.post(`/user/login-verification`, body);

export const resetPasswordTokenCheckReq = (body: { token: string }) =>
  axios.post(`/user/token-check`, body);
