import React from "react";

const SvgLogout = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16667 16.16V6.25249C9.16667 5.67749 8.87083 5.14332 8.38333 4.83915L5.05 2.75582C3.94 2.06248 2.5 2.85998 2.5 4.16915V14.0758C2.5 14.6508 2.79583 15.185 3.28333 15.4892L6.61667 17.5725C7.72667 18.2667 9.16667 17.4683 9.16667 16.16Z"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 9.16667H17.5"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 10.8333L17.5 9.16667L15.8334 7.5"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16663 15.8333H12.5C13.4208 15.8333 14.1666 15.0875 14.1666 14.1666V13.3333"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1666 5V4.16667C14.1666 3.24583 13.4208 2.5 12.5 2.5H4.16663"
        stroke="#FF4D00"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLogout;
