import React, { useState, useEffect, ReactNode } from "react";
import * as Sentry from "@sentry/react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import { Box } from "@mui/material";
import { SUPPORT_EMAIL } from "@/constants";

interface ErrorBoundaryProps {
  children: ReactNode;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
  function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  },
);

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = (error: Error, errorInfo: React.ErrorInfo) => {
    Sentry.captureException(error, { extra: errorInfo as any });
    setHasError(true);
  };

  const handleClose = () => {
    setHasError(false);
  };

  useEffect(() => {
    const errorHandler = (event: ErrorEvent) => {
      handleError(event.error, { componentStack: "" });
    };

    window.addEventListener("error", errorHandler);

    return () => {
      window.removeEventListener("error", errorHandler);
    };
  }, []);

  return (
    <>
      {hasError && (
        <Snackbar open={hasError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            An error has occurred. Please contact support at{" "}
            <a
              style={{ color: "#fff", textDecoration: "underline" }}
              href={`mailto: ${SUPPORT_EMAIL}`}
            >
              {SUPPORT_EMAIL}
            </a>{" "}
            or try again later.
          </Alert>
        </Snackbar>
      )}
      {children}
    </>
  );
};

export default Sentry.withErrorBoundary(ErrorBoundary, {
  fallback: (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      An error has occurred. Please contact support at
      <a href={`mailto:${SUPPORT_EMAIL}`} style={{ margin: "0 5px" }}>
        {SUPPORT_EMAIL}
      </a>
      or try again later.
    </Box>
  ),
});
