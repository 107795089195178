import React, { useEffect, useState } from "react";
import AuthLayout from "@/components/AuthLayout";
import Card from "@/components/Card";
import {
  Box,
  Button,
  Checkbox,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import OtpInput from "@/components/OtpInput";
import { Controller, useForm } from "react-hook-form";
import { verificationReq } from "@/api/authApi";
import { useAuthStore } from "@/store/authStore.ts";
import { useNavigate } from "react-router-dom";
import useRedirectByRoles from "@/hooks/useRedirectByRoles.ts";

const BoxRememberMe = styled(Box)(({ theme }) => ({
  cursor: "pointer",
  display: "flex",
  gap: "8px",
  margin: "27px 0",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    margin: "0",
  },
}));

const VerificationCodePage = () => {
  const [rememberMe, setRememberMe] = useState(false);
  const { handleSubmit, control, setError, watch, setValue } = useForm<{
    code: string;
  }>();

  const theme = useTheme();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const setAuth = useAuthStore((state) => state.setAuth);

  const navigate = useNavigate();
  const verificationEmail = useAuthStore((state) => state.verificationEmail);

  const codeValue = watch("code");

  const handleCodeChange = (res: string) => {
    setValue("code", res);
  };

  const handleSubmitForm = async (values: { code: string }) => {
    try {
      const response = await verificationReq({
        code: values?.code,
        rememberMe,
        email: verificationEmail as string,
      });

      const isSuccess = response.data.success;

      const data = response?.data;

      if (isSuccess) {
        setAuth({
          token: data?.accessToken,
          role: data?.user?.role,
          trainingCompleted: data?.user?.trainingCompleted,
          essentialCookies: data?.essentialCookies,
        });
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.error;
      if (e?.response?.status === 403) {
        navigate("/account-locked");
      } else {
        setError("code", {
          message: errorMessage ? errorMessage : e?.message,
        });
      }
    }
  };

  useEffect(() => {
    if (!verificationEmail) return navigate("/login");
  }, [verificationEmail]);

  useRedirectByRoles();

  return (
    <AuthLayout>
      <Card
        sx={{ ...(!matchesDownLg && { maxWidth: "570px" }), width: "100%" }}
        title={
          <Typography variant={"cardHeader"}>
            Two-Factor Authentication
          </Typography>
        }
      >
        <Typography
          sx={{
            fontSize: "16px",
            color: "#000",
            fontWeight: 400,
            marginTop: "8px",
          }}
        >
          We've sent a 6-digit code to {verificationEmail}
        </Typography>
        <Box sx={{ marginTop: "32px" }}>
          <Controller
            name={"code"}
            control={control}
            render={({ fieldState: { error } }) => {
              return <OtpInput error={error} onChange={handleCodeChange} />;
            }}
          />
        </Box>

        <BoxRememberMe onClick={() => setRememberMe(!rememberMe)}>
          <Checkbox sx={{ color: "#0076CE" }} checked={rememberMe} />
          <Typography>Remember me on this device</Typography>
        </BoxRememberMe>
        <Button
          disabled={codeValue?.length !== 6}
          sx={{ width: "100%", marginTop: "24px" }}
          onClick={handleSubmit(handleSubmitForm)}
          variant={"contained"}
        >
          Log in
        </Button>
      </Card>
    </AuthLayout>
  );
};

export default VerificationCodePage;
