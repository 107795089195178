import React, { useState } from "react";
import DateRangePicker, { DateRange, RangeType } from "rsuite/DateRangePicker";
import "rsuite/dist/rsuite.min.css";
import { startOfMonth, subDays } from "rsuite/cjs/internals/utils/date";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SVGCalendar from "@/assets/icons/SVGCalendar.tsx";
import dayjs from "dayjs";
import SVGCross from "@/assets/icons/SVGCross.tsx";
import { StyledTableActionButton } from "@/components/styled";

const predefinedRanges = [
  {
    label: "Week",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "2 Weeks",
    value: [subDays(new Date(), 13), new Date()],
    placement: "left",
  },
  {
    label: "Month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "3 Months",
    value: [subDays(new Date(), 89), new Date()],
    placement: "left",
  },
  {
    label: "6 Months",
    value: [subDays(new Date(), 179), new Date()],
    placement: "left",
  },
  {
    label: "Year",
    value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
    placement: "left",
  },
];

const clearRange = [
  {
    label: "Clear",
    value: [1, 1],
  },
];

const TableDateRange = ({
  setDateRangeValue,
}: {
  setDateRangeValue: (value: DateRange | null) => void;
}) => {
  const [value, setValue] = useState<DateRange | null>();
  const [open, setOpen] = useState(false);

  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box sx={{ position: "relative", width: "100%" }}>
      <DateRangePicker
        open={open}
        preventOverflow
        showOneCalendar={downLg}
        ranges={!downLg ? (predefinedRanges as any) : clearRange}
        onClose={() => setOpen(false)}
        showHeader={false}
        onShortcutClick={(
          shortcut:
            | RangeType<DateRange>
            | {
                value: [1, 1];
              },
          event,
        ) => {
          event.stopPropagation();
          if (
            Array.isArray(shortcut?.value) &&
            shortcut.value[0] === 1 &&
            shortcut.value[1] === 1
          ) {
            setValue(null);
            setDateRangeValue(null);
          } else {
            setValue(shortcut?.value as DateRange);
            setDateRangeValue(shortcut?.value as DateRange);
          }
          setOpen(false);
        }}
        onOk={(value: DateRange, event) => {
          event.stopPropagation();
          setValue(value);
          setDateRangeValue(value);
          setOpen(false);
        }}
        value={value ?? null}
        style={{
          position: "absolute",
          opacity: 0,
          cursor: "pointer",
          width: 0,
        }}
      />
      <StyledTableActionButton
        onClick={() => setOpen(true)}
        variant={value ? "outlined" : "outlinedGray"}
      >
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            gap: "8px",
          }}
        >
          <SVGCalendar color={"#4E4B48"} />
          {!downMd && (
            <Typography
              variant={"subtitleRegular"}
              sx={{
                color: "textLightGray2",
                whiteSpace: "nowrap",
              }}
            >
              {value
                ? `${dayjs(value[0]).format("MMMM D, YYYY")} - ${dayjs(value[1]).format("MMMM D, YYYY")}`
                : "Date"}
            </Typography>
          )}
          {value && (
            <SVGCross
              color={"#4E4B48"}
              onClick={() => {
                setValue(null);
                setDateRangeValue(null);
              }}
            />
          )}
        </Box>
      </StyledTableActionButton>
    </Box>
  );
};

export default TableDateRange;
