import { create } from "zustand";
import { persist } from "zustand/middleware";
import { CookiesBody, UserType } from "@/types";
import { getBudgetList } from "@/api/budgetWidgetApi";
import { acceptCookiesReq } from "@/api/cookieApi";

type ResetPasswordDataType = {
  token: string | null;
  email: string | null;
};

type State = {
  token: string | null;
  verificationEmail: string | null;
  resetPasswordData: ResetPasswordDataType;
  isAuth: boolean;
};

type Actions = {
  setVerificationEmail: (email: string) => void;
  setAuth: ({
    token,
    role,
  }: {
    token: string;
    role: string;
    trainingCompleted?: boolean;
    essentialCookies?: boolean;
  }) => void;
  setToken: (token: string) => void;
  updateTrainingCompletion: (trainingCompleted: boolean) => void;
  logout: () => void;
  setResetPasswordData: (data: Partial<ResetPasswordDataType>) => void;
  reset: () => void;
  role: UserType["role"] | null;
  trainingCompleted: boolean;
  essentialCookies: boolean;
  acceptCookies: ({ essentialCookies }: CookiesBody) => void;
};

const initialState = {
  token: null,
  isAuth: false,
  resetPasswordData: {
    token: null,
    email: null,
  },
  role: null,
  trainingCompleted: false,
  essentialCookies: false,
};

export const useAuthStore = create(
  persist<State & Actions>(
    (set) => ({
      ...initialState,
      verificationEmail: null,
      setAuth: ({ token, role, trainingCompleted, essentialCookies }) =>
        set(() => ({
          token,
          role: role as UserType["role"],
          isAuth: !!token,
          trainingCompleted,
          essentialCookies,
        })),
      acceptCookies: async ({ essentialCookies }) => {
        try {
          await acceptCookiesReq({ essentialCookies });
          set(() => ({
            essentialCookies,
          }));
        } catch (e) {
          console.error(e);
        }
      },
      setToken: (token) =>
        set(() => ({
          token,
        })),
      updateTrainingCompletion: (trainingCompleted) =>
        set(() => ({
          trainingCompleted,
        })),
      setVerificationEmail: (verificationEmail) =>
        set(() => ({
          verificationEmail,
        })),
      logout: () => set(() => initialState),
      setResetPasswordData: (data) =>
        set((state) => ({
          resetPasswordData: { ...state.resetPasswordData, ...data },
        })),
      reset: () => {
        set(initialState);
      },
    }),
    {
      name: "auth",
    },
  ),
);
