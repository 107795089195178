import React, { useEffect, useRef, useState } from "react";
import { loadModules } from "esri-loader";
import Layout from "@/components/Layout";
import { BoxHeaderInner } from "@/components/styled";
import { getMapAuthReq } from "@/api/userApi";
import { useUserStore } from "@/store/userStore.ts";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "@/store/authStore.ts";

const GISMapPage = () => {
  const mapRef = useRef(null);
  const [token, setToken] = useState(null);
  const user = useUserStore((state) => state.user);
  const trainingCompleted = useAuthStore((state) => state.trainingCompleted);

  const navigate = useNavigate();

  useEffect(() => {
    // Function to fetch ArcGIS token from the backend
    if (trainingCompleted) {
      const fetchToken = async () => {
        try {
          const response = await getMapAuthReq();

          if (response.data) {
            setToken(response.data.message);
          } else {
            console.error(response.data.message);
          }
        } catch (error) {
          console.error("Failed to fetch ArcGIS token:", error);
        }
      };

      fetchToken();
    }
    if (user?.id && !trainingCompleted) {
      navigate("/contractor-dashboard");
    }
  }, [user?.id, trainingCompleted]);

  useEffect(() => {
    if (token) {
      const authenticate = async () => {
        loadModules(
          [
            "esri/views/MapView",
            "esri/WebMap",
            "esri/widgets/Search",
            "esri/identity/IdentityManager",
          ],
          { css: true },
        )
          .then(([MapView, WebMap, Search, IdentityManager]) => {
            IdentityManager.registerToken({
              token: token,
              server: "https://www.arcgis.com/sharing/rest",
              userId: user?.id,
            });

            const webMap = new WebMap({
              portalItem: {
                id: import.meta.env.VITE_GIS_MAP_ID,
              },
            });

            const view = new MapView({
              container: mapRef.current,
              map: webMap,
            });

            const searchWidget = new Search({
              view,
            });

            view.ui.add(searchWidget, {
              position: "top-right",
            });

            return () => {
              if (view) {
                view.destroy();
              }
            };
          })
          .catch((err) => console.error(err));
      };

      authenticate();
    }
  }, [token]);
  return (
    <Layout>
      <div
        style={{
          zIndex: 20,
          height: "calc(100vh - 120px)",
          width: "100%",
          borderTopLeftRadius: "24px",
          borderTopRightRadius: "24px",
          overflow: "hidden",
        }}
        ref={mapRef}
      ></div>
      <BoxHeaderInner />
    </Layout>
  );
};

export default GISMapPage;
