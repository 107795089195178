import React, { useEffect } from "react";
import Layout from "@/components/Layout";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import { useAdminStore } from "@/store/adminStore.ts";
import { useNavigate, useParams } from "react-router-dom";
import LoginSessionsTable from "@/components/LoginSessionsTable";
import LogTable from "@/components/LogTable";
import DeleteAccountCard from "@/pages/admin-pages/UserProfilePage/components/DeleteAccountCard.tsx";
import { deleteUserReq } from "@/api/adminApi";
import ProfileInfoWrapper from "@/pages/admin-pages/UserProfilePage/components/ProfileInfoWrapper.tsx";
import ProjectsNavigation from "@/pages/ProjectDetailsPage/components/ProjectsNavigation.tsx";

const UserProfilePage = () => {
  const theme = useTheme();
  const usersIds = useAdminStore((state) => state.usersIds);
  const getUsersIds = useAdminStore((state) => state.getUsersIds);
  const matchesDownXl = useMediaQuery(theme.breakpoints.down("xl"));
  const { id } = useParams();
  const getUser = useAdminStore((state) => state.getUser);
  const user = useAdminStore((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      getUser(Number(id));
    }
  }, [id]);

  const handleDeleteUser = async () => {
    try {
      await deleteUserReq(user?.id);
      navigate(-1);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Layout>
      <Grid
        sx={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        {matchesDownXl && (
          <Box sx={{ width: "100%", height: "48px", position: "relative" }}>
            <ProjectsNavigation
              navigateTo={(userId, searchParams) => {
                navigate(`/user-profile/${userId}?${searchParams.toString()}`);
              }}
              ids={usersIds}
              getIds={getUsersIds}
            />
          </Box>
        )}
        <ProfileInfoWrapper user={user} />
        <LoginSessionsTable />
        <LogTable />
        <DeleteAccountCard
          onDelete={handleDeleteUser}
          buttonText={"Delete Account"}
          confirmationText={"I confirm account deletion"}
          headerText={"Delete Account"}
        />
      </Grid>
    </Layout>
  );
};

export default UserProfilePage;
