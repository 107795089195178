import React, { useEffect, useMemo } from "react";
import {
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledBadge,
  StyledRightActionButton,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { formatDate } from "@/utils/formatDate.ts";
import TablePagination from "@/components/TablePagination";
import useTablePagination from "@/hooks/useTablePagination.ts";
import { LogType } from "@/types";
import { useAdminStore } from "@/store/adminStore.ts";
import LogDetailModal from "@/pages/admin-pages/UserManagementPage/components/LogDetailModal.tsx";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import UnauthorizedTableActions from "@/components/UnauthorizedTable/UnauthorizedTableActions.tsx";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import CommonTableActions from "@/components/CommonTableActions";
import { LogsCSVHeaders } from "@/constants";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

function createData({
  id,
  userId,
  accountName,
  message,
  timestamp,
  ipAddress,
  location,
  status,
  role,
  note,
  email,
  logType,
}: Partial<LogType>): Partial<LogType> {
  return {
    id,
    userId,
    accountName,
    message,
    ipAddress,
    location,
    status,
    role,
    note,
    email,
    logType,
    timestamp: formatDate(timestamp as string),
  };
}

const UnauthorizedTable = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { unauthorizedLogs, totalUnauthorizedCount } = useAdminStore(
    (state) => state.unauthorizedLogs,
  );
  const getUnauthorizedLogs = useAdminStore(
    (state) => state.getUnauthorizedLogs,
  );

  const rows = useMemo(() => {
    return unauthorizedLogs.map((el) => createData(el));
  }, [unauthorizedLogs]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalUnauthorizedCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);

  useEffect(() => {
    fetchDataWithLoading(getUnauthorizedLogs, [
      page + 1,
      rowsPerPage,
      searchValue,
    ]);
  }, [
    fetchDataWithLoading,
    getUnauthorizedLogs,
    page,
    rowsPerPage,
    searchValue,
  ]);
  return (
    <StyledTableCard>
      <CommonTableActions
        title={"Unauthorized access"}
        onSearch={handleDebounceSearchValue}
        rightActions={
          <StyledRightActionButton
            sx={{ gap: "6px" }}
            onClick={() =>
              downloadCSV(
                transformLogToCSV(unauthorizedLogs),
                "logs",
                LogsCSVHeaders,
              )
            }
            variant={"contained"}
          >
            <SVGUpload /> {!downSm && "Download CSV report"}
          </StyledRightActionButton>
        }
      />
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>IP Address</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Access type</StyledTableCell>
              <StyledTableCell>Result</StyledTableCell>
              <StyledTableCell>Time</StyledTableCell>
              <StyledTableCell>Event Log</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  key={row.id}
                  title={row.logType}
                  firstSub={row.id}
                  secondSub={
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  }
                >
                  <CellWithHeader header={"IP Address"}>
                    {row.ipAddress}
                  </CellWithHeader>
                  <CellWithHeader header={"Location"}>
                    {row.location}
                  </CellWithHeader>
                  <CellWithHeader header={"Time"}>
                    {formatDate(row.timestamp ?? "")}
                  </CellWithHeader>
                  <CellWithHeader header={"Event log"}>
                    {row.message}
                  </CellWithHeader>
                  <LogDetailModal
                    log={row}
                    getLogs={() =>
                      getUnauthorizedLogs(page + 1, rowsPerPage, searchValue)
                    }
                  />
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.ipAddress}</StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>{row.logType}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>{row.timestamp}</StyledTableCell>
                  <StyledTableCell>{row.message}</StyledTableCell>
                  <StyledTableCell>
                    <LogDetailModal
                      log={row}
                      getLogs={() =>
                        getUnauthorizedLogs(page + 1, rowsPerPage, searchValue)
                      }
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalUnauthorizedCount}
      />
    </StyledTableCard>
  );
};

export default UnauthorizedTable;
