import React, { ReactNode } from "react";
import Card from "@/components/Card";
import {
  Box,
  Grid,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { StyledBadge, StyledTruncatedTypography } from "@/components/styled";
import { useAdminStore } from "@/store/adminStore.ts";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import SVGEmail from "@/assets/icons/SVGEmail.tsx";
import SVGCalendar from "@/assets/icons/SVGCalendar.tsx";
import dayjs from "dayjs";
import SVGHash from "@/assets/icons/SVGHash.tsx";
import SVGLocation from "@/assets/icons/SVGLocation.tsx";
import SVGLink from "@/assets/icons/SVGLink.tsx";
import { getRoleLabel } from "@/utils/getRoleLabel.tsx";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";
import ProjectsNavigation from "@/pages/ProjectDetailsPage/components/ProjectsNavigation.tsx";
import { useNavigate } from "react-router-dom";
import { UserType } from "@/types";

export const ProfileCompletionBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  marginTop: "46px",
  [theme.breakpoints.down("lg")]: {
    marginTop: "16px",
  },
}));

export const AvatarBox = styled(Box)(({ theme }) => ({
  width: "200px",
  height: "200px",
  borderRadius: "99px",
  backgroundColor: "#246BFD40",
  [theme.breakpoints.down("md")]: {
    width: "72px",
    height: "72px",
  },
}));

export const ResponsiveStyledBadge = styled(StyledBadge)(({ theme }) => ({
  display: "inline",
  textWrap: "nowrap",
  [theme.breakpoints.down("md")]: {
    display: "block",
    width: "100%",
  },
}));
export const TypographyProfileInfo = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

type Props = {
    user: UserType;
    profileCompletion: string;
    updateProfileComponent: ReactNode;
};

const ProfileInfoCard = ({
                             user,
                             profileCompletion,
                             updateProfileComponent,
                         }: Props) => {
   const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downXl = useMediaQuery(theme.breakpoints.down("xl"));
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const showModalInFirstSection =
    user?.role !== "contractor" || (user?.role === "contractor" && downLg);

  const usersIds = useAdminStore((state) => state.usersIds);
  const getUsersIds = useAdminStore((state) => state.getUsersIds);
  const navigate = useNavigate();
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Card>
        <Grid
          container
          sx={{ justifyContent: "space-between", position: "relative" }}
        >
          <Grid item lg={8} xs={12}>
            <Grid container sx={{ gap: "16px" }}>
              {!downMd && <AvatarBox></AvatarBox>}
              <Grid item xs={12} lg={8} md={8}>
                <Grid
                  container
                  sx={{
                    alignItems: "center",
                  }}
                >
                  {downMd && (
                    <Grid sx={{ minWidth: "80px" }} item xs={2}>
                      <AvatarBox></AvatarBox>
                    </Grid>
                  )}
                  <Grid
                    sx={{ display: "flex", gap: "6px", alignItems: "center" }}
                    item
                    xs={7}
                    sm={9}
                    md={12}
                  >
                    <StyledTruncatedTypography variant={"bodyHeader2"}>
                      {user?.fullName}
                    </StyledTruncatedTypography>
                    {!downMd && (
                      <ResponsiveStyledBadge
                        {...getBadgeVariant(user?.role, null)}
                      >
                        {getRoleLabel(user?.role)}
                      </ResponsiveStyledBadge>
                    )}
                  </Grid>
                  {downMd && (
                    <Grid item xs={12} md={2}>
                      <ResponsiveStyledBadge
                        sx={{ ...(downMd && { margin: "16px 0 8px 0" }) }}
                        {...getBadgeVariant(user?.role, null)}
                      >
                        {getRoleLabel(user?.role)}
                      </ResponsiveStyledBadge>
                    </Grid>
                  )}
                </Grid>
                <Box
                  sx={{
                    display: "flex",
                    gap: "12px",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{ display: "flex", gap: "12px", alignItems: "center" }}
                  >
                    <SVGEmail />
                    <TypographyProfileInfo variant={"bodyMedium"}>
                      {user?.email}
                    </TypographyProfileInfo>
                  </Box>
                  <Box
                    sx={{ display: "flex", gap: "12px", alignItems: "center" }}
                  >
                    <SVGCalendar />
                    <TypographyProfileInfo variant={"bodyMedium"}>
                      {dayjs(user?.createdAt).format("MMMM D, YYYY")}
                    </TypographyProfileInfo>
                  </Box>
                  <ResponsiveStyledBadge
                    {...getBadgeVariant(null, user?.status)}
                  >
                    {replaceUnderscoresWithSpaces(
                      capitalizeFirstLetter(user?.status),
                    )}
                  </ResponsiveStyledBadge>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    maxWidth: "480px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                  }}
                >
                  <ProfileCompletionBox>
                    <Typography
                      variant={"bodyMedium"}
                      sx={{ color: "#716F6D" }}
                    >
                      Profile Completion
                    </Typography>
                    <Typography
                      variant={"bodyMedium"}
                      sx={{ color: "#21201E" }}
                    >
                      {profileCompletion}%
                    </Typography>
                  </ProfileCompletionBox>
                  <Box
                    sx={{
                      width: "100%",
                      backgroundColor: "#EDEDED",
                      height: "8px",
                      borderRadius: "4px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        width: `${profileCompletion}%`,
                        backgroundColor: "#0076CE",
                        height: "8px",
                        borderRadius: "4px",
                        position: "absolute",
                        left: "0",
                      }}
                    ></Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {showModalInFirstSection && (
            <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                {updateProfileComponent}
            </Box>
          )}
          {user?.role === "contractor" && (
            <Grid
              item
              xs={12}
              lg={4}
              sx={{ width: "100%", ...(downLg && { marginTop: "40px" }) }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "4px",
                }}
              >
                <Typography variant={"cardHeader2"}>
                  Company information
                </Typography>
                {!showModalInFirstSection && (
                    updateProfileComponent
                )}
              </Box>
              <StyledTruncatedTypography variant={"bodyHeader2"}>
                {user?.companyName ?? ""}
              </StyledTruncatedTypography>
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "8px" }}
              >
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <SVGHash />
                  <StyledTruncatedTypography variant={"bodyRegular"}>
                    {user?.businessLicenseNumber}
                  </StyledTruncatedTypography>
                </Box>
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <SVGLocation />
                  <StyledTruncatedTypography variant={"bodyRegular"}>
                    {user?.companyAddress}
                  </StyledTruncatedTypography>
                </Box>
                <Box sx={{ display: "flex", gap: "4px" }}>
                  <SVGLink />
                  <a
                    href={`${user?.companyWebsite}`}
                    style={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "#4E4B48",
                      textDecoration: "underline",
                      whiteSpace: "nowrap",
                      display: "block",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {user?.companyWebsite}
                  </a>
                </Box>
              </Box>
            </Grid>
          )}
        </Grid>
      </Card>
      {!downXl && (
        <ProjectsNavigation
          navigateTo={(userId, searchParams) => {
            navigate(`/user-profile/${userId}?${searchParams.toString()}`);
          }}
          ids={usersIds}
          getIds={getUsersIds}
        />
      )}
    </Box>
  );
};

export default ProfileInfoCard;
