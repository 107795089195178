import { checkUserEmail } from "@/api/adminApi";

export const validateEmail = async (email: string) => {
  try {
    await checkUserEmail(email);
    return true;
  } catch (error: any) {
    return error?.response?.data?.message;
  }
};
