import React, { useEffect, useState } from "react";
import Card from "@/components/Card";
import { Box, Grid, styled, Typography, useTheme } from "@mui/material";
import { ChartData } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { InformationItem } from "@/components/BudgetWidget/BudgetInformation.tsx";
import { getRoleStatisticReq } from "@/api/adminApi";

const BoxWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: "16px",
  paddingTop: "40px",
}));

const AccountRolesChart = () => {
  const [statistic, setStatistic] = useState({
    admins: 0,
    oems: 0,
    contractors: 0,
    percentages: {
      admins: 0,
      contractors: 0,
      oems: 0,
    },
    totalUsers: 0,
  });
  const { palette } = useTheme();

  const { admins, oems, contractors, totalUsers } = statistic;
  const {
    admins: administratorPercentage,
    oems: OEMPercentage,
    contractors: contractorsPercentage,
  } = statistic.percentages;

  const data: ChartData<"doughnut", number[], string> = {
    labels: ["Contractors", "OEM", "Administrators"],
    datasets: [
      {
        data: [contractors, oems, admins],
        backgroundColor: [palette.green, palette.blue, palette.red],
        borderColor: [palette.green, palette.blue, palette.red],
        borderWidth: 1,
        spacing: 6,
        borderRadius: 4,
      },
    ],
  };

  useEffect(() => {
    const fetchStatistics = async () => {
      try {
        const response = await getRoleStatisticReq();
        setStatistic(response.data?.userStatistics);
      } catch (e) {
        console.error(e);
      }
    };
    fetchStatistics();
  }, []);
  return (
    <Card>
      <Typography variant={"cardHeader2"}>Account Roles</Typography>
      <Grid container columns={12} sx={{ marginTop: "24px" }}>
        <Grid item xs={12}>
          <Doughnut
            style={{
              width: "240px",
              maxHeight: "240px",
              minHeight: "240px",
            }}
            data={data}
            options={{
              maintainAspectRatio: false,
              responsive: true,
              layout: {},
              plugins: {
                legend: {
                  display: false,
                },
              },
              cutout: 70,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <BoxWrapper>
            <InformationItem
              amount={`${contractors}`}
              percentage={`Contractors (${contractorsPercentage}%)`}
              color={"green"}
            />
            <InformationItem
              amount={`${oems}`}
              percentage={`OEM (${OEMPercentage}%)`}
              color={"blue"}
            />
            <InformationItem
              amount={`${admins}`}
              percentage={`Administrator (${administratorPercentage}%)`}
              color={"red"}
            />
            <Box
              height={"2px"}
              width={"100%"}
              sx={{ backgroundColor: "#EDEDED", marginTop: "8px" }}
            />
            <Box sx={{ marginTop: "8px" }}>
              <Box
                gap={"8px"}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant={"bodyRegular"}>Total</Typography>
                <Typography variant={"h3"}>{totalUsers}</Typography>
              </Box>
            </Box>
          </BoxWrapper>
        </Grid>
      </Grid>
    </Card>
  );
};

export default AccountRolesChart;
