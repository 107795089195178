import React, { Suspense } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import { GlobalStyles, useMediaQuery, useTheme } from "@mui/material";

type Props = {
  onChange: (value: string) => void;
  initialData: string;
};

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      ".ck.ck-editor__editable_inline>:first-of-type ": {
        marginTop: 0,
      },
      ".ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable)": {
        boxShadow: "none",
      },
      ".ck .ck-powered-by": {
        display: "none",
      },
      ".ck.ck-balloon-panel": {
        zIndex: "1400 !important", // Put a higher value that your MUI Dialog (generaly 1300)
      },
      ".ck.ck-editor__editable_inline": {
        minHeight: "100px",
        padding: "16px 12px",
        border: "1px solid #EDEDED",
        borderRadius: "12px !important",
        backgroundColor: "#fefefe",
        marginTop: "12px",
      },

      ".ck .ck-toolbar__items": {
        gap: "10px",
      },

      ".ck-toolbar": {
        border: "1px solid #D0D2D8",
        minHeight: "48px",
        borderBottom: "none",
        borderRadius: "12px !important",
        backgroundColor: "#f5f5f5",
      },
      ".ck-placeholder": {
        color: "#888684",
        fontSize: "14px",
        fontWeight: 400,
        margin: 0,
      },
    }}
  />
);

const TextEditor = ({ onChange, initialData }: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {inputGlobalStyles}
      <CKEditor
        editor={DecoupledEditor as any}
        data={initialData}
        config={{
          toolbar: [
            "bold",
            "italic",
            "underline",
            "|",
            "bulletedList",
            "numberedList",
            "link",
          ],
          placeholder: "Write your content here...",
          removePlugins: ["ListProperties"],
        }}
        onChange={(_, editor) => {
          const data = editor.getData();
          onChange(data);
        }}
        onReady={(editor: any) => {
          if (!downSm) {
            editor.ui
              ?.getEditableElement()
              ?.parentElement?.insertBefore(
                editor.ui.view.toolbar.element!,
                editor.ui.getEditableElement()!,
              );
          }
        }}
      />
    </Suspense>
  );
};

export default TextEditor;
