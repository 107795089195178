import React, { useEffect } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import AuthLayout from "@/components/AuthLayout";
import Card from "@/components/Card";
import LoginForm from "@/pages/LoginPage/components/LoginForm.tsx";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "@/store/authStore.ts";
import useRedirectByRoles from "@/hooks/useRedirectByRoles.ts";

const LoginPage = () => {
  const theme = useTheme();

  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  useRedirectByRoles();

  return (
    <AuthLayout>
      <Card
        sx={{ ...(!matchesDownLg && { maxWidth: "570px" }), width: "100%" }}
        title={<Typography variant={"cardHeader"}>Log In</Typography>}
      >
        <LoginForm />
      </Card>
    </AuthLayout>
  );
};

export default LoginPage;
