import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import UploadProjectFile from "@/components/UploadProjectFile";
import EditProjectModal from "@/pages/ProjectDetailsPage/components/EditProjectModal.tsx";
import { ProjectRecord } from "@/types";
import {
  StyledIconButton,
  StyledRightActionButton,
  StyledRowActionsWrapperBox,
} from "@/components/styled";
import SVGArchive from "@/assets/icons/SVGArchive.tsx";
import SVGRefresh from "@/assets/icons/SVGRefresh.tsx";
import { uploadProjectFileReq } from "@/api/projectsApi";
import SvgUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import { useAuthStore } from "@/store/authStore.ts";

type Props = {
  row: ProjectRecord;
  getProjectsReq: () => void;
  setDeleteProjectId: (id: number) => void;
  setRestoreProjectId: (id: number) => void;
};

const ProjectRowActions = ({
  row,
  getProjectsReq,
  setDeleteProjectId,
  setRestoreProjectId,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const role = useAuthStore((state) => state.role);

  const uploadProjectFile = async (
    projectId: ProjectRecord["id"],
    data: FormData,
  ) => {
    try {
      await uploadProjectFileReq(projectId, data);
    } catch (e) {
      console.error(e);
    }
  };

  const isAdmin = role === "administrator";
  return (
    <>
      {row.enrolmentStatus !== "archived" && (
        <Box
          sx={{
            display: "flex",
            gap: "6px",
            ...(downSm && { flexDirection: "column" }),
          }}
        >
          <UploadProjectFile
            uploadFile={(data) => uploadProjectFile(row.id, data)}
          />
          <EditProjectModal
            project={row as ProjectRecord}
            getUpdatedProjects={getProjectsReq}
          />
          {isAdmin && (
            <StyledIconButton
              sx={{
                gap: "6px",
                whiteSpace: "nowrap",
                ...(!downSm && { border: 0 }),
              }}
              onClick={() => setDeleteProjectId(row.id)}
              variant={downSm ? "dangerOutlined" : "text"}
            >
              {!downSm && <SVGArchive />}
              {downSm && "Archive"}
            </StyledIconButton>
          )}
        </Box>
      )}
      {row.enrolmentStatus === "archived" && isAdmin && (
        <StyledRowActionsWrapperBox>
          <StyledIconButton
            onClick={() => {
              setRestoreProjectId(row.id);
            }}
            sx={{ ...(downSm && { flex: 1 }) }}
            variant={downSm ? "outlined" : "text"}
          >
            {!downSm && <SVGRefresh />}
            {downSm && "Restore"}
          </StyledIconButton>
        </StyledRowActionsWrapperBox>
      )}
    </>
  );
};

export default ProjectRowActions;
