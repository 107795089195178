import { useState } from "react";
import { Box, Button, MenuItem, Typography } from "@mui/material";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import { StyledMenu } from "@/components/styled";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import SvgSimpleCheckMark from "@/assets/icons/SVGSimpleCheckMark.tsx";
import SvgArrowDownSmall from "@/assets/icons/SVGArrowDownSmall.tsx";

type Props = {
  selectedValue: string;
  placeholder: string;
  items: { value: string; label: string }[];
  setSelectedValue: (value: any) => void;
};

const DataTypeSelect = ({
  placeholder,
  items,
  setSelectedValue,
  selectedValue,
}: Props) => {
  const [selectedItem, setSelectedItem] = useState<string>(selectedValue);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (value: string) => {
    const newSelectedItem = selectedItem === value ? "" : value;
    setSelectedItem(newSelectedItem);
    setSelectedValue(newSelectedItem);
    handleClose();
  };

  const isSelected = (value: string) => selectedItem === value;

  return (
    <>
      <Button
        onClick={handleClick}
        variant={"text"}
        sx={{
          color: "#8A8A8E",
          padding: "0 12px",
          minHeight: "initial",
        }}
        endIcon={<SvgArrowDownSmall />}
      >
        {placeholder}
      </Button>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        sx={{ "& .MuiPaper-root": { minWidth: "310px" } }}
      >
        {items.map((el) => {
          return (
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
              key={el.value}
              onClick={() => handleClickItem(el.value)}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#4E4B48" }}
                >
                  {capitalizeFirstLetter(el.label ?? "")}
                </Typography>
              </Box>
              {isSelected(el.value) && <SvgSimpleCheckMark />}
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default DataTypeSelect;
