export const getLogStatusBadgeLabel = (status: "401" | "200" | string) => {
  if (status === "401") {
    return "Denied";
  }

  if (status === "200" || status === "304") {
    return "Success";
  }

  return "Failed";
};
