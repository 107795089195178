export const getLogStatusBadgeVariant = (status: "401" | "200" | string) => {
  if (status === "401") {
    return { yellow: true };
  }

  if (status === "200" || status === "304") {
    return { success: true };
  }

  return { danger: true };
};
