import React, { useEffect, useState } from "react";
import FormAutocompleteTags from "@/components/FormAutocompleteTags";
import FormRadioList from "@/components/FormRadioList";
import { Box, Button, Collapse, Typography } from "@mui/material";
import {
  Control,
  Controller,
  useFieldArray,
  useFormContext,
} from "react-hook-form";
import FormInput from "@/components/FormInput";
import { StyledIconButton } from "@/components/styled";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import SVGSelectArrowUp from "@/assets/icons/SVGSelectArrowUp.tsx";
import FormAsyncSelect from "@/components/FormAsyncSelect";
import { getBatteryModelsReq, getBatteryTypesReq } from "@/api/batteryApi";

const BatteryModelsForm = ({ control }: { control: Control }) => {
  const { watch } = useFormContext();
  const [expandedIndices, setExpandedIndices] = useState<number[]>([]);

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "batteries",
  });

  const toggleExpand = (index: number) => {
    setExpandedIndices((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };
  const batteryModelsWatch = watch("batteryModels");
  const batteriesWatch = watch("batteries");
  const manufactoringBrand = watch("manufactoringBrand");

  useEffect(() => {
    const currentModels = batteriesWatch?.map(
      (battery: any) => battery.batteryModel,
    );

    // Remove batteries that are not in the new batteryModels
    currentModels?.forEach((model: string, index: number) => {
      if (!batteryModelsWatch?.includes(model)) {
        remove(index);
      }
    });

    // Add new batteries that are not in the current batteries
    batteryModelsWatch?.forEach((model: string) => {
      if (!currentModels.includes(model)) {
        append({
          batteryModel: model,
          batteryTypes: [],
          batteryLocations: "",
          batteryCapacity: "",
        });
        setExpandedIndices((prev) => [...prev, currentModels.length]);
      }
    });
  }, [batteryModelsWatch, batteriesWatch, append, remove, fields.length]);

  return (
    <Box sx={{}}>
      <FormAsyncSelect
        multiple
        itemsKey={"batteryModels"}
        getItems={(searchValue) =>
          getBatteryModelsReq(manufactoringBrand, searchValue)
        }
        name={"batteryModels"}
        control={control}
        label={"Battery Models"}
        placeholder={"Model name"}
      />
      {fields?.map((item: any, index) => (
        <Box
          sx={{ padding: "8px 0", marginBottom: "16px", marginTop: "-8px" }}
          key={item.id}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
              {item?.batteryModel}
            </Typography>
            <StyledIconButton onClick={() => toggleExpand(index)}>
              {expandedIndices.includes(index) ? (
                <SVGSelectArrowUp color={"#4E4B48"} />
              ) : (
                <SVGSelectArrowDown />
              )}
            </StyledIconButton>
          </Box>
          <Collapse
            sx={{ paddingTop: "16px" }}
            in={expandedIndices.includes(index)}
          >
            <FormAsyncSelect
              multiple
              name={`batteries[${index}].batteryTypes`}
              control={control}
              label={"Battery Type"}
              placeholder={"Type name"}
              itemsKey={"batteryTypes"}
              getItems={(searchValue) => getBatteryTypesReq(searchValue)}
            />
            <FormRadioList
              name={`batteries[${index}].batteryLocations`}
              control={control}
              label={"Battery Locations"}
              items={[
                { value: "inside", label: "Inside" },
                { value: "outside", label: "Outside" },
                { value: "either", label: "Either" },
                { value: "other", label: "Other" },
              ]}
            />
            <FormInput
              name={`batteries[${index}].inverterModels`}
              control={control}
              label={"Inverter Models"}
              placeholder={"Inverter Models name"}
            />
            <FormInput
              name={`batteries[${index}].batteryCapacity`}
              control={control}
              label={"Nameplate Usable Capacity (kWh)"}
              placeholder={"Battery capacity, kWh"}
            />
          </Collapse>
        </Box>
      ))}
    </Box>
  );
};

export default BatteryModelsForm;
