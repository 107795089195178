import React from "react";

const SvgEligibility = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0003 20.9418C12.6684 20.983 12.3343 21.0038 11.9998 21.0038C7.02721 21.0038 2.99609 16.9727 2.99609 12.0001C2.99609 7.02745 7.02721 2.99634 11.9998 2.99634C16.9725 2.99634 21.0036 7.02745 21.0036 12.0001C21.0036 12.3345 20.9827 12.6686 20.9416 13.0005"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0036 17.0022L17.5026 19.5032L16.002 18.0026"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.6617 12.15L7.75578 14.0599C7.59111 14.2259 7.49851 14.4502 7.49805 14.684V15.6192C7.49805 15.8533 7.59106 16.0779 7.75662 16.2434C7.92218 16.409 8.14674 16.502 8.38088 16.502H9.32002C9.5544 16.5023 9.77916 16.4088 9.94413 16.2423L11.8461 14.3364C12.1944 14.4584 12.5603 14.5227 12.9294 14.527C14.4621 14.5522 15.8344 13.5812 16.3205 12.1274C16.8066 10.6736 16.2943 9.07244 15.0547 8.17071C13.8151 7.26897 12.134 7.27468 10.9005 8.18482C9.66705 9.09496 9.16573 10.6996 9.6617 12.15V12.15Z"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9299 11.03C12.9075 11.03 12.8894 11.0482 12.8894 11.0705C12.8894 11.0929 12.9075 11.111 12.9299 11.111C12.9523 11.111 12.9704 11.0929 12.9704 11.0705C12.9704 11.0482 12.9523 11.03 12.9299 11.03V11.03"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgEligibility;
