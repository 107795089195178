import React from "react";

const SVGPrivacyPolicy = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83337 7.49998H7.50004"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83337 10.8334H9.16671"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83337 14.1667H13.3334"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1708 10C11.87 10 10.0042 8.13502 10.0042 5.83336C10.0042 3.58002 11.9208 1.66419 14.1742 1.66669C16.4742 1.66836 18.3375 3.53336 18.3375 5.83336C18.3375 8.13419 16.4717 10 14.1708 10"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1667 6.04169V7.70835"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.17 3.98168C14.1058 3.98168 14.0542 4.03335 14.055 4.09751C14.055 4.16168 14.1067 4.21335 14.1708 4.21335C14.235 4.21335 14.2867 4.16168 14.2867 4.09751C14.2858 4.03335 14.2342 3.98168 14.17 3.98168"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8334 9.65419V15.8334C15.8334 16.7542 15.0875 17.5 14.1667 17.5H5.00004C4.07921 17.5 3.33337 16.7542 3.33337 15.8334V5.83335C3.33337 4.91252 4.07921 4.16669 5.00004 4.16669H10.3675"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVGPrivacyPolicy;
