import React from "react";
import { Box, Typography } from "@mui/material";
import { getFileIcon } from "@/utils/getFileIcon.tsx";
import { formatBytes } from "@/components/FormUploadInput";

const FileComponent = ({
  fileInfo,
}: {
  fileInfo: { name: string; size: number };
}) => {
  return (
    <Box
      sx={{
        display: "flex",
        gap: "8px",
        overflow: "hidden",
        alignItems: "center",
      }}
    >
      <Box sx={{ display: "flex" }}>{getFileIcon(fileInfo.name)}</Box>
      <Box sx={{ overflow: "hidden", width: "100%" }}>
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: "14px",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          {fileInfo.name}
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: "#888684",
          }}
        >
          {formatBytes(fileInfo.size)}
        </Typography>
      </Box>
    </Box>
  );
};

export default FileComponent;
