import React from "react";
import { ProjectRecord } from "@/types";
import { useForm } from "react-hook-form";
import Modal from "@/components/Modal";
import FormInput from "@/components/FormInput";
import { Box, Button } from "@mui/material";
import { updateProject } from "@/api/projectsApi";

const RejectedProjectModal = ({
  projectId,
  getProjectsReq,
  onClose,
}: {
  projectId: ProjectRecord["id"];
  getProjectsReq: () => void;
  onClose: () => void;
}) => {
  const { handleSubmit, reset, control, watch } = useForm<{
    note: string;
  }>({
    mode: "onChange",
  });

  const note = watch("note");

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async () => {
    try {
      const formData = new FormData();
      formData.append("enrolmentStatus", "rejected");
      formData.append("note", note);
      await updateProject(projectId, formData);
      await getProjectsReq();
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Modal
      open={!!projectId}
      onClose={handleClose}
      title={`Reject project ${projectId?.toString()?.padStart(3, "0")}`}
    >
      <form
        style={{
          display: "flex",
          flexDirection: "column",
          overflow: "auto",
          marginTop: "24px",
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormInput
          name={"note"}
          control={control}
          label={"Write a reason for rejection"}
          placeholder={"Reason"}
          multiline
          rows={4}
          controlProps={{ rules: { required: "Reject reason is required" } }}
        />
        <Box
          sx={{
            marginTop: "32px",
            display: "flex",
            gap: "8px",
            width: "100%",
          }}
        >
          <Button
            variant={"danger"}
            sx={{ width: "100%" }}
            disabled={!note}
            type={"submit"}
          >
            Reject
          </Button>
        </Box>
      </form>
    </Modal>
  );
};

export default RejectedProjectModal;
