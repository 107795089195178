import React from "react";
import FormInput from "@/components/FormInput";
import { Control } from "react-hook-form";

const SecondContractorForm = ({ control }: { control: Control }) => {
  return (
    <>
      <FormInput
        name={"companyName"}
        control={control}
        label={"Company name"}
        placeholder={"Company INC"}
        controlProps={{
          rules: { required: "Company name is required" },
        }}
      />
      <FormInput
        name={"businessLicenseNumber"}
        control={control}
        label={"Business License Number"}
        placeholder={"BL-0000000"}
        controlProps={{
          rules: { required: "Business License Number is required" },
        }}
      />
      <FormInput
        name={"companyAddress"}
        control={control}
        label={"Company Address"}
        placeholder={"1234 Main St, Springfield, IL 62704"}
        // rules={{ required: "Company Address is required" }}
      />
      <FormInput
        name={"companyWebsite"}
        control={control}
        label={"Company Website"}
        placeholder={"https://..."}
        // rules={{ required: "Company Website is required" }}
      />
    </>
  );
};

export default SecondContractorForm;
