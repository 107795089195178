import { Doughnut } from "react-chartjs-2";
import {
  ArcElement,
  Chart as ChartJS,
  ChartData,
  Legend,
  Tooltip,
} from "chart.js";
import { useTheme } from "@mui/material";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { BudgetType } from "@/types";

ChartJS.register(ArcElement, Tooltip, Legend);

const BudgetChart = ({ widgetData }: { widgetData: BudgetType }) => {
  const { palette } = useTheme();
  const remaining = widgetData?.remainingBudget;
  const reserved = widgetData?.reservedFunds;
  const paid = widgetData?.paidOutAmounts;
  // const totalBudget = widgetData?.total_budget;

  const total = remaining + reserved + paid;

  const data: ChartData<"doughnut", number[], string> = {
    labels: ["Remaining", "Reserved", "Paid"],
    datasets: [
      {
        data: [remaining, reserved, paid],
        backgroundColor: [
          palette.chartFirstColor,
          palette.chartSecondColor,
          palette.chartThirdColor,
        ],
        borderColor: [
          palette.chartFirstColor,
          palette.chartSecondColor,
          palette.chartThirdColor,
        ],
        borderWidth: 1,
        spacing:
          total === remaining || total === reserved || total === paid ? 0 : 6,
        borderRadius:
          total === remaining || total === reserved || total === paid ? 0 : 4,
      },
    ],
  };

  return (
    <Doughnut
      style={{
        width: "240px",
        maxHeight: "240px",
        minHeight: "240px",
      }}
      data={data}
      options={{
        maintainAspectRatio: false,
        responsive: true,
        layout: {},
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                return `${formatCurrency((tooltipItem.raw as number) / 100)}`;
              },
            },
          },
        },
        cutout: 70,
      }}
    />
  );
};

export default BudgetChart;
