import { formatBytes } from "@/components/FormUploadInput";

export function createFileData(
  id: number,
  fileName: string,
  fileSize: string,
  createdAt: string,
  status: string,
  fileType: string,
  accountName: string,
  accountEmail: string,
  url: string,
) {
  return {
    id,
    fileName,
    fileSize: formatBytes(Number(fileSize)),
    status,
    createdAt: createdAt,
    fileType,
    accountName,
    accountEmail,
    url,
  };
}
