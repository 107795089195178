import {
  Autocomplete,
  AutocompleteGetTagProps,
  Box,
  debounce,
  FormControl,
  FormLabel,
  InputProps,
  styled,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { ReactNode, useEffect, useMemo, useState } from "react";
import SVGTagClose from "@/assets/icons/SVGTagClose.tsx";

export type FormInputProps = InputProps & {
  name: string;
  control: Control<any, any>;
  label?: ReactNode;
  controlProps?: Partial<ControllerProps>;
  values?: string[];
  multiple?: boolean;
  onDebounce?: (value: string) => void;
};

export const StyledTextField = styled(TextField)<{
  active?: boolean;
}>(({ theme }) => ({
  "& .MuiInput-underline:after": {
    opacity: "0",
  },
  "&.Mui-focused fieldset": {
    borderColor: theme.palette.primary.main,
  },
  "& .MuiInputBase-root": {
    position: "relative",
    border: "1px solid",
    borderColor: theme.palette.textLightGray1,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.text.primary,
    borderRadius: "12px",
    padding: `9px 16px`,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      border: "1px solid",
      borderColor: theme.palette.primary.main,
      borderRadius: "12px",
    },
    "&::before": {
      display: "none",
    },
  },
  "& .MuiInputLabel-root": {
    marginTop: theme.spacing(3),
  },
}));

interface TagProps extends ReturnType<AutocompleteGetTagProps> {
  label: string;
}

export const AutoCompleteTag = (props: TagProps) => {
  const { label, onDelete, ...other } = props;
  return (
    <Box
      {...other}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "4px",
        padding: "4px 8px",
        border: (theme) => `1px solid ${theme.palette.textLightGray2}`,
        borderRadius: "8px",
      }}
    >
      <Typography sx={{ fontWeight: 600, fontSize: "12px" }}>
        {label}
      </Typography>
      <SVGTagClose style={{ cursor: "pointer" }} onClick={onDelete} />
    </Box>
  );
};

const FormAutocompleteTags = ({
  name,
  control,
  label,
  controlProps,
  values = [] as string[],
  multiple = false,
  onDebounce,
  ...props
}: FormInputProps) => {
  const { palette } = useTheme();
  const isRequired = !!controlProps?.rules?.required;
  const [internalValues, setInternalValues] = useState([] as string[]);
  useEffect(() => {
    setInternalValues([
      ...new Set([
        ...internalValues?.filter((el) => !!el),
        ...values?.filter((el) => !!el),
      ]),
    ]);
  }, [values]);

  const handleDebouncedValue = useMemo(
    () =>
      debounce(
        (value: string, onDebounce: (value: string) => void) =>
          onDebounce(value),
        500,
      ),
    [],
  );
  return (
    <FormControl
      component="fieldset"
      sx={{
        width: "calc(100% - 1px)",
      }}
    >
      <FormLabel component="legend" sx={{ width: "100%" }}>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: (theme) => theme.palette.textGray1,
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}{" "}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const handleKeyDown = (event: any) => {
            if (event.key === "Enter") {
              event.preventDefault();
              const inputValue = event.target?.value as string;
              if (inputValue.trim() && !internalValues.includes(inputValue)) {
                const newValue = [...(value || []), inputValue];
                setInternalValues((prevValues) => [...prevValues, inputValue]);
                onChange(multiple ? newValue : inputValue);
                props?.onChange?.(newValue as any);
              }
            }
          };
          return (
            <Box sx={{ position: "relative", width: "100%" }}>
              <Autocomplete
                freeSolo
                multiple={multiple}
                limitTags={5}
                id="multiple-limit-tags"
                options={internalValues ?? []}
                value={value ? value : multiple ? [] : ""}
                popupIcon={null}
                onChange={(_, value) => {
                  onChange(value);
                  props?.onChange?.(value);
                }}
                renderTags={(value, getTagProps) => {
                  return value?.map((el, index) => {
                    const { key, ...tagProps } = getTagProps({ index });
                    return (
                      <AutoCompleteTag label={el} key={key} {...tagProps} />
                    );
                  });
                }}
                onInputChange={(_, newInputValue) => {
                  if (onDebounce) {
                    handleDebouncedValue(newInputValue, onDebounce);
                  }
                  if (!multiple) {
                    onChange(newInputValue);
                  }
                }}
                renderInput={(params) => {
                  return (
                    <StyledTextField
                      {...params}
                      variant={"standard"}
                      placeholder={props?.placeholder}
                      onKeyDown={handleKeyDown}
                      sx={{ ...(multiple && { paddingBottom: "16px" }) }}
                    />
                  );
                }}
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: palette.errorText,
                  paddingTop: "4px",
                  opacity: !!error?.message ? 1 : 0,
                }}
              >
                {error?.message || "helper text"}
              </Typography>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormAutocompleteTags;
