import React from "react";
import { Box } from "@mui/material";
import { RowsPerPageOptions } from "@/constants";
import TablePaginationActions from "@/components/TablePaginationActions";
import { TablePagination as MuiTablePagination } from "@mui/material";
import { UserType } from "@/types";

type Props = {
  page: number;
  rowsPerPage: number;
  handleChangeRowsPerPage: (e?: any) => void;
  handleChangePage: (e: any, page: number) => void;
  totalCount: number;
};

const TablePagination = ({
  page,
  rowsPerPage,
  handleChangeRowsPerPage,
  handleChangePage,
  totalCount,
}: Props) => {
  return (
    <MuiTablePagination
      component={Box}
      sx={{ border: 0, display: "flex", width: "100%" }}
      rowsPerPageOptions={RowsPerPageOptions}
      count={totalCount}
      rowsPerPage={rowsPerPage}
      page={page}
      labelRowsPerPage=""
      labelDisplayedRows={() => ""}
      slotProps={{
        select: {
          sx: { display: "none" },
          inputProps: {
            sx: { display: "none" },
          },
        },
      }}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      ActionsComponent={(props) => (
        <TablePaginationActions
          rowsPerPageOptions={RowsPerPageOptions}
          onRowsPerPageChange={handleChangeRowsPerPage}
          {...props}
        />
      )}
    />
  );
};

export default TablePagination;
