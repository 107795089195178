import { Box, Button, styled, Typography } from "@mui/material";
import { StyledModalBox } from "@/components/styled";
import SvgSuccessModal from "@/assets/icons/SVGSuccessModal.tsx";

type Props = {
  title: string;
  subtitle: string;
  onClose: () => void;
  buttonText?: string;
};

const TitleTypography = styled(Typography)(({ theme }) => ({
  marginTop: "32px",
  textAlign: "center",
  fontSize: "28px",
  fontWeight: 600,
  [theme.breakpoints.down("lg")]: {
    fontSize: "24px",
    fontWeight: 500,
  },
}));

const SuccessOperationModal = ({
  title,
  subtitle,
  onClose,
  buttonText = "Done",
}: Props) => {
  return (
    <StyledModalBox>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <SvgSuccessModal />
        <TitleTypography sx={{ marginTop: "32px" }}>{title}</TitleTypography>
        <Typography
          sx={{
            fontSize: "14px",
            fontWeight: 400,
            marginTop: "12px",
            textAlign: "center",
          }}
        >
          {subtitle}
        </Typography>
        <Button
          variant={"contained"}
          sx={{ width: "100%", marginTop: "32px" }}
          onClick={onClose}
        >
          {buttonText}
        </Button>
      </Box>
    </StyledModalBox>
  );
};

export default SuccessOperationModal;
