import React from "react";
import { Box } from "@mui/material";

const SvgDangerModal = ({
  variant = "red",
}: {
  variant?: "red" | "yellow";
}) => {
  const colors =
    variant === "red"
      ? { shadowColor: "#FF4D0033", color: "#FF4D00" }
      : variant === "yellow"
        ? { shadowColor: "#F5A62333", color: "#E8B528" }
        : { shadowColor: "#FF4D0033", color: "#FF4D00" };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "99px",
        boxShadow: `0px 24px 34px 10px ${colors.shadowColor}`,
        overflow: "hidden",
      }}
    >
      <svg
        width="92"
        height="92"
        viewBox="24 0 92 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_144_40556)">
          <rect
            x="24"
            width="92"
            height="92"
            rx="46"
            fill={`${colors.color}`}
            shapeRendering="crispEdges"
          />
          <path
            d="M69.9997 24.3334C58.0613 24.3334 48.333 34.0617 48.333 46C48.333 57.9384 58.0613 67.6667 69.9997 67.6667C81.938 67.6667 91.6663 57.9384 91.6663 46C91.6663 34.0617 81.938 24.3334 69.9997 24.3334ZM68.3747 37.3334C68.3747 36.445 69.1113 35.7084 69.9997 35.7084C70.888 35.7084 71.6247 36.445 71.6247 37.3334V48.1667C71.6247 49.055 70.888 49.7917 69.9997 49.7917C69.1113 49.7917 68.3747 49.055 68.3747 48.1667V37.3334ZM71.993 55.49C71.8847 55.7717 71.733 55.9884 71.538 56.205C71.3213 56.4 71.083 56.5517 70.823 56.66C70.563 56.7684 70.2813 56.8334 69.9997 56.8334C69.718 56.8334 69.4363 56.7684 69.1763 56.66C68.9163 56.5517 68.678 56.4 68.4613 56.205C68.2663 55.9884 68.1147 55.7717 68.0063 55.49C67.898 55.23 67.833 54.9484 67.833 54.6667C67.833 54.385 67.898 54.1034 68.0063 53.8434C68.1147 53.5834 68.2663 53.345 68.4613 53.1284C68.678 52.9334 68.9163 52.7817 69.1763 52.6734C69.6963 52.4567 70.303 52.4567 70.823 52.6734C71.083 52.7817 71.3213 52.9334 71.538 53.1284C71.733 53.345 71.8847 53.5834 71.993 53.8434C72.1013 54.1034 72.1663 54.385 72.1663 54.6667C72.1663 54.9484 72.1013 55.23 71.993 55.49Z"
            fill="white"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_144_40556"
            x="0"
            y="0"
            width="140"
            height="140"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="10"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_144_40556"
            />
            <feOffset dy="24" />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 1 0 0 0 0 0.301961 0 0 0 0 0 0 0 0 0.2 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_144_40556"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_144_40556"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};

export default SvgDangerModal;
