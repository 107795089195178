import React, { ReactChildren, ReactNode, useState } from "react";
import Card from "@/components/Card";
import { Box, Button, styled, TableRow, Typography } from "@mui/material";
import FormCheckbox from "@/components/FormCheckbox";
import { UserType } from "@/types";
import { deleteUserReq } from "@/api/adminApi";
import { useNavigate } from "react-router-dom";

export const BoxWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: "8px",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "start",
  },
}));
export const StyledButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

type Props = {
  onDelete: () => void;
  confirmationText: string;
  buttonText: string;
  headerText: string;
  children?: ReactNode;
};

const DeleteAccountCard = ({
  onDelete,
  buttonText,
  headerText,
  confirmationText,
  children,
}: Props) => {
  const [confirmDelete, setConfirmDelete] = useState(false);
  const handleChange = (value: boolean) => setConfirmDelete(value);

  return (
    <Card>
      <Typography variant={"cardHeader2"}>{headerText}</Typography>
        {children}
        <BoxWrapper>
        <FormCheckbox
          value={confirmDelete}
          onChange={handleChange}
          label={confirmationText}
        />
        <StyledButton
          onClick={onDelete}
          variant={"danger"}
          disabled={!confirmDelete}
        >
          {buttonText}
        </StyledButton>
      </BoxWrapper>
    </Card>
  );
};

export default DeleteAccountCard;
