import Card from "@/components/Card";
import { Box, Button, styled, Typography, useMediaQuery } from "@mui/material";
import { StyledDivider } from "@/components/styled";
import BalanceActivity from "@/components/TotalBalance/BalanceActivity.tsx";
import AddFunds from "@/components/TotalBalance/AddFunds.tsx";
import DeductFunds from "@/components/TotalBalance/DeductFunds.tsx";
import TransferFunds from "@/components/TotalBalance/TransferFunds.tsx";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { useEffect, useState } from "react";
import ExportTransactions from "@/components/TotalBalance/ExportTransactions.tsx";

const BoxActions = styled(Box)(() => ({
  display: "flex",
  gap: "16px",
  marginTop: "16px",
  flexDirection: "column",
}));

const TotalBalance = () => {
  const budgetData = useBudgetStore((state) => state.budgetData);
  const [yScroll, setYScroll] = useState(0);
  const matchesUp1800px = useMediaQuery("(min-width:1800px)");

  useEffect(() => {
    // calculate dynamic Y scroll height for table
    setYScroll(window.innerHeight - 160);
  }, []);

  return (
    <Card
      title={"Total balance"}
      sx={{ maxHeight: `${yScroll}px`, position: "relative", paddingBottom: 0 }}
    >
      <Typography
        variant={"h3"}
        sx={{
          overflowX: "hidden",
          textOverflow: "ellipsis",
          minHeight: "35px",
        }}
      >
        {/*$1,808.17*/}
        {formatCurrency(budgetData?.totalBudget / 100 || 0)}
      </Typography>
      <BoxActions sx={{ ...(matchesUp1800px && { flexDirection: "row" }) }}>
        <AddFunds />
        <DeductFunds />
        <TransferFunds />
      </BoxActions>
      <StyledDivider sx={{ marginY: "24px" }} />
      <BalanceActivity />
      <ExportTransactions />
    </Card>
  );
};

export default TotalBalance;
