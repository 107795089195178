import React from "react";

const SvgRadioCheckBox = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#0076CE" />
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#0076CE" />
      <path
        d="M16.8996 8.5L10.3896 15.5L7.09961 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgRadioCheckBox;
