import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";

type Props = {
  value: boolean;
  onChange: (value: boolean) => void;
  label: string;
};

const FormCheckbox = ({ value, onChange, label }: Props) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        display: "flex",
        gap: "4px",
        alignItems: "center",
      }}
      onClick={() => onChange(!value)}
    >
      <Checkbox
        sx={{ color: (theme) => theme.palette.chartFirstColor }}
        checked={!!value}
        onChange={(e) => onChange(e.target.checked)}
      />
      <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
        {label}
      </Typography>
    </Box>
  );
};

export default FormCheckbox;
