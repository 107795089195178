import { FileRecord, UserType } from "@/types";
import axios from "@/libs/axios.ts";
import { DateRange } from "rsuite/DateRangePicker";
import dayjs from "dayjs";

export const deleteFileReq = async (fileId: FileRecord["id"]) =>
  axios.delete(`/file/${fileId}`);
export const restoreFileReq = async (fileId: FileRecord["id"]) =>
  axios.post(`/file/${fileId}/restore`);
export const uploadFileReq = async (userId: UserType["id"], body: FormData) =>
  axios.post(`/file/personal/${userId}`, body);

export const uploadSingleFileReq = async (body: FormData) =>
  axios.post(`/file/personal`, body);
export const changeFileStatusReq = async (
  fileId: FileRecord["id"],
  status: string,
) => axios.patch(`/file/status/${fileId}`, { status });

export const getAllUploadedFilesReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  sort: string,
  filter = [] as string[],
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    sort,
    filter: filter.join(","),
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/project/document/list`, { params });
};

export const getAllProjectsFilesToDownloadReq = async (
  search = "",
  filter = [] as string[],
) => {
  const params = {
    ...(search && { search }),
    ...(filter?.length && { filter: filter.join(",") }),
  };
  return axios.get(`/project/document/list`, { params });
};

export const getProjectsFilesToDownloadReq = (projectIds: number[]) => {
  const params = {
    ids: projectIds?.join(","),
  };
  return axios.get(`/project/document/download`, { params });
};

export const getUserUploadedFilesReq = async (
  userId: UserType["id"],
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/file/user/files/${userId}`, { params });
};
export const getAllUsersUploadedFilesReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/file/list`, { params });
};
