import React from "react";

const SvgLink = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0218 12.019L19.7525 10.2883C20.8589 9.21488 21.3012 7.62858 20.9097 6.13754C20.5183 4.6465 19.3538 3.48204 17.8628 3.09058C16.3717 2.69911 14.7854 3.14136 13.712 4.24779L10.0104 7.94933C9.20905 8.75016 8.75879 9.83665 8.75879 10.9696C8.75879 12.1025 9.20905 13.189 10.0104 13.9898L9.99944 14.0009C10.3442 14.3391 10.7445 14.6155 11.1829 14.8182"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8064 9.19177C13.2448 9.39479 13.6451 9.67159 13.9898 10.0101C14.7912 10.8109 15.2415 11.8974 15.2415 13.0304C15.2415 14.1633 14.7912 15.2498 13.9898 16.0506L10.2883 19.7522C8.61311 21.3774 5.94357 21.3572 4.29317 19.7068C2.64276 18.0564 2.62256 15.3869 4.24779 13.7117L5.97851 11.9809"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLink;
