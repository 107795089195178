import React from "react";

const SvgLocation = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00078 12C5.04744 12.0002 4.22671 12.6732 4.03978 13.608L3.03983 18.6078C2.92232 19.1953 3.07443 19.8046 3.45431 20.268C3.83419 20.7314 4.40181 21 5.00099 21H18.9996C19.5988 21 20.1664 20.7314 20.5463 20.268C20.9262 19.8046 21.0783 19.1953 20.9608 18.6078L19.9608 13.608C19.7739 12.6732 18.9532 12.0002 17.9998 12"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 17H15.5"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 7.5V7.5C7.5 5.01472 9.51472 3 12 3V3C14.4853 3 16.5 5.01472 16.5 7.5V7.5C16.5 9.48666 14.2599 11.6071 12.9396 12.6839C12.3834 13.1055 11.6146 13.1053 11.0586 12.6834C9.73949 11.6065 7.5 9.48637 7.5 7.5Z"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.999 7.25001C11.8611 7.25053 11.7496 7.36269 11.75 7.50064C11.7503 7.63858 11.8624 7.75018 12.0003 7.75C12.1383 7.74983 12.25 7.63795 12.25 7.50001C12.2504 7.43343 12.224 7.36949 12.1768 7.3225C12.1297 7.27551 12.0656 7.2494 11.999 7.25001"
        stroke="#716F6D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLocation;
