import React, { useState } from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { Box, FormControl, styled, Typography } from "@mui/material";
import SVGUploadFile from "@/assets/icons/SVGUploadFile.tsx";
import FileComponent from "@/components/FileComponent";
import { FILE_SIZE_10MB } from "@/constants";

const BoxDropzone = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  gap: "16px",
  width: "100%",
  border: "1px solid",
  padding: "16px 4px",
  borderColor: theme.palette.textLightGray1,
  borderRadius: "12px",
  cursor: "pointer",
  "&:hover": { backgroundColor: theme.palette.textLightGray1 },
}));

type Props = {
  name: string;
  fileInfo: { name: string; size: number };
  control: Control<any, any>;
  controlProps?: Partial<ControllerProps>;
  label?: string;
  author?: string;
  maxFileSize?: number;
};

const FormFileDropzone = ({
  name,
  control,
  controlProps,
  fileInfo,
  maxFileSize = FILE_SIZE_10MB,
}: Props) => {
  const [isDragging, setIsDragging] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFileChange = (
    file: File | null,
    onChange: (file: File | null) => void,
  ) => {
    if (file) {
      if (file.size > maxFileSize) {
        setError("The file is too big");
        onChange(null);
      } else {
        onChange(file);
        setError(null);
      }
    } else {
      onChange(null);
      setError(null);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (
    event: React.DragEvent<HTMLDivElement>,
    onChange: (file: File | null) => void,
  ) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files?.[0];
    handleFileChange(file, onChange);
  };

  const handleClick = (inputRef: React.RefObject<HTMLInputElement>) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: "24px" }}
    >
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value } }) => {
          const inputRef = React.createRef<HTMLInputElement>();

          return (
            <>
              <BoxDropzone
                sx={{
                  backgroundColor: isDragging
                    ? "textLightGray1"
                    : "transparent",
                }}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={(e) => handleDrop(e, onChange)}
                onClick={() => handleClick(inputRef)}
              >
                {!value && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <SVGUploadFile color={"#0076CE"} />
                    <Typography
                      sx={{ marginTop: "12px" }}
                      component={"span"}
                      variant={"bodyRegular"}
                    >
                      <Typography
                        component={"span"}
                        sx={{ color: "chartFirstColor" }}
                      >
                        Upload a file
                      </Typography>{" "}
                      or drag and drop
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: 400,
                        color: "textGray3",
                        marginTop: "4px",
                      }}
                    >
                      PDF, PNG, JPG, CSV, XLSX up to 10MB
                    </Typography>
                  </Box>
                )}
                {fileInfo && <FileComponent fileInfo={fileInfo} />}
                <input
                  type="file"
                  accept=".pdf,.csv,.xlsx,.xlsb,.docx,.png,.jpg,.doc"
                  hidden
                  ref={inputRef}
                  onChange={(e) =>
                    handleFileChange(e.target.files?.[0] || null, onChange)
                  }
                />
              </BoxDropzone>
              {error && (
                <Typography
                  sx={{ color: "red", fontSize: "12px", marginTop: "8px" }}
                >
                  {error}
                </Typography>
              )}
            </>
          );
        }}
      />
    </FormControl>
  );
};

export default FormFileDropzone;
