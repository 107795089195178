import { Button, Modal as MuiModal, Typography } from "@mui/material";
import SvgCardAdd from "@/assets/icons/SVGCardAdd.tsx";
import { useState } from "react";
import Modal from "@/components/Modal";
import { useForm } from "react-hook-form";
import FormInput from "@/components/FormInput";
import FormCurrencyInput from "@/components/FormCurrencyInput";
import ButtonsSection from "@/components/Modal/ButtonsSection.tsx";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import SuccessOperationModal from "@/components/SuccessOperationModal";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { addTotalBudget } from "@/api/budgetWidgetApi";
import totalBalance from "@/components/TotalBalance/index.tsx";
import { getTransactionLabel } from "@/utils/getTransactionLabel.ts";

type FundsFormType = {
  funds: string;
  note: string;
};

const AddFunds = () => {
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const budgetData = useBudgetStore((state) => state.budgetData);
  const setBudgetData = useBudgetStore((state) => state.setBudgetData);
  const getTransactions = useBudgetStore((state) => state.getTransactions);

  const { handleSubmit, reset, control, watch } = useForm<FundsFormType>();
  const funds = watch("funds");
  const onSubmit = async (data: FundsFormType) => {
    try {
      const amount = Math.round(Number(data?.funds) * 100);
      const response = await addTotalBudget({
        amount,
        note: data?.note,
      });
      setBudgetData(response?.data?.updatedBudget);
      getTransactions();
      handleClose();
      setOpenSuccess(true);
    } catch (e) {
      console.error(e);
    }
  };
  const handleClose = () => {
    reset({ funds: "", note: "" });
    setOpen(false);
  };

  const isFundsEmpty = !(Number(funds) * 100 > 0);

  const currentBalance = budgetData?.totalBudget;

  const formattedCurrentBalance = formatCurrency(currentBalance / 100); // Format the current balance
  const formattedNewBalance = formatCurrency(
    (currentBalance + Number(funds) * 100) / 100,
  ); // Format the new balance

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        startIcon={<SvgCardAdd />}
        sx={{ width: "100%" }}
        variant={"contained"}
      >
        Add
      </Button>
      <Modal open={open} onClose={handleClose} title={"Add funds"}>
        <>
          <FormCurrencyInput
            name={"funds"}
            control={control}
            label={"Amount of funds"}
            placeholder={"$00.00"}
            helperText={
              !isFundsEmpty && (
                <>
                  <Typography
                    sx={{ display: "block", marginTop: "2px" }}
                    variant={"helperText"}
                  >
                    Current balance: {formattedCurrentBalance}
                  </Typography>
                  <Typography sx={{ display: "block" }} variant={"helperText"}>
                    Balance after operation: {formattedNewBalance}
                  </Typography>
                </>
              )
            }
          />
          <FormInput
            name={"note"}
            control={control}
            label={"Note"}
            placeholder={"Add note..."}
            multiline
            rows={4}
          />
          <ButtonsSection
            onCancel={handleClose}
            actionButton={
              <Button
                sx={{ width: "100%" }}
                onClick={handleSubmit(onSubmit)}
                variant={"contained"}
                disabled={isFundsEmpty}
              >
                Add funds
              </Button>
            }
          />
        </>
      </Modal>
      <MuiModal open={openSuccess} onClose={() => setOpenSuccess(false)}>
        <SuccessOperationModal
          onClose={() => setOpenSuccess(false)}
          title={`Funds successfully ${getTransactionLabel("add")}`}
          subtitle={`Your current balance is ${formattedCurrentBalance}`}
        />
      </MuiModal>
    </>
  );
};

export default AddFunds;
