import React from "react";
import { BoxHeaderInner, StyledPageWrapperBox } from "@/components/styled";
import Layout from "@/components/Layout";
import BudgetWidget from "@/components/BudgetWidget";
import { Grid } from "@mui/material";

const BudgetPage = () => {
  return (
    <Layout>
      <StyledPageWrapperBox>
        <Grid container columns={12}>
          <Grid item xs={12}>
            <BudgetWidget />
          </Grid>
        </Grid>
      </StyledPageWrapperBox>
      <BoxHeaderInner />
    </Layout>
  );
};

export default BudgetPage;
