const convertToCSV = (array: any[], headers: string[]) => {
  const escapeValue = (value: any) => {
    if (typeof value === "string" && value.includes(",")) {
      // Wrap the value in double quotes and escape any double quotes within the value
      return `"${value.replace(/"/g, '""')}"`;
    }
    return value;
  };

  // join rows with new line
  return [
    headers.join(","), // join headers with comma
    ...array.map(
      (item) => Object.values(item).map(escapeValue).join(","), // join each item's values with comma
    ),
  ].join("\n");
};

export const downloadCSV = (
  data: any[],
  fileName: string,
  headers: string[],
) => {
  const csvContent = convertToCSV(data, headers);
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${fileName}.csv`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
