import React, { useState } from "react";
import Layout from "@/components/Layout";
import { StyledPageWrapperBox } from "@/components/styled";
import ProfileInfoCard from "@/components/ProfileInfoCard";
import { useUserStore } from "@/store/userStore.ts";
import { Box, Grid, Modal as MuiModal } from "@mui/material";
import UpdateUserWrapper from "@/pages/ProfilePage/components/UpdateUserWrapper.tsx";
import DeleteAccountCard from "@/pages/admin-pages/UserProfilePage/components/DeleteAccountCard.tsx";
import WarningMessage from "@/pages/admin-pages/UserProfilePage/components/WarningMessage.tsx";
import DangerModal from "@/components/DangerModal";
import { deleteAccountReq } from "@/api/userApi";

const ProfilePage = () => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const user = useUserStore((state) => state.user);
  const profileCompletion = useUserStore((state) => state.profileCompletion);

  const handleCloseDeleteModal = () => setOpenDeleteModal(false);

  const handleDeleteUser = async () => {
    try {
      await deleteAccountReq();
    } catch (e) {
      console.error(e);
    } finally {
      handleCloseDeleteModal();
    }
  };
  return (
    <Layout>
      <StyledPageWrapperBox>
        <Box
          sx={{
            marginTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <ProfileInfoCard
              user={user}
              profileCompletion={profileCompletion ?? ""}
              updateProfileComponent={<UpdateUserWrapper />}
            />
          </Box>
          <DeleteAccountCard
            onDelete={() => setOpenDeleteModal(true)}
            buttonText={"Delete Account"}
            confirmationText={"I confirm account deletion"}
            headerText={"Delete Account"}
          >
            <Box sx={{ width: "100%", padding: "24px 0" }}>
              <WarningMessage
                title={"You are deleting your account"}
                message={
                  "Deleting your account will permanently remove all your data from our system. Your request will be reviewed within 7 days."
                }
              />
            </Box>
          </DeleteAccountCard>
        </Box>
        <MuiModal open={openDeleteModal} onClose={handleCloseDeleteModal}>
          <>
            <DangerModal
              onClick={handleDeleteUser}
              onClose={handleCloseDeleteModal}
              title={`Delete Account?`}
              subtitle={`Deleting your account will permanently remove all your data from our system. Your request will be reviewed within 7 days.`}
              buttonText={"Send request"}
            />
          </>
        </MuiModal>
      </StyledPageWrapperBox>
    </Layout>
  );
};

export default ProfilePage;
