import { LogType } from "@/types";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";

export const transformLogToCSV = (logs: LogType[]) => {
  return logs?.map((el) => ({
    userId: el?.userId,
    accountName: el?.accountName,
    ipAddress: el?.ipAddress,
    location: el?.location,
    type: el?.logType,
    result: getLogStatusBadgeLabel(el?.status),
    time: el?.timestamp,
    eventLog: el?.message,
  }));
};
