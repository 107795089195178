import React, { useState } from "react";
import Modal from "@/components/Modal";
import { Box, Button, styled, Typography } from "@mui/material";
import { StyledRightActionButton, StyledSwitch } from "@/components/styled";
import { useAuthStore } from "@/store/authStore.ts";

const BoxButtonsWrapper = styled(Box)(({ theme }) => ({
  marginTop: "32px",
  display: "flex",
  gap: "8px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
    width: "100%",
  },
}));

const ManageCookiesModal = () => {
  const [open, setOpen] = useState(false);

  const acceptCookies = useAuthStore((state) => state.acceptCookies);

  const handleClose = () => {
    setOpen(false);
  };

  const savePreferences = async () => {
    await acceptCookies({ essentialCookies: true });
    handleClose();
  };
  return (
    <>
      <StyledRightActionButton
        onClick={() => setOpen(true)}
        variant={"outlined"}
        sx={{ whiteSpace: "nowrap", width: "100%" }}
      >
        Manage Settings
      </StyledRightActionButton>
      <Modal
        open={open}
        onClose={handleClose}
        title={`Manage Cookie Preferences`}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              padding: "40px 0",
            }}
          >
            <CookieItem
              title={"Essential Cookies"}
              description={
                "Required for the website to function properly. These cannot be disabled."
              }
              checked={true}
              disabled={true}
            />
          </Box>
          <BoxButtonsWrapper>
            <Button
              variant={"outlined"}
              sx={{ width: "100%" }}
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={savePreferences}
              variant={"contained"}
              sx={{ width: "100%" }}
            >
              Save preferences
            </Button>
          </BoxButtonsWrapper>
        </>
      </Modal>
    </>
  );
};

export default ManageCookiesModal;

type Props = {
  title: string;
  description: string;
  checked: boolean;
  disabled?: boolean;
};

const CookieItem = ({
  title,
  description,
  checked,
  disabled = false,
}: Props) => {
  return (
    <Box sx={{ display: "flex", gap: "20px" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          {title}
        </Typography>
        <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
          {description}
        </Typography>
      </Box>
      <StyledSwitch disabled={disabled} variant={"first"} checked={checked} />
    </Box>
  );
};
