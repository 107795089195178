import axios from "@/libs/axios.ts";
import { LogType, UserType } from "@/types";

export const getRequestsLogsReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
  };
  return axios.get(`/admin/access-request`, { params });
};

export const updateDataRequestLogNoteReq = async (
  logId: LogType["id"],
  body: { note: string },
) => axios.patch(`/admin/access-request/${logId}`, body);
