import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "@/store/authStore.ts";
import { resetPasswordReq, sendResetPasswordLinkReq } from "@/api/authApi";
import {
  Box,
  Button,
  Modal as MuiModal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormInput from "@/components/FormInput";
import Card from "@/components/Card";
import SVGCheckMark from "@/assets/icons/SVGCheckMark.tsx";
import SVGInfo from "@/assets/icons/SVGInfo.tsx";
import SuccessOperationModal from "@/components/SuccessOperationModal";
import { getTransactionLabel } from "@/utils/getTransactionLabel.ts";

const CreateNewPasswordForm = () => {
  const theme = useTheme();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { handleSubmit, control, setError, watch } = useForm<{
    newPassword: string;
    confirmPassword: string;
  }>();
  const navigate = useNavigate();
  const resetPasswordData = useAuthStore((state) => state.resetPasswordData);
  const setResetPasswordData = useAuthStore(
    (state) => state.setResetPasswordData,
  );
  const newPassword = watch("newPassword");
  const confirmPassword = watch("confirmPassword");
  const [openSuccess, setOpenSuccess] = useState(false);

  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    letter: false,
    number: false,
    specialChar: false,
  });

  const validatePassword = (password = "") => {
    return {
      length: password.length >= 8 && password.length <= 30,
      letter: /[a-zA-Z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[^A-Za-z0-9]/.test(password),
    };
  };

  useEffect(() => {
    setPasswordValidation(validatePassword(newPassword));
  }, [newPassword]);

  const handleSubmitForm = async (values: {
    newPassword: string;
    confirmPassword: string;
  }) => {
    if (confirmPassword !== newPassword) {
      setError("confirmPassword", { message: "Non-matching passwords" });
    } else {
      try {
        if (resetPasswordData?.token) {
          await resetPasswordReq({
            password: values?.newPassword,
            token: resetPasswordData.token,
          });
          setResetPasswordData({ token: null, email: null });
          setOpenSuccess(true);
        }
      } catch (e: any) {
        const errorMessage = e?.response?.data?.error;
        setError("confirmPassword", {
          message: errorMessage ? errorMessage : e?.message,
        });
      }
    }
  };

  const validationItems = [
    { text: "8-30 characters", isValid: passwordValidation.length },
    { text: "One letter", isValid: passwordValidation.letter },
    { text: "One number", isValid: passwordValidation.number },
    {
      text: "One special character (Ex: $, #, % or !)",
      isValid: passwordValidation.specialChar,
    },
  ];

  const isNewPasswordValid = Object.values(passwordValidation).every(Boolean);

  const handleSuccessModalClose = () => {
    setResetPasswordData({ token: null, email: null });
    navigate("/login");
    setOpenSuccess(false);
  };

  return (
    <Card
      sx={{ ...(!matchesDownLg && { maxWidth: "570px" }), width: "100%" }}
      title={<Typography variant={"cardHeader"}>Set new password</Typography>}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "#000",
          fontWeight: 400,
          marginTop: "8px",
        }}
      >
        The new password must be different from the old one.
      </Typography>
      <Box
        sx={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormInput
          name={"newPassword"}
          label={"Set new password"}
          placeholder={"Enter new password"}
          type={"password"}
          control={control}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "4px",
            marginTop: "-16px",
            marginBottom: "16px",
          }}
        >
          {validationItems.map((item, index) => (
            <ValidationItem
              key={index}
              isValid={item.isValid}
              text={item.text}
            />
          ))}
        </Box>
        <FormInput
          name={"confirmPassword"}
          label={"Confirm new password"}
          placeholder={"Confirm new password"}
          type={"password"}
          control={control}
        />
        <Button
          disabled={
            !newPassword?.length || !confirmPassword || !isNewPasswordValid
          }
          sx={{ width: "100%", marginTop: "24px" }}
          onClick={handleSubmit(handleSubmitForm)}
          variant={"contained"}
        >
          Reset password
        </Button>
      </Box>
      <MuiModal open={openSuccess} onClose={handleSuccessModalClose}>
        <>
          <SuccessOperationModal
            onClose={handleSuccessModalClose}
            title={`Password reset`}
            subtitle={`Your password has been successfully reset. Click below to log in.`}
            buttonText={"Log in"}
          />
        </>
      </MuiModal>
    </Card>
  );
};

export default CreateNewPasswordForm;

const ValidationItem = ({
  isValid,
  text,
}: {
  isValid: boolean;
  text: string;
}) => {
  return (
    <Box sx={{ display: "flex", gap: "2px", alignItems: "center" }}>
      {isValid ? <SVGCheckMark /> : <SVGInfo />}
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: "12px",
          color: (theme) => `${isValid ? "#007586" : theme.palette.textGray1}`,
        }}
      >
        {text}
      </Typography>
    </Box>
  );
};
