import React from "react";
import { StyledBadge } from "@/components/styled";
import { getProjectDocumentationBadgeVariant } from "@/utils/getProjectDocumentationBadgeVariant.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import BadgeSelect from "@/pages/admin-pages/UserManagementPage/components/BadgeSelect.tsx";
import { PROJECT_FILE_STATUSES } from "@/constants";
import { FileRecord, ProjectRecord } from "@/types";

type Props = {
  isDeleted: boolean;
  isAdmin: boolean;
  rowId: number;
  rowStatus: string;
  updateProjectFile: (
    fileId: FileRecord["id"],
    data: Partial<FileRecord>,
  ) => Promise<void>;
};

const ProjectBadgesWrapper = ({
  isDeleted,
  isAdmin,
  rowId,
  rowStatus,
  updateProjectFile,
}: Props) => {
  if (isDeleted && isAdmin)
    return (
      <StyledBadge
        sx={{ display: "inline", textWrap: "nowrap" }}
        {...getProjectDocumentationBadgeVariant(rowStatus)}
      >
        {capitalizeFirstLetter(rowStatus)}
      </StyledBadge>
    );
  if (!isDeleted)
    return (
      <BadgeSelect
        readOnly={!isAdmin}
        currentSelectedItem={rowStatus}
        items={PROJECT_FILE_STATUSES}
        onSelectItem={(value) => updateProjectFile(rowId, { status: value })}
        badgeVariantFn={getProjectDocumentationBadgeVariant}
      />
    );
};

export default ProjectBadgesWrapper;
