import React, { useState } from "react";

const useTableFilter = (
  handleChangePage: (
    _: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number,
  ) => void,
) => {
  const [filters, setFilters] = useState([] as string[]);
  const handleFilterChange = (values: string[]) => {
    setFilters(values);
    handleChangePage(null, 0);
  };
  return { filters, handleFilterChange };
};

export default useTableFilter;
