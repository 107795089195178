import React from "react";
import Layout from "@/components/Layout";
import { BoxHeaderInner, StyledPageWrapperBox } from "@/components/styled";
import DataRequestsTable from "@/pages/admin-pages/DataRequestsPage/components/DataRequestsTable.tsx";

const DataRequestsPage = () => {
  return (
    <Layout>
      <StyledPageWrapperBox>
        <DataRequestsTable />
      </StyledPageWrapperBox>
      <BoxHeaderInner />
    </Layout>
  );
};

export default DataRequestsPage;
