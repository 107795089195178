import { create } from "zustand";
import { getBudgetList, getTransactionsList } from "@/api/budgetWidgetApi";
import { BudgetType, TransactionsDataType } from "@/types";

type State = {
  budgetData: BudgetType;
  transactions: TransactionsDataType;
};

type Actions = {
  setBudgetData: (budget: BudgetType) => void;
  getBudgetData: () => void;
  getTransactions: () => void;
};

export const useBudgetStore = create<State & Actions>((set) => ({
  budgetData: {} as BudgetType,
  transactions: {} as TransactionsDataType,
  setBudgetData: (budget) => {
    set(() => ({
      budgetData: budget,
    }));
  },
  getBudgetData: async () => {
    try {
      const response = await getBudgetList();
      set(() => ({
        budgetData: response?.data?.budget,
      }));
    } catch (e) {
      console.error(e);
    }
  },
  getTransactions: async () => {
    try {
      const response = await getTransactionsList();
      set(() => ({
        transactions: response?.data?.transactions,
      }));
    } catch (e) {
      console.error(e);
    }
  },
}));
