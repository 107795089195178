import SVGFilePDF from "@/assets/icons/SVGFilePDF.tsx";
import SVGFileCSV from "@/assets/icons/SVGFileCSV.tsx";
import SVGFileXLSX from "@/assets/icons/SVGFileXLSX.tsx";
import SVGFileDOCX from "@/assets/icons/SVGFileDOCX.tsx";
import SVGUpload from "@/assets/icons/SVGUpload.tsx";

export const getFileIcon = (fileName: string) => {
  const ext = fileName.split(".").pop()?.toLowerCase();
  switch (ext) {
    case "pdf":
      return <SVGFilePDF />;
    case "csv":
      return <SVGFileCSV />;
    case "xlsx":
      return <SVGFileXLSX />;
    case "docx":
      return <SVGFileDOCX />;
    default:
      return <SVGUpload />;
  }
};
