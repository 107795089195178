import React, { useEffect, useMemo } from "react";
import {
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  BoxAction,
  StyledBadge,
  StyledRightActionButton,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import useTablePagination from "@/hooks/useTablePagination.ts";
import TablePagination from "@/components/TablePagination";
import { SessionType, UserType } from "@/types";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatDate } from "@/utils/formatDate.ts";
import { getSessionStatusBadgeVariant } from "@/utils/getSessionStatusBadgeVariant.ts";
import { useAdminStore } from "@/store/adminStore.ts";
import SvgCross from "@/assets/icons/SVGCross.tsx";
import { finishAllSessionsReq, finishCurrentSessionReq } from "@/api/adminApi";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import CommonTableActions from "@/components/CommonTableActions";
import TableFilter from "@/components/TableFilter";
import { COMMON_SORT_ITEMS, SESSIONS_FILTER_ITEMS } from "@/constants";
import TableSort from "@/components/TableSort";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

function createData(
  id: number,
  userId: UserType["id"],
  ipAddress: string,
  location: string,
  time: string,
  status: "ok" | "error",
  userAgent: string,
) {
  return {
    id,
    userId,
    ipAddress,
    location,
    status,
    userAgent,
    time: dayjs(time).format("MMMM D, YYYY"),
  };
}

const LoginSessionsTable = () => {
  const fetchDataWithLoading = useFetchDataWithLoading();
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const { userSessions, totalSessionsCount } = useAdminStore(
    (state) => state.userSessions,
  );
  const userId = useAdminStore((state) => state.user?.id);
  const getUserSessions = useAdminStore((state) => state.getUserSessions);
  const rows = useMemo(() => {
    return userSessions.map(
      ({ id, ipAddress, location, createdAt, status, userAgent, user }) =>
        createData(
          id,
          user,
          ipAddress,
          location,
          createdAt,
          status as any,
          userAgent,
        ),
    );
  }, [userSessions]);
  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalSessionsCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const finishSession = async (sessionId: SessionType["id"]) => {
    try {
      await finishCurrentSessionReq({ sessionId });
      getUserSessions(userId, page, rowsPerPage, searchValue, filters, sort);
    } catch (e) {
      console.error(e);
    }
  };

  const handleFinishSessions = async () => {
    if (userId) {
      try {
        await finishAllSessionsReq(userId);
        getUserSessions(userId, page, rowsPerPage, searchValue, filters, sort);
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (userId) {
      fetchDataWithLoading(getUserSessions, [
        userId,
        page,
        rowsPerPage,
        searchValue,
        filters,
        sort,
      ]);
    }
  }, [
    userId,
    page,
    rowsPerPage,
    searchValue,
    filters,
    sort,
    fetchDataWithLoading,
    getUserSessions,
  ]);
  return (
    <StyledTableCard>
      <CommonTableActions
        title={"Login Sessions"}
        onSearch={handleDebounceSearchValue}
        filter={
          <TableFilter
            placeholder={"Result"}
            items={SESSIONS_FILTER_ITEMS}
            setFilterValues={handleFilterChange}
          />
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
        rightActions={
          <StyledRightActionButton
            onClick={handleFinishSessions}
            variant={"dangerOutlined"}
            sx={{
              whiteSpace: "nowrap",
            }}
          >
            {!downLg ? "Finish all sessions" : "Finish all"}
          </StyledRightActionButton>
        }
      />
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Session ID</StyledTableCell>
              <StyledTableCell>IP Address</StyledTableCell>
              <StyledTableCell>Location</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Device</StyledTableCell>
              <StyledTableCell>Time</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  key={row.id}
                  title={row.id}
                  firstSub={row.userAgent}
                  secondSub={
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getSessionStatusBadgeVariant(row.status)}
                    >
                      {capitalizeFirstLetter(row.status)}
                    </StyledBadge>
                  }
                >
                  <CellWithHeader header={"IP Address"}>
                    {row.ipAddress}
                  </CellWithHeader>
                  <CellWithHeader header={"Location"}>
                    {row.location}
                  </CellWithHeader>
                  <CellWithHeader header={"Time"}>
                    {formatDate(row.time)}
                  </CellWithHeader>
                  <StyledRightActionButton
                    onClick={() => finishSession(row.id)}
                    variant={"danger"}
                  >
                    Finish session
                  </StyledRightActionButton>
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>{row.ipAddress}</StyledTableCell>
                  <StyledTableCell>{row.location}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      sx={{ display: "inline", textWrap: "nowrap" }}
                      {...getSessionStatusBadgeVariant(row.status)}
                    >
                      {capitalizeFirstLetter(row.status)}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>{row.userAgent}</StyledTableCell>
                  <StyledTableCell>{formatDate(row.time)}</StyledTableCell>
                  <StyledTableCell>
                    <BoxAction
                      onClick={() => finishSession(row.id)}
                      sx={{ color: (theme) => theme.palette.red1 }}
                    >
                      <SvgCross />
                    </BoxAction>
                  </StyledTableCell>
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalSessionsCount}
      />
    </StyledTableCard>
  );
};

export default LoginSessionsTable;
