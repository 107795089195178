import { TransactionType } from "@/types";

export const getTransactionLabel = (type: TransactionType["type"]) => {
  switch (type) {
    case "add":
      return "Added";
    case "deduct":
      return "Deducted";
    case "transfer":
      return "Transferred";
    default:
      return "Operation";
  }
};
