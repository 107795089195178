import React, { useCallback, useEffect, useMemo, useState } from "react";
import useTablePagination from "@/hooks/useTablePagination.ts";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import {
  TableBody,
  TableContainer,
  TableRow,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  StyledBadge,
  StyledIconButton,
  StyledRightActionButton,
  StyledRowActionsWrapperBox,
  StyledTable,
  StyledTableCard,
  StyledTableCell,
  StyledTableHeader,
  StyledTableRow,
} from "@/components/styled";
import TablePagination from "@/components/TablePagination";
import useTableFilter from "@/hooks/useTableFilter.ts";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { getDataRequestBadgeVariant } from "@/utils/getDataRequesBadgeVariant.ts";
import DataRequestDetailModal from "@/pages/admin-pages/DataRequestsPage/components/DataRequestDetailModal.tsx";
import RejectDataRequestModal from "@/pages/admin-pages/DataRequestsPage/components/RejectDataRequestModal.tsx";
import CommonTableActions from "@/components/CommonTableActions";
import TableFilter from "@/components/TableFilter";
import {
  COMMON_SORT_ITEMS,
  DataRequestLogsCSVHeaders,
  LOGS_FILTER_ITEMS,
  LogsCSVHeaders,
  REQUEST_LOGS_FILTER_ITEMS,
} from "@/constants";
import TableSort from "@/components/TableSort";
import SVGDownload from "@/assets/icons/SVGDownload.tsx";
import CollapsableRow, { CellWithHeader } from "@/components/CollapsableRow";
import { useAdminStore } from "@/store/adminStore.ts";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { UserType } from "@/types";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";

function createDataRequestData(
  id: number,
  dateTime: string,
  status: string,
  accountName: string,
  email: string,
  requestType: string,
  location: string,
  ipAddress: string,
  note: string,
  userId: number | undefined,
  role: UserType["role"],
) {
  return {
    id,
    dateTime: formatDate(dateTime),
    status,
    accountName,
    email,
    requestType,
    location,
    ipAddress,
    note,
    userId,
    role,
  };
}

export type DataRequestLogRowType = {
  id: number;
  dateTime: string;
  status: string;
  accountName: string;
  email: string;
  requestType: string;
  note: string;
  location: string;
  ipAddress: string;
  userId: number | undefined;
  role: UserType["role"];
};

const DataRequestsTable = () => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const getRequestsLogs = useAdminStore((state) => state.getRequestsLogs);
  const { requestsLogs, totalRequestsLogs } = useAdminStore(
    (state) => state.requestsLogs,
  );
  const fetchDataWithLoading = useFetchDataWithLoading();

  const [rejectionRequest, setRejectionRequest] = useState<null | any>(null);

  const rows = useMemo(() => {
    return requestsLogs?.map(
      ({
        id,
        timestamp,
        status,
        accountName,
        email,
        logType,
        note,
        ipAddress,
        userId,
        location,
        role,
      }) =>
        createDataRequestData(
          id,
          timestamp,
          status,
          accountName,
          email,
          logType,
          location,
          ipAddress,
          note,
          userId,
          role,
        ),
    );
  }, [requestsLogs]);

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(rows, totalRequestsLogs);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { sort, handleSortChange } = useTableSort();
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);

  const getRequestsLogsReq = useCallback(() => {
    fetchDataWithLoading(getRequestsLogs, [
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      sort,
    ]);
  }, [page, rowsPerPage, searchValue, filters, sort, getRequestsLogs]);

  useEffect(() => {
    getRequestsLogsReq();
  }, [getRequestsLogsReq]);

  return (
    <StyledTableCard sx={{ width: "100%" }}>
      <CommonTableActions
        title={"Data requests"}
        onSearch={handleDebounceSearchValue}
        filter={
          <TableFilter
            placeholder={"Result"}
            items={REQUEST_LOGS_FILTER_ITEMS}
            setFilterValues={handleFilterChange}
          />
        }
        sort={
          <TableSort
            placeholder={"Sort"}
            items={COMMON_SORT_ITEMS}
            setSortValue={handleSortChange}
          />
        }
        rightActions={
          <StyledRightActionButton
            sx={{ whiteSpace: "nowrap" }}
            onClick={() => {
              downloadCSV(rows, "logs", DataRequestLogsCSVHeaders);
            }}
            startIcon={<SVGDownload />}
            variant={"outlined"}
          >
            {!downSm && "Download CSV report"}
            {downSm && "Report"}
          </StyledRightActionButton>
        }
      />
      <TableContainer>
        <StyledTable aria-label="customized table">
          <StyledTableHeader>
            <TableRow>
              <StyledTableCell>Request ID</StyledTableCell>
              <StyledTableCell>Date & time</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell>Account name</StyledTableCell>
              <StyledTableCell>Email</StyledTableCell>
              <StyledTableCell>Request type</StyledTableCell>
              <StyledTableCell>Detailed description</StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </TableRow>
          </StyledTableHeader>
          <TableBody>
            {rows.map((row) =>
              downSm ? (
                <CollapsableRow
                  key={row.id}
                  title={row.id}
                  firstSub={row.requestType}
                  secondSub={
                    <StyledBadge
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                      sx={{ display: "inline", textWrap: "nowrap" }}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  }
                >
                  <CellWithHeader header={"Account name"}>
                    {row.accountName}
                  </CellWithHeader>
                  <CellWithHeader header={"Account email"}>
                    {row.email}
                  </CellWithHeader>

                  <DataRequestDetailModal
                    dataRequest={row}
                    getLogs={getRequestsLogsReq}
                  />
                  <CellWithHeader header={"Upload date"}>
                    {row.dateTime}
                  </CellWithHeader>
                  {row.status === "pending" && (
                    <StyledRowActionsWrapperBox>
                      <StyledIconButton
                        sx={{ flex: 1 }}
                        onClick={() => setRejectionRequest(row)}
                        variant={"dangerOutlined"}
                      >
                        Reject
                      </StyledIconButton>
                      <StyledIconButton sx={{ flex: 1 }} variant={"contained"}>
                        Approve
                      </StyledIconButton>
                    </StyledRowActionsWrapperBox>
                  )}
                </CollapsableRow>
              ) : (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{row.id}</StyledTableCell>
                  <StyledTableCell>{row.dateTime}</StyledTableCell>
                  <StyledTableCell>
                    <StyledBadge
                      {...getLogStatusBadgeVariant(row.status ?? "")}
                      sx={{ display: "inline", textWrap: "nowrap" }}
                    >
                      {getLogStatusBadgeLabel(row.status ?? "")}
                    </StyledBadge>
                  </StyledTableCell>
                  <StyledTableCell>{row.accountName}</StyledTableCell>
                  <StyledTableCell>{row.email}</StyledTableCell>
                  <StyledTableCell>{row.requestType}</StyledTableCell>
                  <DataRequestDetailModal
                    dataRequest={row}
                    getLogs={getRequestsLogsReq}
                  />
                </StyledTableRow>
              ),
            )}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <TablePagination
        rowsPerPage={rowsPerPage}
        page={page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        totalCount={totalRequestsLogs}
      />
      <RejectDataRequestModal
        rejectionRequest={rejectionRequest}
        setOpen={setRejectionRequest}
      />
    </StyledTableCard>
  );
};

export default DataRequestsTable;
