const SvgCopyText = () => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="5.24805"
        y="5.74844"
        width="10.5044"
        height="10.5044"
        rx="2"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24832 13.2516H3.7477C2.91892 13.2516 2.24707 12.5797 2.24707 11.7509V4.24781C2.24707 3.41904 2.91892 2.74719 3.7477 2.74719H11.2508C12.0796 2.74719 12.7514 3.41904 12.7514 4.24781V5.74844"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgCopyText;
