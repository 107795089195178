import React, { useEffect, useState } from "react";
import FormAutocompleteTags, {
  FormInputProps,
} from "@/components/FormAutocompleteTags";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";

const FormAsyncSelect = ({
  getItems,
  itemsKey,
  ...props
}: { getItems: (e?: any) => Promise<any>; itemsKey: string } & {
  multiple?: boolean;
} & Omit<FormInputProps, "onDebounce">) => {
  const [items, setItems] = useState([]);
  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch(
    () => {},
  );
  useEffect(() => {
    const fetchItems = async () => {
      try {
        const response = await getItems(searchValue);
        setItems(response?.data?.[itemsKey]);
      } catch (e) {
        console.error(e);
      }
    };
    fetchItems();
  }, [searchValue]);

  return (
    <FormAutocompleteTags
      {...props}
      values={items}
      onDebounce={handleDebounceSearchValue}
    />
  );
};

export default FormAsyncSelect;
