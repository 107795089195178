import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import UpdateTemplateModal from "@/pages/admin-pages/FileManagementPage/copmonents/TemplateManagement/UpdateTemplateModal.tsx";
import { StyledIconButton } from "@/components/styled";
import SVGTrashCan from "@/assets/icons/SVGTrashCan.tsx";
import SVGRefresh from "@/assets/icons/SVGRefresh.tsx";
import { downloadFile } from "@/utils/downloadFile.ts";
import SVGDownloadFile from "@/assets/icons/SVGDownloadFile.tsx";
import { TemplateRowType } from "@/types";

type Props = {
  isDeleted: boolean;
  isAdmin: boolean;
  row: TemplateRowType;
  getFilesReq: () => void;
  deleteFile: () => void;
  restoreFile: () => void;
};
const TemplatesActionsWrapper = ({
  isDeleted,
  isAdmin,
  row,
  getFilesReq,
  deleteFile,
  restoreFile,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  if (isAdmin) {
    if (!isDeleted) {
      return (
        <>
          <UpdateTemplateModal template={row} getFiles={getFilesReq} />
          <StyledIconButton
            onClick={() => {
              deleteFile();
            }}
            variant={downSm ? "dangerOutlined" : "text"}
          >
            {!downSm && <SVGTrashCan />}
            {downSm && "Delete"}
          </StyledIconButton>
        </>
      );
    } else {
      return (
        <StyledIconButton
          onClick={() => {
            restoreFile();
          }}
          variant={downSm ? "outlined" : "text"}
        >
          {!downSm && <SVGRefresh />}
          {downSm && "Restore"}
        </StyledIconButton>
      );
    }
  }
  if (!isAdmin) {
    return (
      <StyledIconButton
        onClick={() => {
          downloadFile(`/template/${row.id}`, row.fileName);
        }}
      >
        {!downSm && <SVGDownloadFile />}
        {downSm && "Download file"}
      </StyledIconButton>
    );
  }
};

export default TemplatesActionsWrapper;
