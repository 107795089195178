import React from "react";
import { Grid } from "@mui/material";
import NewsListItem from "@/pages/NewsPage/components/NewsListItem.tsx";
import { useNewsStore } from "@/store/newsStore.ts";

const NewsList = () => {
  const news = useNewsStore((state) => state.news);
  return (
    <Grid container rowSpacing={"24px"} columnSpacing={"24px"}>
      {news?.map((el) => (
        <Grid key={el?.id} item xs={12} md={3}>
          <NewsListItem article={el} />
        </Grid>
      ))}
    </Grid>
  );
};

export default NewsList;
