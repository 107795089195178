import React from "react";

const SvgData = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18C5 19.381 8.134 20.5 12 20.5C15.866 20.5 19 19.381 19 18V6C19 4.619 15.866 3.5 12 3.5C8.134 3.5 5 4.619 5 6V18Z"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 6C19 7.381 15.866 8.5 12 8.5C8.134 8.5 5 7.381 5 6"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 14C19 15.381 15.866 16.5 12 16.5C8.134 16.5 5 15.381 5 14"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 10C19 11.381 15.866 12.5 12 12.5C8.134 12.5 5 11.381 5 10"
        stroke="#21201E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgData;
