import { INCENTIVE_STATUS } from "@/constants";

export const getFileStorageBadgeVariant = (status: string) => {
  if (status === INCENTIVE_STATUS.PAID) {
    return { neutral: true };
  }
  if (status === INCENTIVE_STATUS.RESERVED) {
    return { danger: true };
  }
  if (status === INCENTIVE_STATUS.PENDING) {
    return { yellow: true };
  }
  if (
    status === INCENTIVE_STATUS.NO_HELD ||
    status === INCENTIVE_STATUS.CANCELLED
  ) {
    return { grey: true };
  }

  return {};
};
