import React from "react";
import { BoxHeaderInner, StyledPageWrapperBox } from "@/components/styled";
import Layout from "@/components/Layout";
import FileManagementTable from "@/pages/admin-pages/FileManagementPage/copmonents/FileManagement/FileManagementTable.tsx";
import { FILES_MANAGEMENT_TABS, USERS_MANAGEMENT_TABS } from "@/constants";
import NavTab from "@/components/NavTab";
import { BoxHeaderBottomInner } from "@/pages/admin-pages/UserManagementPage";
import { Grid } from "@mui/material";
import { Outlet } from "react-router-dom";

const FileManagementPage = () => {
  return (
    <Layout
      headerBottomSection={
        <BoxHeaderBottomInner>
          {FILES_MANAGEMENT_TABS.map((el) => (
            <NavTab key={el?.text} text={el?.text} navTo={el?.navTo} />
          ))}
        </BoxHeaderBottomInner>
      }
    >
      <StyledPageWrapperBox>
        <Outlet />
      </StyledPageWrapperBox>
    </Layout>
  );
};

export default FileManagementPage;
