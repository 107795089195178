import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    bodyRegular: React.CSSProperties;
    bodyMedium: React.CSSProperties;
    subtitleRegular: React.CSSProperties;
    helperText: React.CSSProperties;
    body1Medium: React.CSSProperties;
    body1Small: React.CSSProperties;
    body2Small: React.CSSProperties;
    cardHeader: React.CSSProperties;
    cardHeader2: React.CSSProperties;
    bodyHeader2: React.CSSProperties;
    cardHeader3: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    bodyRegular?: React.CSSProperties;
    bodyMedium?: React.CSSProperties;
    subtitleRegular?: React.CSSProperties;
    helperText?: React.CSSProperties;
    body1Medium?: React.CSSProperties;
    body1Small?: React.CSSProperties;
    body2Small?: React.CSSProperties;
    cardHeader?: React.CSSProperties;
    cardHeader2?: React.CSSProperties;
    bodyHeader2?: React.CSSProperties;
    cardHeader3?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    bodyRegular: true;
    subtitleRegular: true;
    bodyMedium: true;
    helperText: true;
    body1Medium: true;
    body1Small: true;
    body2Small: true;
    cardHeader: true;
    cardHeader2: true;
    bodyHeader2: true;
    cardHeader3: true;
  }
}

declare module "@mui/material/styles" {
  interface Palette {
    chartFirstColor: string;
    chartSecondColor: string;
    chartThirdColor: string;
    green: string;
    blue: string;
    red: string;
    red1: string;
    errorText: string;
    linkText: string;
    textGray1: string;
    textGray2: string;
    textGray3: string;
    textGray4: string;
    textGray5: string;
    textLightGray1: string;
    textLightGray2: string;
    textLightGray3: string;
    textLightGray4: string;
    textLightGray5: string;
    textDarkBlue: string;
  }

  interface PaletteOptions {
    chartFirstColor?: string;
    chartSecondColor?: string;
    chartThirdColor?: string;
    green: string;
    blue: string;
    red: string;
    red1: string;
    errorText?: string;
    linkText?: string;
    textGray1?: string;
    textGray2?: string;
    textGray3?: string;
    textGray4?: string;
    textGray5?: string;
    textLightGray1?: string;
    textLightGray2?: string;
    textLightGray3?: string;
    textLightGray4?: string;
    textLightGray5?: string;
    textDarkBlue?: string;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsVariantOverrides {
    outlinedGray: true;
    danger: true;
    dangerOutlined: true;
    navigation: true;
  }
}

// A custom theme for this app

const baseTheme = createTheme();

const theme = createTheme({
  palette: {
    chartFirstColor: "#0076CE",
    chartSecondColor: "#FF9800",
    chartThirdColor: "#00A5BD",
    green: "#0096AC",
    blue: "#3391D8",
    red: "#B53700",
    red1: "#FF4D00",
    errorText: "#f44336",
    linkText: "#0076CE",
    textGray1: "#373533",
    textGray2: "#21201E",
    textGray3: "#716F6D",
    textGray4: "#4E4B48",
    textGray5: "#01161E",
    textLightGray1: "#EDEDED",
    textLightGray2: "#888684",
    textLightGray3: "#6C7278",
    textLightGray4: "#e0e0e0",
    textLightGray5: "#677378",
    textDarkBlue: "#354052",
  },
  typography: {
    fontFamily: ["Libre Franklin", "Arial", "sans-serif"].join(","),
    allVariants: {
      color: "#4E4B48", // Primary color for typography
    },
    bodyRegular: {
      fontSize: "16px",
      fontWeight: 400,
    },
    bodyMedium: {
      fontSize: "18px",
      fontWeight: 500,
    },
    subtitleRegular: {
      fontSize: "14px",
      fontWeight: 400,
      color: "#888684",
    },
    body1Medium: {
      fontSize: "16px",
      fontWeight: 500,
      color: "#1A1C1E",
    },
    body1Small: {
      fontSize: "14px",
      fontWeight: 500,
      color: "#21201E",
    },
    body2Small: {
      fontSize: "12px",
      fontWeight: 600,
      color: "#888684",
    },
    h3: {
      fontWidth: 600,
      fontSize: "28px",
    },
    helperText: {
      fontSize: "12px",
      fontWeight: 500,
      color: "#716F6D",
    },
    cardHeader: {
      fontSize: "36px",
      color: "#4E4B48",
      fontWeight: 700,
      [baseTheme.breakpoints.down("md")]: {
        fontSize: "32px",
        fontWeight: 600,
      },
    },
    cardHeader2: {
      fontWeight: 600,
      fontSize: "20px",
      color: "#4E4B48",
    },
    cardHeader3: {
      fontWeight: 600,
      fontSize: "16px",
      color: "#21201E",
    },
    bodyHeader2: {
      fontWeight: 600,
      fontSize: "32px",
      color: "#21201E",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {},
      variants: [
        {
          props: { variant: "contained" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            fontWeight: 600,
            textTransform: "none",
            boxShadow: "none",
          },
        },
        {
          props: { variant: "outlined" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            fontWeight: 600,
            textTransform: "none",
            minWidth: "initial",
          },
        },
        {
          props: { variant: "text" },
          style: {
            textTransform: "none",
          },
        },
        {
          props: { variant: "outlinedGray" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            textTransform: "none",
            border: "1px solid #EDEDED",
            color: "#888684",
            fontWeight: 400,
            fontSize: "14px",
            minWidth: "initial",
          },
        },
        {
          props: { variant: "danger" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            backgroundColor: "#FF4D00",
            color: "#fff",
            "&:hover": {
              backgroundColor: "#FF4D00",
            },
            "&:disabled": { color: "#FFEDE6", backgroundColor: "#FFC8B0" },
          },
        },
        {
          props: { variant: "navigation" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            backgroundColor: "#003257",
            padding: "12px 32px",
            color: "#fff",
          },
        },
        {
          props: { variant: "dangerOutlined" },
          style: {
            minHeight: "48px",
            borderRadius: "12px",
            textTransform: "none",
            fontWeight: 600,
            fontSize: "14px",
            backgroundColor: "#fff",
            color: "#FF4D00",
            border: "1px solid #FF4D00",
            "&:disabled": { color: "#FFEDE6", backgroundColor: "#FFC8B0" },
          },
        },
      ],
    },
  },
});

export default theme;
