import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import AccountManagementTable from "@/pages/admin-pages/UserManagementPage/components/AccountManagementTable.tsx";
import { useAdminStore } from "@/store/adminStore.ts";
import useTablePagination from "@/hooks/useTablePagination.ts";
import TablePagination from "@/components/TablePagination";
import UnauthorizedTable from "@/components/UnauthorizedTable";
import ActiveAccountsCard from "@/pages/admin-pages/UserManagementPage/components/ActiveAccountsCard.tsx";
import AccountRolesChart from "@/pages/admin-pages/UserManagementPage/components/AccountRolesChart.tsx";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import useTableFilter from "@/hooks/useTableFilter.ts";
import useTableSort from "@/hooks/useTableSort.ts";
import { createSearchParams, useNavigate } from "react-router-dom";
import TableFilter from "@/components/TableFilter";
import { ROLES_FILTER_ITEMS, USERS_SORT_ITEMS } from "@/constants";
import TableSort from "@/components/TableSort";
import { StyledTableCard } from "@/components/styled";
import CommonTableActions from "@/components/CommonTableActions";
import CreateUserModal from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/CreateUserModal.tsx";
import useFetchDataWithLoading from "@/hooks/useFetchDataWithLoading.ts";

const AccountManagement = () => {
  const getUsers = useAdminStore((state) => state.getUsers);
  const { users, totalUsersCount } = useAdminStore((state) => state.users);
  const navigate = useNavigate();

  const fetchDataWithLoading = useFetchDataWithLoading();

  const { page, rowsPerPage, handleChangeRowsPerPage, handleChangePage } =
    useTablePagination(users, totalUsersCount);
  const { searchValue, handleDebounceSearchValue } =
    useDebouncedSearch(handleChangePage);
  const { filters, handleFilterChange } = useTableFilter(handleChangePage);
  const { sort, handleSortChange } = useTableSort();

  useEffect(() => {
    fetchDataWithLoading(getUsers, [
      page + 1,
      rowsPerPage,
      searchValue,
      filters,
      sort,
    ]);
  }, [
    page,
    rowsPerPage,
    searchValue,
    filters,
    sort,
    fetchDataWithLoading,
    getUsers,
  ]);

  const navigateToUser = (userId: number) => {
    const params = createSearchParams({
      ...(searchValue && { search: searchValue }),
      ...(filters.length && { filter: filters.join(",") }),
      ...(sort && { sort: sort }),
    });
    navigate({
      pathname: `/user-profile/${userId}`,
      search: params?.toString(),
    });
  };

  return (
    <>
      <Grid sx={{ width: "100%" }} item xs={12} md={9}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <StyledTableCard sx={{ position: "relative" }}>
            <CommonTableActions
              title={"Account Management"}
              onSearch={handleDebounceSearchValue}
              filter={
                <TableFilter
                  placeholder={"Roles"}
                  items={ROLES_FILTER_ITEMS}
                  setFilterValues={handleFilterChange}
                />
              }
              sort={
                <TableSort
                  placeholder={"Sort"}
                  items={USERS_SORT_ITEMS}
                  setSortValue={handleSortChange}
                />
              }
              rightActions={
                <CreateUserModal
                  handleChangePage={() =>
                    getUsers(page + 1, rowsPerPage, searchValue, filters, sort)
                  }
                />
              }
            />
            <AccountManagementTable
              users={users}
              navigateToUser={navigateToUser}
              pagination={
                <TablePagination
                  rowsPerPage={rowsPerPage}
                  page={page}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  totalCount={totalUsersCount}
                />
              }
            />
          </StyledTableCard>
          <UnauthorizedTable />
        </Box>
      </Grid>
      <Grid sx={{ width: "100%" }} item xs={12} md={3}>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <ActiveAccountsCard />
          <AccountRolesChart />
        </Box>
      </Grid>
    </>
  );
};

export default AccountManagement;
