import React, { useEffect, useState } from "react";
import AuthLayout from "@/components/AuthLayout";
import GetLinkForm from "@/pages/ResetPasswordPage/components/GetLinkForm.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ResetPasswordSteps,
  useResetPasswordStepsStore,
} from "@/pages/ResetPasswordPage/store/resetPasswordStepsStore.ts";
import CreateNewPasswordForm from "@/pages/ResetPasswordPage/components/CreateNewPasswordForm.tsx";
import { resetPasswordTokenCheckReq } from "@/api/authApi";
import { Box, Snackbar } from "@mui/material";
import SnackbarBody from "@/components/SnackbarBody";

type RenderFormType = {
  [ResetPasswordSteps.GetLink]: JSX.Element;
  [ResetPasswordSteps.CreateNewPassword]: JSX.Element;
};

const RenderForm: RenderFormType = {
  [ResetPasswordSteps.GetLink]: <GetLinkForm />,
  [ResetPasswordSteps.CreateNewPassword]: <CreateNewPasswordForm />,
};

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const setResetPasswordData = useAuthStore(
    (state) => state.setResetPasswordData,
  );
  const setCurrentStep = useResetPasswordStepsStore(
    (state) => state.setCurrentStep,
  );
  const currentStep = useResetPasswordStepsStore((state) => state.currentStep);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const resetPasswordToken = query?.get("token");

  const [open, setOpen] = useState(false);

  const handleClose = (_: any, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (resetPasswordToken) {
      const resetTokenCheck = async () => {
        try {
          await resetPasswordTokenCheckReq({
            token: resetPasswordToken,
          });
          setResetPasswordData({ token: resetPasswordToken });
          setCurrentStep(ResetPasswordSteps.CreateNewPassword);
          navigate("/password-reset");
        } catch (e) {
          console.error(e);
          setOpen(true);
        }
      };
      resetTokenCheck();
    }
  }, [resetPasswordToken]);

  return (
    <AuthLayout
      onBackButtonClick={() => navigate("/login")}
      backButtonText={"Back to Login"}
    >
      {RenderForm[currentStep]}
      <Snackbar
        open={open}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={handleClose}
      >
        <Box>
          <SnackbarBody
            title={"Link expired"}
            description={"To reset password please enter your email again"}
            onClose={() => setOpen(false)}
          />
        </Box>
      </Snackbar>
    </AuthLayout>
  );
};

export default ResetPasswordPage;
