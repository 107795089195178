import React, { ReactNode } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  InputProps,
  Typography,
  useTheme,
} from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import TextEditor from "@/components/TextEditor";

type FormInputProps = InputProps & {
  name: string;
  control: Control<any, any>;
  label?: ReactNode;
  controlProps?: Partial<ControllerProps>;
};

const FormTextEditor = ({
  name,
  control,
  label,
  controlProps,
}: FormInputProps) => {
  const { palette } = useTheme();
  const isRequired = !!controlProps?.rules?.required;
  return (
    <FormControl component="fieldset" sx={{ width: "100%" }}>
      <FormLabel component="legend" sx={{ width: "100%" }}>
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: (theme) => theme.palette.textGray1,
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          {label}{" "}
          {isRequired && (
            <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
          )}
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          return (
            <Box sx={{ position: "relative", width: "100%" }}>
              <TextEditor onChange={onChange} initialData={value} />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: palette.errorText,
                  paddingTop: "4px",
                  opacity: !!error?.message ? 1 : 0,
                }}
              >
                {error?.message || "helper text"}
              </Typography>
            </Box>
          );
        }}
      />
    </FormControl>
  );
};

export default FormTextEditor;
