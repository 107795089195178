import React from "react";
import { Box, Typography } from "@mui/material";
import SVGAlert from "@/assets/icons/SVGAlert.tsx";
import SVGCross from "@/assets/icons/SVGCross.tsx";

type Props = {
  title: string;
  description: string;
  onClose: () => void;
};

const SnackbarBody = ({ title, description, onClose }: Props) => {
  return (
    <Box
      sx={{
        maxWidth: "360px",
        width: "100%",
        padding: "26px",
        borderRadius: "16px",
        backgroundColor: "#fff",
        border: "2px solid #FFC8B0",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "8px",
        }}
      >
        {/*<Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>*/}
        <SVGAlert />
        <Box>
          <Typography
            sx={{ fontWeight: 700, fontSize: "16px", color: "#21201E" }}
          >
            {title}
          </Typography>
          <Typography
            sx={{ fontWeight: 600, fontSize: "14px", color: "#4E4B48" }}
          >
            {description}
          </Typography>
        </Box>
        <Box onClick={onClose} sx={{ display: "flex", cursor: "pointer" }}>
          <SVGCross color={"#000"} />
        </Box>
        {/*</Box>*/}
      </Box>
    </Box>
  );
};

export default SnackbarBody;
