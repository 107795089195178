import axios from "@/libs/axios.ts";
import {
  FileRecord,
  LogType,
  ProjectRecord,
  SessionType,
  UserType,
} from "@/types";
import { DateRange } from "rsuite/DateRangePicker";
import dayjs from "dayjs";
import { FileType } from "rsuite/Uploader";
import { ArticleForm } from "@/pages/NewsPage/components/AddNewArticle.tsx";

export const getUsersReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
  };

  return axios.get(`/admin/user`, { params });
};

export const getUsersIdsReq = async (
  search = "",
  filter = [] as string[],
  sort = "",
) => {
  const params = {
    ...(search && { search }),
    ...(sort && { sort }),
    ...(filter?.length && { filter: filter.join(",") }),
    ids: true,
  };
  return axios.get(`/admin/user`, { params });
};

export const getUserReq = async (userId: UserType["id"]) => {
  return axios.get(`/admin/user/${userId}`);
};

export const getGeneratedPDFLogReq = async (logId: LogType["id"]) => {
  return axios.get(`/admin/log/report/${logId}`, { responseType: "blob" });
};

export const getActiveUsersReq = async () => {
  return axios.get(`/admin/user/active`);
};
export const getRoleStatisticReq = async () => {
  return axios.get(`/admin/user/statistics`);
};

export const getUserSessionsReq = async (
  userId: UserType["id"],
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
  };

  return axios.get(`/admin/session/${userId}`, { params });
};

export const finishCurrentSessionReq = async ({
  sessionId,
}: {
  sessionId: SessionType["id"];
}) => {
  return axios.delete(`/admin/session/${sessionId}`);
};
export const finishAllSessionsReq = async (userId: UserType["id"]) => {
  return axios.post(`/admin/logout/${userId}`);
};

export const getAllLogsReq = async (
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/admin/log`, { params });
};

export const getUserLogsReq = async (
  userId: UserType["id"],
  page = "1",
  pageLimit: any,
  search = "",
  filter = [] as string[],
  sort: string,
  dateRangeValue: DateRange | null,
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
    filter: filter.join(","),
    sort,
    startDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
      : "",
    endDate: dateRangeValue?.length
      ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
      : "",
  };
  return axios.get(`/admin/log/user/${userId}`, { params });
};

export const getUnauthorizedReq = async (
  page = "1",
  pageLimit: any,
  search = "",
) => {
  const params = {
    page,
    pageLimit: pageLimit ?? "",
    search,
  };
  return axios.get(`/admin/log/unauthorized`, { params });
};

export const updateUserReq = async (userId: UserType["id"], body: FormData) =>
  axios.patch(`/admin/user/update/${userId}`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const deleteUserReq = async (userId: UserType["id"]) =>
  axios.delete(`/admin/user/${userId}`);

export const createUserReq = async (body: FormData) =>
  axios.post(`/admin/user/create`, body, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const checkUserEmail = async (email: string) =>
  axios.get(`/admin/user/check/${email}`);

export const updateLogNoteReq = async (
  logId: LogType["id"],
  body: { note: string },
) => axios.patch(`/admin/log/${logId}/add-note`, body);

export const getManufacturingBrandsReq = async (searchValue: string) => {
  const params = {
    search: searchValue,
  };
  return axios.get(`/admin/user/oem`, { params });
};

export const restoreUserReq = async (userId: UserType["id"]) => {
  return axios.patch(`/admin/user/restore/${userId}`);
};

export const createArticleReq = async (body: ArticleForm) => {
  return axios.post(`/admin/article`, body);
};
export const updateArticleReq = async (
  articleId: number,
  body: ArticleForm,
) => {
  return axios.post(`/admin/article/${articleId}`, body);
};

export const deleteArticleReq = async (articleId: number) => {
  return axios.delete(`/admin/article/${articleId}`);
};
