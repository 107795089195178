import { UserType } from "@/types";

export const getBadgeVariant = (
  role: UserType["role"] | null,
  status: UserType["status"] | null,
) => {
  if (
    role === "administrator" ||
    status === "suspended" ||
    status === "deleted"
  ) {
    return { danger: true };
  }
  if (role === "oem" || status === "passed_training") {
    return { neutral: true };
  }
  if (status === "pending_approval") {
    return { yellow: true };
  }
  return {};
};
