import React from "react";
import { BoxHeaderInner, StyledPageWrapperBox } from "@/components/styled";
import Layout from "@/components/Layout";
import ProjectsTable from "@/pages/ProjectsPage/components/ProjectsTable.tsx";
import ProgressBar from "@/components/ProgressBar";

const ProjectsPage = () => {
  return (
    <Layout>
      <StyledPageWrapperBox>
        <ProjectsTable />
      </StyledPageWrapperBox>
      <BoxHeaderInner />
    </Layout>
  );
};

export default ProjectsPage;
