import React, { SVGProps } from "react";

const SvgProtectedCheckMark = (props: SVGProps<any>) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22.9785 15.5129L16.7565 21.7349L13.023 18.0014"
        stroke="#003257"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.65 8.88148C24.9615 8.59048 21.6045 7.12198 18.9555 4.84948C18.4125 4.38448 17.586 4.38448 17.0445 4.84948C14.3955 7.12048 11.0385 8.59048 7.35 8.88148C6.582 8.94148 6 9.59848 6 10.368V16.863C6 23.4135 10.734 29.556 17.217 31.3905C17.7255 31.5345 18.276 31.5345 18.7845 31.3905C25.266 29.5545 30 23.415 30 16.8645V10.3695C30 9.59848 29.418 8.94148 28.65 8.88148Z"
        stroke="#003257"
        strokeWidth="2.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgProtectedCheckMark;
