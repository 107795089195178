import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Modal as MuiModal,
  styled,
  Typography,
} from "@mui/material";
import SVGInfo from "@/assets/icons/SVGInfo.tsx";
import SVGArrowRight from "@/assets/icons/SVGArrowRight.tsx";
import NextStepModal from "@/pages/ContractorDashboardPage/components/NextStepModal.tsx";
import { useNavigate } from "react-router-dom";
import { completeTrainingVideoReq } from "@/api/userApi";
import { useAuthStore } from "@/store/authStore.ts";

const BoxVideoContainer = styled(Box)(({ theme }) => ({
  height: "640px",
  margin: "24px 0",
  [theme.breakpoints.down("sm")]: {
    height: "170px",
  },
}));

const BoxButtonContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  gap: " 8px",
  marginTop: "36px",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

declare global {
  interface Window {
    YT: any;
    onYouTubeIframeAPIReady: (() => void) | null;
  }
}

const videoId = import.meta.env.VITE_TRAINING_VIDEO_ID;
const checkInterval = 1000;

const iframeStyle = {
  width: "100%",
  height: "100%",
};

const TrainingVideo = () => {
  const [openModal, setOpenModal] = useState(false);

  const navigate = useNavigate();

  const playerRef = useRef<any>(null);
  const intervalRef = useRef<any>(null);
  const [completedTraining, setCompletedTraining] = useState(false);

  const trainingCompleted = useAuthStore((state) => state.trainingCompleted);
  const updateTrainingCompletion = useAuthStore(
    (state) => state.updateTrainingCompletion,
  );

  useEffect(() => {
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag?.parentNode?.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = initializePlayer;

    return () => {
      window.onYouTubeIframeAPIReady = null;
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  const initializePlayer = () => {
    playerRef.current = new window.YT.Player(playerContainer.current, {
      videoId: videoId,
      playerVars: {
        rel: 0, // Disable related videos
        modestbranding: 1, // Enable modest branding
      },
      events: {
        onReady: onPlayerReady,
        onStateChange: onPlayerStateChange,
      },
    });
  };

  const onPlayerReady = () => {
    if (intervalRef.current) {
      intervalRef.current = setInterval(trackVideoProgress, checkInterval);
    }

    const playerIframe = playerRef.current.getIframe();
    if (playerIframe) {
      playerIframe.style.width = iframeStyle.width;
      playerIframe.style.height = iframeStyle.height;
      playerIframe.style.border = 0;
    }
  };

  const onPlayerStateChange = (event: any) => {
    if (event.data === window.YT.PlayerState.PLAYING) {
      intervalRef.current = setInterval(trackVideoProgress, checkInterval);
    } else {
      clearInterval(intervalRef.current);
    }
  };

  const trackVideoProgress = () => {
    const duration = playerRef.current.getDuration();
    const currentTime = playerRef.current.getCurrentTime();
    const percentageWatched = (currentTime / duration) * 100;

    if (percentageWatched >= 90) {
      setCompletedTraining(true);
    }
  };

  const playerContainer = useRef(null);

  const handleCloseModal = () => setOpenModal(false);
  const handleNextStep = async () => {
    try {
      if (!trainingCompleted) {
        const response = await completeTrainingVideoReq({
          trainingCompleted: true,
        });
        updateTrainingCompletion(
          response?.data?.updatedUser?.trainingCompleted,
        );
        if (response?.data?.updatedUser?.trainingCompleted) {
          navigate("/map");
        }
      }
    } catch (e) {
      console.error(e);
    }
    setOpenModal(false);
  };

  const handleModalOpen = () => {
    setOpenModal(true);
  };

  useEffect(() => {
    setCompletedTraining(trainingCompleted);
  }, [trainingCompleted]);

  return (
    <>
      <BoxVideoContainer>
        <div ref={playerContainer}></div>
      </BoxVideoContainer>
      <BoxButtonContainer>
        <Box sx={{ display: "flex", gap: "8px" }}>
          <SVGInfo style={{ marginTop: "3px" }} />
          <Typography sx={{ width: "100%" }} variant={"bodyRegular"}>
            Before proceeding to the next step, make sure to complete your
            training program
          </Typography>
        </Box>
        <Button
          disabled={!completedTraining}
          onClick={handleModalOpen}
          sx={{ display: "flex", gap: "8px", whiteSpace: "nowrap" }}
          variant={"contained"}
        >
          I watched the video <SVGArrowRight />
        </Button>
      </BoxButtonContainer>
      <MuiModal open={openModal} onClose={handleCloseModal}>
        <>
          <NextStepModal
            onClick={handleNextStep}
            onClose={handleCloseModal}
            title={`Have you completed your training?`}
            subtitle={`To continue, you must first study the material`}
            buttonText={"Yes, proceed to the next step"}
          />
        </>
      </MuiModal>
    </>
  );
};

export default TrainingVideo;
