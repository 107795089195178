import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

type Props = {
  text: string;
  navTo: string;
};

const NavTab = ({ text, navTo }: Props) => {
  const navigate = useNavigate();
  const handleTabClick = (navigateTo: string) => navigate(navigateTo);
  return (
    <NavLink style={{ textDecoration: "none" }} to={navTo} end={true}>
      {({ isActive }) => (
        <Box
          sx={{
            fontSize: "16px",
            fontWeight: 600,
            color: isActive ? "#E6F1FA" : "#C8C7C6",
            paddingBottom: "12px",
            ...(isActive && { borderBottom: "1px solid #E6F1FA" }),
          }}
          onClick={() => handleTabClick(navTo)}
        >
          {text}
        </Box>
      )}
    </NavLink>
  );
};

export default NavTab;
