const SvgArrowRight = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_57_1325)">
        <path
          d="M14.707 5.63601L20.364 11.293C20.5515 11.4805 20.6568 11.7348 20.6568 12C20.6568 12.2652 20.5515 12.5195 20.364 12.707L14.707 18.364C14.5184 18.5462 14.2658 18.647 14.0036 18.6447C13.7414 18.6424 13.4906 18.5372 13.3052 18.3518C13.1198 18.1664 13.0146 17.9156 13.0123 17.6534C13.01 17.3912 13.1108 17.1386 13.293 16.95L17.243 13H4C3.73478 13 3.48043 12.8946 3.29289 12.7071C3.10536 12.5196 3 12.2652 3 12C3 11.7348 3.10536 11.4804 3.29289 11.2929C3.48043 11.1054 3.73478 11 4 11H17.243L13.293 7.05001C13.1975 6.95776 13.1213 6.84742 13.0689 6.72541C13.0165 6.60341 12.9889 6.47219 12.9877 6.33941C12.9866 6.20663 13.0119 6.07495 13.0622 5.95205C13.1125 5.82916 13.1867 5.71751 13.2806 5.62361C13.3745 5.52972 13.4861 5.45547 13.609 5.40519C13.7319 5.3549 13.8636 5.3296 13.9964 5.33076C14.1292 5.33191 14.2604 5.3595 14.3824 5.41191C14.5044 5.46431 14.6148 5.5405 14.707 5.63601Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_57_1325">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgArrowRight;
