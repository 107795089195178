import React from "react";
import FormInput from "@/components/FormInput";
import FormSelect from "@/components/FormSelect";
import { Control } from "react-hook-form";
import { UserType } from "@/types";
import { Box, MenuItem, Typography } from "@mui/material";
import FormFileUpload from "@/components/FormUploadInput";
import FormAutocompleteTags from "@/components/FormAutocompleteTags";
import { EmailPatternValidation } from "@/validators/emailPatternValidation.ts";

const items: {
  header: string;
  description: string;
  value: UserType["role"];
}[] = [
  {
    header: "Administrator",
    description: "Manage Accounts, settings, and security.",
    value: "administrator",
  },
  {
    header: "Contractor",
    description: "Check eligibility and input program data.",
    value: "contractor",
  },
  {
    header: "OEM",
    description: "Upload data and manage compliance.",
    value: "OEM" as any,
  },
];

const renderMenuItem = (value: string, header: string, description: string) => {
  return (
    <MenuItem key={value} sx={{ borderRadius: "12px" }} value={value}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
        <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
          {header}
        </Typography>
        <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
          {description}
        </Typography>
      </Box>
    </MenuItem>
  );
};

const FirstUserForm = ({
  control,
  isEdit = false,
}: {
  control: Control;
  isEdit?: boolean;
}) => {
  return (
    <>
      <FormInput
        name={"fullName"}
        control={control}
        label={"Full name"}
        placeholder={"John Smith"}
        controlProps={{ rules: { required: "Full name is required" } }}
      />
      <FormInput
        name={"email"}
        control={control}
        label={"Account email"}
        placeholder={"johnsmith@gmail.com"}
        controlProps={{
          rules: {
            required: "Email is required",
            ...EmailPatternValidation,
          },
        }}
      />
      <FormSelect
        name={"role"}
        control={control}
        label={"Account role"}
        placeholder={"Role"}
        disabled={isEdit}
        controlProps={{ rules: { required: "Role is required" } }}
      >
        {items?.map(({ value, header, description }) =>
          renderMenuItem(value, header, description),
        )}
      </FormSelect>
    </>
  );
};

export default FirstUserForm;
