import { create } from "zustand";

type State = {
  pageLoading: boolean;
};

type Actions = {
  setPageLoading: (loading: boolean) => void;
};

const useLoadingStore = create<State & Actions>((set) => ({
  pageLoading: false,
  setPageLoading: (loading) => set({ pageLoading: loading }),
}));

export default useLoadingStore;
