import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelectedRowsStore } from "@/store/selectedRowsStore.ts";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { useProjectsStore } from "@/store/projectsStore.ts";
import SVGDownloadFile from "@/assets/icons/SVGDownloadFile.tsx";
import { FileRecord, ProjectRecord } from "@/types";
import { formatDate } from "@/utils/formatDate.ts";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { ProjectsCSVHeaders } from "@/constants";
import { replaceUnderscoresWithSpaces } from "@/utils/replaceUnderscoreWithSpaces.ts";
import {
  getAllProjectsFilesToDownloadReq,
  getProjectsFilesToDownloadReq,
} from "@/api/fileApi";
import { useAdminStore } from "@/store/adminStore.ts";
import { downloadFile } from "@/utils/downloadFile.ts";
import dayjs from "dayjs";
import { DateRange } from "rsuite/DateRangePicker";

type Props = {
  search: string;
  filter: string[];
  dateRangeValue: DateRange | null;
  tableId: string;
};

const FilesSelectionInfo = ({
  search,
  filter,
  dateRangeValue,
  tableId,
}: Props) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const selectedRows = useSelectedRowsStore(
    (state) => state.tables[tableId]?.selectedRows,
  );
  const isSelectAll = useSelectedRowsStore(
    (state) => state.tables[tableId]?.isSelectAll,
  );
  const initializeTable = useSelectedRowsStore(
    (state) => state.initializeTable,
  );

  const { totalUploadedFiles } = useAdminStore(
    (state) => state.allUploadedFiles,
  );

  const selectedRowsNumber = useMemo(
    () => (isSelectAll ? totalUploadedFiles : selectedRows?.size || 0),
    [isSelectAll, selectedRows, totalUploadedFiles],
  );

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDownloadProjectsFiles = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    try {
      if (isSelectAll) {
        const filterParams = filter.join(",");
        const startDate = dateRangeValue?.length
          ? dayjs(dateRangeValue?.[0])?.format("YYYY-MM-DD")
          : "";
        const endDate = dateRangeValue?.length
          ? dayjs(dateRangeValue?.[1])?.format("YYYY-MM-DD")
          : "";

        downloadFile(
          `project/document/list/download?search=${search}&filter=${filterParams}&startDate=${startDate}&endDate=${endDate}`,
          "files",
        );
      } else {
        downloadFile(
          `project/document/list/download?ids=${[...selectedRows]?.join(",")}`,
          "files",
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsDownloading(false);
    }
  };

  // Initialize the table
  useEffect(() => {
    initializeTable(tableId);
  }, [initializeTable, tableId]);

  if (!selectedRowsNumber) return null;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "16px",
        alignItems: "center",
        justifyContent: "space-between",
        ...(downSm && { paddingLeft: "24px" }),
      }}
    >
      <Button
        onClick={handleDownloadProjectsFiles}
        startIcon={<SVGDownloadFile />}
        variant="outlined"
        disabled={isDownloading}
      >
        {isDownloading ? "Downloading..." : "Download"} {selectedRowsNumber}{" "}
        {selectedRowsNumber > 1 ? "Files" : "File"}
      </Button>
    </Box>
  );
};

export default FilesSelectionInfo;
