import React from "react";

const SvgBackArrow = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1144_3138)">
        <path
          d="M3.63605 11.2932C3.44858 11.4807 3.34326 11.735 3.34326 12.0002C3.34326 12.2653 3.44858 12.5197 3.63605 12.7072L9.29305 18.3642C9.48165 18.5463 9.73425 18.6471 9.99645 18.6449C10.2586 18.6426 10.5095 18.5374 10.6949 18.352C10.8803 18.1666 10.9854 17.9158 10.9877 17.6536C10.99 17.3914 10.8892 17.1388 10.707 16.9502L6.75705 13.0002H20C20.2653 13.0002 20.5196 12.8948 20.7072 12.7073C20.8947 12.5198 21 12.2654 21 12.0002C21 11.735 20.8947 11.4806 20.7072 11.2931C20.5196 11.1055 20.2653 11.0002 20 11.0002H6.75705L10.707 7.05018C10.8892 6.86158 10.99 6.60898 10.9877 6.34678C10.9854 6.08458 10.8803 5.83377 10.6949 5.64836C10.5095 5.46295 10.2586 5.35778 9.99645 5.35551C9.73425 5.35323 9.48165 5.45402 9.29305 5.63618L3.63605 11.2932Z"
          fill="#4E4B48"
        />
      </g>
      <defs>
        <clipPath id="clip0_1144_3138">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SvgBackArrow;
