export const getProjectDocumentationBadgeVariant = (status: string) => {
  if (status === "denied" || status === "deleted") {
    return { danger: true };
  }

  if (status === "approved") {
    return { success: true };
  }

  if (status === "ready_for_review") {
    return { neutral: true };
  }

  if (status === "pending") {
    return { yellow: true };
  }

  return {};
};
