import React, { useEffect, useState } from "react";
import Card from "@/components/Card";
import { Box, Typography } from "@mui/material";
import { StyledBadge } from "@/components/styled";
import SvgStatisticArrow from "@/assets/icons/SVGStatisticArrow.tsx";
import { getActiveUsersReq } from "@/api/adminApi";

const ActiveAccountsCard = () => {
  const [activeUsers, setActiveUsers] = useState();

  useEffect(() => {
    const fetchActiveUsers = async () => {
      try {
        const response = await getActiveUsersReq();
        setActiveUsers(response.data.activeUsersCount);
      } catch (e) {
        console.error(e);
      }
    };
    fetchActiveUsers();
  }, []);

  return (
    <Card>
      <Box sx={{ display: "flex", gap: "12px" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
          <Typography variant={"bodyHeader2"}>{activeUsers}</Typography>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "16px",
              color: (theme) => theme.palette.textGray3,
            }}
          >
            Active Accounts
          </Typography>
        </Box>
        <Box>
          <StyledBadge>
            <SvgStatisticArrow />
            +2,7%
          </StyledBadge>
        </Box>
      </Box>
    </Card>
  );
};

export default ActiveAccountsCard;
