import Modal from "@/components/Modal";
import { Box, Divider, Typography } from "@mui/material";
import React, { ReactNode, useEffect, useState } from "react";
import { StyledBadge, StyledDetailItem } from "@/components/styled";
import { TransactionType } from "@/types";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { formatCurrency } from "@/utils/formatCurrency.ts";
import { deleteNoteRequest, editNoteRequest } from "@/api/budgetWidgetApi";
import { useBudgetStore } from "@/store/budgetStore.ts";
import { formatDate } from "@/utils/formatDate.ts";
import EditableNote from "@/components/EditableNote";

type Props = {
  handleClose: () => void;
  open: boolean;
  transaction: TransactionType;
};

const ActionDetailsModal = ({ handleClose, open, transaction }: Props) => {
  const [currentTransaction, setCurrentTransaction] = useState(transaction);

  const budgetData = useBudgetStore((state) => state?.budgetData);
  const getTransactions = useBudgetStore((state) => state?.getTransactions);

  const onClose = () => {
    handleClose();
  };

  const isNote = !!currentTransaction?.note?.length;
  const isTransfer = currentTransaction?.type === "transfer";

  const dateAndTime = formatDate(currentTransaction?.createdAt);
  const updatedAt = formatDate(currentTransaction?.updatedAt);

  const formattedCurrency = formatCurrency(currentTransaction?.amount / 100);
  const balanceAfterTransaction = formatCurrency(
    currentTransaction?.total / 100,
  );

  const handleSaveNote = async (values: { note: string }) => {
    try {
      const response = await editNoteRequest(currentTransaction?.id, {
        note: values?.note,
      });
      setCurrentTransaction(response?.data?.updatedTransaction);
      getTransactions();
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeleteNote = async () => {
    try {
      const response = await deleteNoteRequest(currentTransaction?.id);
      setCurrentTransaction(response?.data?.deletedTransaction);
      getTransactions();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    setCurrentTransaction(transaction);
  }, [transaction]);

  return (
    <Modal open={open} onClose={onClose} title={"Action details"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "24px",
          marginTop: "32px",
        }}
      >
        <DetailItem field={"Date & Time"} value={dateAndTime} />
        {isTransfer && (
          <>
            <DetailItem
              field={"Transaction type"}
              value={
                <Box sx={{ display: "inline-flex" }}>
                  <StyledBadge neutral>
                    {capitalizeFirstLetter(currentTransaction?.type)}
                  </StyledBadge>
                </Box>
              }
            />
            <DetailItem
              field={"From"}
              value={capitalizeFirstLetter(currentTransaction?.from)}
            />
            <DetailItem
              field={"To"}
              value={capitalizeFirstLetter(currentTransaction?.to)}
            />
            <DetailItem field={"Amount of funds"} value={formattedCurrency} />
          </>
        )}
        {!isTransfer && (
          <>
            <DetailItem field={"Amount of funds"} value={formattedCurrency} />
            <DetailItem
              field={"Transaction type"}
              value={
                <Box sx={{ display: "inline-flex" }}>
                  <StyledBadge danger={currentTransaction?.type === "deduct"}>
                    {capitalizeFirstLetter(currentTransaction?.type)}
                  </StyledBadge>
                </Box>
              }
            />
          </>
        )}

        <DetailItem
          field={"Balance after transaction"}
          value={balanceAfterTransaction}
        />
        <Divider />
        <EditableNote
          dateAndTime={dateAndTime}
          updatedAt={updatedAt}
          handleDeleteNote={handleDeleteNote}
          handleSaveNote={handleSaveNote}
          currentNote={currentTransaction?.note}
          isNote={isNote}
        />
      </Box>
    </Modal>
  );
};

export default ActionDetailsModal;

export const DetailItem = ({
  field,
  value,
}: {
  field: string;
  value: ReactNode;
}) => {
  return (
    <StyledDetailItem>
      <Typography sx={{ fontWeight: 500, fontSize: "16px", color: "#6C7278" }}>
        {field}
      </Typography>
      <Typography sx={{ fontWeight: 500, fontSize: "16px", color: "#1A1C1E" }}>
        {value}
      </Typography>
    </StyledDetailItem>
  );
};
