import { UserType } from "@/types";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";

export const getRoleLabel = (role: UserType["role"]) => {
  if (role === "oem") {
    return "OEM";
  } else {
    return capitalizeFirstLetter(role ?? "");
  }
};
