import React from "react";
import FormFileDropzone from "@/components/FormFileDropzone";
import { Control, ControllerProps } from "react-hook-form";

const UploadFileStep = ({
  control,
  file,
}: {
  control: Control<any, any>;
  file: File;
}) => {
  return <FormFileDropzone fileInfo={file} control={control} name={"file"} />;
};

export default UploadFileStep;
