import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import FormInput from "@/components/FormInput";
import { useForm } from "react-hook-form";
import { loginReq, sendResetPasswordLinkReq } from "@/api/authApi";
import { useNavigate } from "react-router-dom";
import { useAuthStore } from "@/store/authStore.ts";
import Card from "@/components/Card";

const GetLinkForm = () => {
  const theme = useTheme();
  const matchesDownLg = useMediaQuery(theme.breakpoints.down("lg"));

  const { handleSubmit, control, setError, watch } = useForm<{
    email: string;
    password: string;
  }>();
  const navigate = useNavigate();
  const email = watch("email");

  const setResetPasswordData = useAuthStore(
    (state) => state.setResetPasswordData,
  );
  const handleSubmitForm = async (values: { email: string }) => {
    try {
      await sendResetPasswordLinkReq(values);
      setResetPasswordData({ token: null, ...values });
      navigate("/reset-link");
    } catch (e: any) {
      const errorMessage = e?.response?.data?.error;
      setError("email", {
        message: errorMessage ? errorMessage : e?.message,
      });
    }
  };

  return (
    <Card
      sx={{ ...(!matchesDownLg && { maxWidth: "570px" }), width: "100%" }}
      title={<Typography variant={"cardHeader"}>Forgot password?</Typography>}
    >
      <Typography
        sx={{
          fontSize: "16px",
          color: "#000",
          fontWeight: 400,
          marginTop: "8px",
        }}
      >
        Don't worry. We can fix this!
      </Typography>
      <Box
        sx={{
          marginTop: "40px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <FormInput
          name={"email"}
          label={"Email"}
          placeholder={"Enter your email"}
          control={control}
        />
        <Button
          disabled={!email?.length}
          sx={{ width: "100%", marginTop: "24px" }}
          onClick={handleSubmit(handleSubmitForm)}
          variant={"contained"}
        >
          Get link
        </Button>
      </Box>
    </Card>
  );
};

export default GetLinkForm;
