import { useCallback } from "react";
import useLoadingStore from "@/store/loadingStore.ts";

type FetchFunction = (...args: any[]) => void;

const useFetchDataWithLoading = () => {
  const setPageLoading = useLoadingStore((state) => state.setPageLoading);

  return useCallback(
    async (fetchFunction: FetchFunction, fetchParams: any[]) => {
      setPageLoading(true);
      try {
        await fetchFunction(...fetchParams);
      } catch (error) {
        console.error(error);
      } finally {
        setPageLoading(false);
      }
    },
    [setPageLoading],
  );
};

export default useFetchDataWithLoading;
