import React from "react";
import { Box } from "@mui/material";
import { getFileIcon } from "@/utils/getFileIcon.tsx";
import { downloadFile } from "@/utils/downloadFile.ts";

const FileTableItem = ({
  url,
  fileName,
}: {
  url: string;
  fileName: string;
}) => {
  return (
    <Box
      onClick={() => downloadFile(url, fileName)}
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "8px",
        cursor: "pointer",
      }}
    >
      {getFileIcon(fileName)}
      {fileName}
    </Box>
  );
};

export default FileTableItem;
