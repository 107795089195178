import React, { SVGProps } from "react";

const SvgInfo = (props: SVGProps<any>) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.9502 2.5C12.6627 2.5 15.7002 5.5375 15.7002 9.25C15.7002 12.9625 12.6627 16 8.9502 16C5.2377 16 2.2002 12.9625 2.2002 9.25C2.2002 5.5375 5.2377 2.5 8.9502 2.5Z"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 12V8.25"
        stroke="#4E4B48"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 6H9.00674"
        stroke="#4E4B48"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgInfo;
