import React from "react";

const SvgEmail = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7.3V7.3C3 7.9 3.3 8.4 3.8 8.8L9.8 12.9C11.2 13.8 12.9 13.8 14.3 12.9L20.3 8.9C20.7 8.4 21 7.9 21 7.3V7.3C21 6.3 20.2 5.5 19.2 5.5H4.8C3.8 5.5 3 6.3 3 7.3Z"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3 7.5V17.5C3 18.6 3.9 19.5 5 19.5H19C20.1 19.5 21 18.6 21 17.5V7.5"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.58789 18.912L9.68189 12.818"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3604 12.86L20.4124 18.912"
        stroke="#888684"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgEmail;
