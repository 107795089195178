import { Box } from "@mui/material";

const SvgSuccessModal = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "99px",
        boxShadow: "0px 24px 34px 10px #00A5BD4D",
        overflow: "hidden",
      }}
    >
      <svg
        width="92"
        height="92"
        viewBox="24 0 92 92"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_494_115760)">
          <rect
            x="24"
            width="91"
            height="92"
            rx="45.5"
            fill="#00A5BD"
            shapeRendering="crispEdges"
          />
          <g filter="url(#filter1_d_494_115760)">
            <path
              d="M69.5 24.3333C57.7912 24.3333 48.25 34.0616 48.25 46C48.25 57.9383 57.7912 67.6666 69.5 67.6666C81.2088 67.6666 90.75 57.9383 90.75 46C90.75 34.0616 81.2088 24.3333 69.5 24.3333ZM79.6575 41.0166L67.6087 53.3016C67.3112 53.605 66.9075 53.7783 66.4825 53.7783C66.0575 53.7783 65.6537 53.605 65.3563 53.3016L59.3425 47.17C58.7262 46.5416 58.7262 45.5016 59.3425 44.8733C59.9587 44.245 60.9787 44.245 61.595 44.8733L66.4825 49.8566L77.405 38.72C78.0212 38.0916 79.0413 38.0916 79.6575 38.72C80.2738 39.3483 80.2738 40.3666 79.6575 41.0166Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_494_115760"
            x="0"
            y="0"
            width="139"
            height="140"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feMorphology
              radius="10"
              operator="erode"
              in="SourceAlpha"
              result="effect1_dropShadow_494_115760"
            />
            <feOffset dy="24" />
            <feGaussianBlur stdDeviation="17" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0.647059 0 0 0 0 0.741176 0 0 0 0.3 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_494_115760"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_494_115760"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_494_115760"
            x="20"
            y="14"
            width="99"
            height="100"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="18" />
            <feGaussianBlur stdDeviation="12" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.24 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_494_115760"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_494_115760"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </Box>
  );
};

export default SvgSuccessModal;
