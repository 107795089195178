import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Divider, styled, Typography } from "@mui/material";
import SvgSentLink from "@/assets/icons/SVGSentLink.tsx";
import { useAuthStore } from "@/store/authStore.ts";
import { useNavigate } from "react-router-dom";
import { StyledTypographyLink } from "@/components/styled";
import { sendResetPasswordLinkReq } from "@/api/authApi";
import Snackbar from "@mui/material/Snackbar";

const BoxLayout = styled(Box)(() => ({
  display: "flex",
  width: "100%",
  minHeight: "100vh",
  alignItems: "center",
  flexDirection: "column",
  justifyContent: "center",
  backgroundColor: "#F9F9F9",
  padding: "0 26px",
}));

const StyledTypography = styled(Typography)(() => ({
  color: "#21201E",
  fontWeight: 500,
  fontSize: "16px",
}));

const TypographyTitle = styled(Typography)(({ theme }) => ({
  color: "#21201E",
  fontWeight: 600,
  fontSize: "32px",
  marginTop: "36px",
  [theme.breakpoints.down("md")]: {
    fontSize: "28px",
    fontWeight: 600,
  },
}));

const TypographySubtitle = styled(Box)(({ theme }) => ({
  color: "#4E4B48",
  fontWeight: 400,
  fontSize: "18px",
  marginTop: "16px",
  textAlign: "center",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 500,
  },
}));

const TypographyBold = styled(Box)(({ theme }) => ({
  fontWeight: 500,
  color: "#4E4B48",
  fontSize: "18px",
  marginTop: "16px",
  display: "inline",
  [theme.breakpoints.down("md")]: {
    fontSize: "16px",
    fontWeight: 600,
  },
}));

const ResetLinkPage = () => {
  const resetPasswordData = useAuthStore((state) => state.resetPasswordData);
  const setResetPasswordData = useAuthStore(
    (state) => state.setResetPasswordData,
  );
  const [hasNotification, setHasNotification] = useState(false);

  const handleClose = () => setHasNotification(false);

  const navigate = useNavigate();
  useEffect(() => {
    if (!resetPasswordData?.email) navigate("/password-reset");
  }, [resetPasswordData?.email]);

  const handleResendLink = async () => {
    if (resetPasswordData?.email) {
      const values = { email: resetPasswordData.email };
      try {
        await sendResetPasswordLinkReq(values);
        setResetPasswordData({ token: null, ...values });
        setHasNotification(true);
        navigate("/reset-link");
      } catch (e: any) {
        console.error(e);
      }
    }
  };

  const handleNavigateToLogin = () => {
    setResetPasswordData({ token: null, email: null });
    navigate("/login");
  };

  return (
    <BoxLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: " column",
          justifyContent: "center",
          alignItems: "center",
          maxWidth: "700px",
          width: "100%",
        }}
      >
        <SvgSentLink />
        <TypographyTitle>Link sent to your email</TypographyTitle>
        <TypographySubtitle>
          We have sent a verification link to your email{" "}
          <TypographyBold>{resetPasswordData?.email}</TypographyBold>. The link
          will expire in 1 hour.
        </TypographySubtitle>
        <Button
          variant={"contained"}
          sx={{ marginTop: "36px", marginBottom: "24px", width: "100%" }}
        >
          Open email app
        </Button>
        <Box sx={{ display: "flex", gap: "16px" }}>
          <StyledTypography>Didn’t receive the email?</StyledTypography>
          <StyledTypographyLink
            sx={{ fontSize: "16px" }}
            onClick={handleResendLink}
          >
            Resend
          </StyledTypographyLink>
        </Box>
        <Divider
          sx={{
            width: "100%",
            borderWidth: "0.5px",
            borderColor: "#AEACAB",
            margin: "36px 0",
          }}
        />
        <Box sx={{ display: "flex", gap: "16px" }}>
          <StyledTypography>Remember password?</StyledTypography>
          <StyledTypographyLink
            sx={{ fontSize: "16px" }}
            onClick={handleNavigateToLogin}
          >
            Log in
          </StyledTypographyLink>
        </Box>
      </Box>
      {hasNotification && (
        <Snackbar
          open={hasNotification}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={"success"}>
            Reset link has been successfully resent to the{" "}
            {resetPasswordData?.email}
          </Alert>
        </Snackbar>
      )}
    </BoxLayout>
  );
};

export default ResetLinkPage;
