import React, { SVGProps } from "react";

const SvgUploadFile = (props: SVGProps<any>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.82739 16.0018C3.3668 14.2928 2.36827 11.1393 3.39672 8.32545C4.42516 5.51161 7.22186 3.74528 10.2046 4.02573C13.1873 4.30619 15.6058 6.56289 16.0917 9.51912C16.2317 9.50916 16.3618 9.49911 16.5018 9.49911C18.6942 9.49834 20.568 11.0775 20.9389 13.2382C21.3097 15.3989 20.0696 17.5125 18.0025 18.2428"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.0013 15.0017L12 12.0005L8.99878 15.0017"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 12.0005V20.0036"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgUploadFile;
