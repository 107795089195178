import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SvgArrowRight from "@/assets/icons/SVGArrowRight.tsx";
import SvgActivityDeducted from "@/assets/icons/SVGActivityDeducted.tsx";
import SvgActivityAdded from "@/assets/icons/SVGActivityAdded.tsx";
import ActionDetailsModal from "@/components/TotalBalance/ActionDetailsModal.tsx";
import { useEffect, useState } from "react";
import { useBudgetStore } from "@/store/budgetStore.ts";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import { TransactionType } from "@/types";
import { getTransactionLabel } from "@/utils/getTransactionLabel.ts";
import { getTransactionAmount } from "@/utils/getTransactionAmount.ts";
import { formatRelativeDate } from "@/utils/formatRelativeDate.ts";

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const BalanceActivity = () => {
  const theme = useTheme();
  const downLg = useMediaQuery(theme.breakpoints.down("lg"));

  const getTransactions = useBudgetStore((state) => state.getTransactions);
  const budgetData = useBudgetStore((state) => state.budgetData);
  const transactions = useBudgetStore((state) => state.transactions);

  const [transactionDetails, setTransactionDetails] = useState<TransactionType>(
    {} as TransactionType,
  );

  const handleDetailOpenModal = (transaction: TransactionType) => {
    setTransactionDetails(transaction);
  };

  useEffect(() => {
    if (budgetData?.id) {
      getTransactions();
    }
  }, [budgetData?.id]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", gap: "8px" }}
      >
        <Typography
          variant="bodyMedium"
          sx={{ ...(downLg && { fontSize: "16px" }) }}
        >
          Recent Activity
        </Typography>
        {/*HIDDEN FOR FURTHER IMPLEMENTATION =>*/}
        {/*<Button variant={"text"} sx={{ color: "#54A3DE", gap: "4px" }}>*/}
        {/*  <Typography sx={{ color: "#54A3DE", fontSize: "14px" }}>*/}
        {/*    See more*/}
        {/*  </Typography>*/}
        {/*  <SvgArrowRight />*/}
        {/*</Button>*/}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "auto",
          paddingRight: "20px",
          paddingBottom: "84px",
        }}
      >
        {Object.entries(transactions).map(([date, items]) => (
          <ActivityItem
            handleDetailOpenModal={handleDetailOpenModal}
            key={date}
            day={formatRelativeDate(date)}
            items={items}
          />
        ))}
      </Box>
      <ActionDetailsModal
        open={!!transactionDetails?.id}
        transaction={transactionDetails}
        handleClose={() => setTransactionDetails({} as TransactionType)}
      />
    </Box>
  );
};

export default BalanceActivity;

const ActivityItem = ({
  day,
  items,
  handleDetailOpenModal,
}: {
  day: string;
  items: TransactionType[];
  handleDetailOpenModal: (transaction: TransactionType) => void;
}) => {
  return (
    <Box>
      <Typography
        variant={"subtitleRegular"}
        sx={{
          display: "flex",
          textAlign: "center",
          marginY: "12px",
          justifyContent: "center",
        }}
      >
        {day}
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
        {items?.map((el) => (
          <Box
            key={el?.id}
            onClick={() => handleDetailOpenModal(el)}
            sx={{
              cursor: "pointer",
              display: "flex",
              justifyContent: "space-between",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "12px",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "48px",
                  height: "48px",
                  borderRadius: "99px",
                  backgroundColor: "#EDEDED",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {el?.type === "deduct" ? (
                  <SvgActivityDeducted />
                ) : (
                  <SvgActivityAdded />
                )}
              </Box>
              <Typography
                variant={"bodyRegular"}
                sx={{
                  textOverflow: "ellipsis",
                  display: "block",
                  overflow: "hidden",
                }}
              >
                {getTransactionLabel(el?.type)}
              </Typography>
            </Box>
            <Typography
              sx={{
                color: el?.type === "deduct" ? "#8C2A00" : "#005B68",
                fontSize: "16px",
                fontWeight: 500,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getTransactionAmount(el?.amount, el?.type)}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
