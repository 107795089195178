import React, { useState } from "react";
import { TablePaginationActionsProps } from "@mui/material/TablePagination/TablePaginationActions";
import {
  Box,
  Button,
  MenuItem,
  Select,
  styled,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVGPagArrowLeft from "@/assets/icons/SVGPagArrowLeft.tsx";
import SVGPagArrowRight from "@/assets/icons/SVGPagArrowRight.tsx";
import { StyledInput } from "@/components/styled";

const StyledButton = styled(Button)(() => ({
  padding: "10px",
  minWidth: "40px",
  height: "40px",
  minHeight: "initial",
  textOverflow: "ellipsis",
  overflow: "hidden",
}));

const StyledDots = styled(Typography)(() => ({
  padding: "10px",
  width: "40px",
  height: "40px",
  display: "flex",
}));

const StyledDivider = styled(Box)(() => ({
  width: "1px",
  backgroundColor: "#C8C7C6",
  padding: "1px 0 0 0",
  margin: "0 30px",
}));

const WrapperPaginationBox = styled(Box)(({ theme }) => ({
  display: "flex",
  width: "100%",
  marginTop: "24px",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    justifyContent: "center",
    gap: "16px",
  },
}));
const PaginationButtonsBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    justifyContent: "space-between",
    padding: "0 24px",
  },
}));

const PerPageBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "8px",
  alignItems: "center",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    justifyContent: "center",
    gap: "40px",
  },
}));

const TablePaginationActions = ({
  onRowsPerPageChange,
  rowsPerPageOptions,
  ...props
}: {
  onRowsPerPageChange: (e: any) => void;
  rowsPerPageOptions: number[];
} & TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();
  const downMd = useMediaQuery(theme.breakpoints.down("md"));

  const [inputPage, setInputPage] = useState<number | null>(null);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value ? Number(event.target.value) - 1 : null;
    setInputPage(value);
  };

  const handleGoButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (inputPage !== null && inputPage >= 0 && inputPage < totalPages) {
      onPageChange(event, inputPage);
    }
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, page + 1);
  };

  const handlePageNumberClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => {
    onPageChange(event, newPage);
  };

  const totalPages = Math.ceil(count / rowsPerPage);

  // Determine range of pages to display
  const pageNumbersToShow = [];
  const maxPageButtons = 3; // Maximum number of page buttons to display

  // Logic to calculate range of page numbers to display
  let startPage = Math.max(0, page - Math.floor(maxPageButtons / 2));
  let endPage = startPage + maxPageButtons - 1;

  if (endPage >= totalPages) {
    endPage = totalPages - 1;
    startPage = Math.max(0, endPage - maxPageButtons + 1);
  }

  for (let i = startPage; i <= endPage; i++) {
    pageNumbersToShow.push(i);
  }

  return (
    <WrapperPaginationBox>
      <PaginationButtonsBox>
        <StyledButton
          sx={{ marginRight: downMd ? "4px" : "24px", width: "40px" }}
          disabled={page === 0}
          onClick={handleBackButtonClick}
          variant={"outlined"}
        >
          <SVGPagArrowLeft />
        </StyledButton>

        {startPage > 0 && (
          <StyledButton
            onClick={(e) => handlePageNumberClick(e, 0)}
            variant="text"
          >
            1
          </StyledButton>
        )}

        {startPage > 1 && <StyledDots>...</StyledDots>}

        {pageNumbersToShow.map((pageNumber) => (
          <StyledButton
            key={pageNumber}
            onClick={(e) => handlePageNumberClick(e, pageNumber)}
            variant={pageNumber === page ? "contained" : "text"}
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                color: "inherit",
                overflow: "hidden",
              }}
            >
              {pageNumber + 1}
            </Typography>
          </StyledButton>
        ))}

        {endPage < totalPages - 2 && <StyledDots>...</StyledDots>}

        {endPage < totalPages - 1 && (
          <StyledButton
            onClick={(e) => handlePageNumberClick(e, totalPages - 1)}
            variant="text"
          >
            <Typography
              sx={{
                textOverflow: "ellipsis",
                color: "inherit",
                overflow: "hidden",
              }}
            >
              {totalPages}
            </Typography>
          </StyledButton>
        )}

        <StyledButton
          sx={{ marginLeft: downMd ? "4px" : "24px", width: "40px" }}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          onClick={handleNextButtonClick}
          variant={"outlined"}
        >
          <SVGPagArrowRight />
        </StyledButton>
      </PaginationButtonsBox>
      {!downMd && (
        <>
          <StyledDivider />

          <Box
            sx={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontWeight: 600, fontSize: "14px", whiteSpace: "nowrap" }}
            >
              Go to page:
            </Typography>
            <Box sx={{ width: "88px", fontSize: "14px" }}>
              <StyledInput
                sx={{
                  width: "100%",
                }}
                placeholder={"1"}
                type={"number"}
                value={inputPage !== null ? inputPage + 1 : ""}
                onChange={handleInputChange}
                inputProps={{ min: 1, max: totalPages }}
              />
            </Box>
            <Button variant={"outlined"} onClick={handleGoButtonClick}>
              Go
            </Button>
          </Box>
          <StyledDivider />
        </>
      )}
      <Box
        sx={{
          display: "flex",
          gap: "8px",
          alignItems: "center",
          width: "100%",
          ...(downMd && {
            justifyContent: "space-between",
          }),
        }}
      >
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: "14px",
            whiteSpace: "nowrap",
            ...(downMd && {
              paddingLeft: "24px",
            }),
          }}
        >
          Results per page:
        </Typography>
        <Box sx={{ maxWidth: "88px", width: "100%", fontSize: "14px" }}>
          <Select
            value={rowsPerPage}
            onChange={onRowsPerPageChange}
            displayEmpty
            input={<StyledInput />}
            inputProps={{ "aria-label": "Without label" }}
          >
            {rowsPerPageOptions?.map((el) => (
              <MenuItem key={el} value={el}>
                {el}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
    </WrapperPaginationBox>
  );
};

export default TablePaginationActions;
