import React, { useEffect, useState } from "react";
import { FileRecord, TemplateRowType } from "@/types";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { StyledIconButton } from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import UploadFileStep from "@/components/UploadFilesModal/UploadFileStep.tsx";
import FileInfoStep from "@/components/UploadFilesModal/FileInfoStep.tsx";
import Modal from "@/components/Modal";
import { useForm } from "react-hook-form";
import { UploadFileForm } from "@/components/UploadProjectFile";
import { updateTemplateReq } from "@/api/templateApi";
import FileTypeForm from "@/components/UploadProjectFile/FileTypeForm.tsx";
import { FILE_TYPES } from "@/constants";
import FileComponent from "@/components/FileComponent";

const UpdateTemplateModal = ({
  template,
  getFiles,
}: {
  template: TemplateRowType;
  getFiles: () => void;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);

  const {
    handleSubmit,
    reset,
    control,
    watch,
    setValue,
    formState: { isValid },
  } = useForm<UploadFileForm>({
    mode: "onChange",
  });

  useEffect(() => {
    if (template) {
      if (FILE_TYPES.some((el) => el === template.fileType)) {
        setValue("type", template.fileType);
      } else {
        setValue("otherFileType", template.fileType);
        setValue("type", "Other");
      }
    }
  }, [template, setValue]);

  const fileType = watch("type");
  const otherFileType = watch("otherFileType");

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = async (data: any) => {
    try {
      if (data.type === "Other") {
        await updateTemplateReq(template?.id, { type: data.otherFileType });
      } else {
        await updateTemplateReq(template?.id, { type: data.type });
      }
      await getFiles();
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const isNextStepDisabled = () => {
    if (fileType === "Other") {
      return otherFileType === template?.fileType || !otherFileType;
    } else {
      return fileType === template?.fileType;
    }
  };
  return (
    <>
      <Box>
        <StyledIconButton
          sx={{ ...(downSm && { width: "100%" }) }}
          variant={downSm ? "outlined" : "text"}
          onClick={() => setOpen(true)}
        >
          {!downSm && <SVGUserEdit />}
          {downSm && "Edit"}
        </StyledIconButton>
        <Modal open={open} onClose={handleClose} title={"Edit template"}>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              overflow: "auto",
              marginTop: "24px",
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <FileTypeForm
              fileInfo={{
                name: template?.fileName,
                size: Number(template?.fileSize),
              }}
              control={control}
              fileType={fileType}
            />
            <Box
              sx={{
                marginTop: "32px",
                display: "flex",
                gap: "8px",
                width: "100%",
              }}
            >
              <Button
                variant={"outlined"}
                sx={{ width: "100%" }}
                onClick={handleClose}
              >
                Back
              </Button>
              <Button
                variant={"contained"}
                sx={{ width: "100%" }}
                type={"submit"}
                disabled={!isValid || isNextStepDisabled()}
              >
                Save
              </Button>
            </Box>
          </form>
        </Modal>
      </Box>
    </>
  );
};

export default UpdateTemplateModal;
