import React, { useEffect, useState } from "react";
import { UserType } from "@/types";
import { useAdminStore } from "@/store/adminStore.ts";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { createUserReq, getUserReq, updateUserReq } from "@/api/adminApi";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import SVGPlus from "@/assets/icons/SVGPlus.tsx";
import Modal from "@/components/Modal";
import FirstUserForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/FirstUserForm.tsx";
import SecondContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondContractorForm.tsx";
import ThirdContractorForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/ThirdContractorForm.tsx";
import SecondOemForm from "@/pages/admin-pages/UserManagementPage/components/CreateUserModal/SecondOemForm.tsx";
import {
  BoxAction,
  StyledIconButton,
  StyledRightActionButton,
} from "@/components/styled";
import SVGUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import { validateEmail } from "@/utils/validateEmail.ts";
import SVGContent from "@/assets/icons/SVGContent.tsx";
import SvgUserEdit from "@/assets/icons/SVGUserEdit.tsx";
import UpdateUserModal from "@/components/UpdateUserModal";

type Props = {
  userId: UserType["id"];
  getFilesReq?: () => void;
  iconOnly?: boolean;
};

const excludedUserUpdateKeys = [
  "batteryModels",
  "battery",
  "certification",
  "license",
  "communitiesOfConsern",
];

const UpdateUserAdminWrapper = ({ iconOnly, userId, getFilesReq }: Props) => {
  const [open, setOpen] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [user, setUser] = useState({} as UserType);
  const updateUsers = useAdminStore((state) => state.updateUsers);
  const getUser = useAdminStore((state) => state.getUser);

  useEffect(() => {
    if (userId && open) {
      const fetchUser = async () => {
        try {
          const response = await getUserReq(userId);
          const userData = response?.data?.userData as UserType;
          const { files, ...userFields } = userData;
          const transformedUserData = {
            ...userFields,
            batteries: userData?.battery?.map((el) => ({
              batteryModel: el?.batteryModel,
              inverterModels: el?.inverterModels,
              batteryTypes: el?.batteryType?.split(", "),
              batteryLocations: el?.batteryLocations,
              batteryCapacity: el?.batteryCapacity,
            })),
            batteryModels: userData?.battery?.map((el) => el?.batteryModel),
            manufactoringBrand: userData?.manufactoringBrand || "",
            certification:
              userData?.files?.find((el) => el?.type === "certification") ||
              null,
            license:
              userData?.files?.find((el) => el?.type === "license") || null,
          };
          setUser(transformedUserData);
        } catch (e) {
          console.error(e);
        }
      };
      fetchUser();
    }
  }, [userId, open]);

  const methods = useForm<Partial<UserType>>({
    mode: "onChange",
  });
  const {
    handleSubmit,
    reset,
    control,
    watch,
    getValues,
    formState: { isValid },
  } = methods;

  useEffect(() => {
    if (user) {
      reset(user);
    }
  }, [user, reset]);

  const selectedRole = useWatch({ control, name: "role" });

  const handleClose = () => {
    setOpen(false);
    reset(user); // Reset to initial values on close
  };

  const handleFormChange = () => {
    const formData = getValues();
    const hasAnyChanges = Object.keys(formData).some(
      (key) =>
        formData[key as keyof UserType] !== user?.[key as keyof UserType],
    );
    setHasChanges(hasAnyChanges);
  };

  useEffect(() => {
    const subscription = watch(handleFormChange);
    return () => subscription.unsubscribe();
  }, [watch, user]);

  const onSubmit = async (data: Partial<UserType>) => {
    try {
      const changedValues: Partial<UserType> = {};
      Object.keys(data).forEach((key) => {
        const initialValue = user?.[key as keyof UserType];
        const currentValue = data[key as keyof UserType];
        if (initialValue !== currentValue) {
          changedValues[key as keyof UserType] = currentValue as any;
        }
      });

      if (changedValues?.email) {
        const emailError = await validateEmail(changedValues.email);
        if (emailError !== true) {
          methods.setError("email", { message: emailError });
          return;
        }
      }

      if (Object.keys(changedValues).length > 0) {
        const formData = new FormData();
        Object.keys(changedValues).forEach((key) => {
          const value = data[key as keyof Partial<UserType>];
          if (value !== undefined) {
            if (value instanceof File) {
              formData.append(key, value);
            } else {
              if (key === "batteries" && Array.isArray(value)) {
                const transformedBatteries = value?.map((el: any) => ({
                  ...el,
                  batteryTypes: el?.batteryTypes?.join(", "),
                }));
                formData.append(key, JSON.stringify(transformedBatteries));
              } else {
                // exclude keys
                if (!excludedUserUpdateKeys.includes(key)) {
                  formData.append(key, value as string);
                }
              }
            }
          }
        });
        await updateUserReq(userId, formData);
        // update state users for user table =>
        updateUsers(userId, changedValues);
        // get updated user =>
        getUser(userId);
        if (getFilesReq) {
          getFilesReq();
        }
      }
      handleClose();
    } catch (e) {
      console.error(e);
    }
  };

  const isNextStepDisabled = () => {
    if (!getValues("fullName") || !getValues("email") || !getValues("role"))
      return true;
    if (
      selectedRole === "contractor" &&
      (!getValues("companyName") || !getValues("businessLicenseNumber"))
    )
      return true;
    return false;
  };
  return (
    <UpdateUserModal
      user={user}
      control={control}
      methods={methods}
      handleClose={handleClose}
      open={open}
      setOpen={setOpen}
      onSubmit={handleSubmit(onSubmit)}
      selectedRole={selectedRole}
      isDisabled={!isValid || isNextStepDisabled() || !hasChanges}
      iconOnly={iconOnly}
    />
  );
};

export default UpdateUserAdminWrapper;
