import React from "react";

const SvgUserEdit = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.707 4.70676L19.293 3.29276C18.902 2.90176 18.269 2.90176 17.879 3.29276L7.293 13.8788C7.105 14.0658 7 14.3208 7 14.5858V16.9998H9.414C9.679 16.9998 9.934 16.8948 10.121 16.7068L20.707 6.12076C21.098 5.73076 21.098 5.09776 20.707 4.70676V4.70676Z"
        stroke="#0076CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9098 7.90984L16.0898 5.08984"
        stroke="#0076CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 11V19C21 20.105 20.105 21 19 21H5C3.895 21 3 20.105 3 19V5C3 3.895 3.895 3 5 3H13"
        stroke="#0076CE"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgUserEdit;
