import React, { useEffect, useState } from "react";
import Modal from "@/components/Modal";
import { BoxAction, StyledBadge, StyledDivider } from "@/components/styled";
import { Box, Button, useMediaQuery, useTheme } from "@mui/material";
import { DetailItem } from "@/components/TotalBalance/ActionDetailsModal.tsx";
import { getBadgeVariant } from "@/utils/getBadgeVariant.ts";
import SVGGoTo from "@/assets/icons/SVGGoTo.tsx";
import { LogType } from "@/types";
import { getLogStatusBadgeVariant } from "@/utils/getLogStatusBadgeVariant.ts";
import { getLogStatusBadgeLabel } from "@/utils/getLogStatusBadgeLabel.tsx";
import { useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { getGeneratedPDFLogReq, updateLogNoteReq } from "@/api/adminApi";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import { LogsCSVHeaders } from "@/constants";
import EditableNote from "@/components/EditableNote";
import { editNoteRequest } from "@/api/budgetWidgetApi";
import SVGContent from "@/assets/icons/SVGContent.tsx";

const LogDetailModal = ({
  log,
  getLogs,
}: {
  log: Partial<LogType>;
  getLogs: () => void;
}) => {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  const [open, setOpen] = useState(false);
  const [currentLog, setCurrentLog] = useState(log);
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteNote = async () => {
    try {
      if (log?.id) {
        const response = await updateLogNoteReq(log?.id, {
          note: "",
        });
        setCurrentLog(response?.data?.log);
        getLogs();
      }
    } catch (e) {
      console.error(e);
    }
  };
  const handleSaveNote = async (values: { note: string }) => {
    try {
      if (log?.id) {
        const response = await updateLogNoteReq(log?.id, {
          note: values?.note,
        });
        setCurrentLog(response?.data?.updatedLog);
        getLogs();
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant={downSm ? "outlined" : "text"}
      >
        {downSm ? "Generate report" : <SVGContent />}
      </Button>
      <Modal open={open} onClose={handleClose} title={`Log ${log?.id}`}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
            marginTop: "24px",
          }}
        >
          <DetailItem
            field={"Account Name"}
            value={
              <Box
                onClick={() => {
                  if (log?.userId) navigate(`/user-profile/${log?.userId}`);
                }}
                sx={{ display: "flex", gap: "8px", cursor: "pointer" }}
              >
                <>{log?.accountName ?? ""}</>
                {log?.userId && <SVGGoTo />}
              </Box>
            }
          />
          <DetailItem field={"Account email"} value={log?.email ?? ""} />
          <DetailItem
            field={"Account role"}
            value={
              <StyledBadge {...getBadgeVariant(log?.role ?? null, null)}>
                {capitalizeFirstLetter(log?.role ?? "")}
              </StyledBadge>
            }
          />
          <StyledDivider />
          <DetailItem field={"IP Address"} value={log?.ipAddress ?? ""} />
          <DetailItem field={"Location"} value={log?.location ?? ""} />
          <DetailItem field={"Time"} value={log?.timestamp ?? ""} />
          <StyledDivider />
          <DetailItem
            field={"Result"}
            value={
              <StyledBadge
                sx={{ display: "inline", textWrap: "nowrap" }}
                {...getLogStatusBadgeVariant(log?.status ?? "")}
              >
                {getLogStatusBadgeLabel(log?.status ?? "")}
              </StyledBadge>
            }
          />
          <DetailItem field={"Event log"} value={log?.message ?? ""} />
          <StyledDivider />
          <EditableNote
            handleDeleteNote={handleDeleteNote}
            handleSaveNote={handleSaveNote}
            currentNote={currentLog?.note ?? ""}
            isNote={!!currentLog?.note}
          />
          <Box
            sx={{
              marginTop: "32px",
              display: "flex",
              gap: "8px",
              width: "100%",
            }}
          >
            <Button
              variant={"outlined"}
              sx={{ width: "100%" }}
              onClick={() => handleClose()}
            >
              Cancel
            </Button>
            <Button
              onClick={() =>
                downloadCSV(
                  transformLogToCSV([log as LogType]),
                  `log_${log?.id}`,
                  LogsCSVHeaders,
                )
              }
              variant={"contained"}
              sx={{ width: "100%" }}
              type={"submit"}
            >
              Download report
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default LogDetailModal;
