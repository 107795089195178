import React from "react";
import { DateRange } from "rsuite/DateRangePicker";
import { BoxActionsWrapper, BoxSearchWrapper } from "@/components/styled";
import { Box, Button } from "@mui/material";
import SearchInput from "@/components/SearchInput";
import TableSort from "@/components/TableSort";
import {
  COMMON_SORT_ITEMS,
  FilesManagementCSVHeaders,
  ROLES_FILTER_ITEMS,
  UPLOADED_FILES_FILTER_ITEMS,
} from "@/constants";
import TableDateRange from "@/components/TableDateRange";
import { downloadCSV } from "@/utils/downloadCSV.ts";
import { transformLogToCSV } from "@/utils/transformLogToCSV.ts";
import SVGDownload from "@/assets/icons/SVGDownload.tsx";
import UploadFilesModal from "@/components/UploadFilesModal";
import TableFilter from "@/components/TableFilter";

type Props = {
  onSearch: (value: string) => void;
  onSort: (value: string) => void;
  onDateRange: (value: DateRange | null) => void;
  onFilter: (value: string[]) => void;
};

const UploadTableFilesActions = ({
  onSearch,
  onSort,
  onDateRange,
  onFilter,
}: Props) => {
  return (
    <BoxActionsWrapper>
      <BoxSearchWrapper>
        <Box sx={{ maxWidth: "300px", width: "100%" }}>
          <SearchInput onDebounce={onSearch} />
        </Box>
        <TableFilter
          placeholder={"Statuses"}
          items={UPLOADED_FILES_FILTER_ITEMS}
          setFilterValues={onFilter}
        />
        <TableSort
          placeholder={"Sort"}
          items={COMMON_SORT_ITEMS}
          setSortValue={onSort}
        />
        <TableDateRange setDateRangeValue={onDateRange} />
      </BoxSearchWrapper>
    </BoxActionsWrapper>
  );
};

export default UploadTableFilesActions;
