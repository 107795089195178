import React from "react";
import Layout from "@/components/Layout";
import { Box, Grid, styled } from "@mui/material";
import { USERS_MANAGEMENT_TABS } from "@/constants";
import NavTab from "@/components/NavTab";
import { Outlet } from "react-router-dom";

export const BoxHeaderBottomInner = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: "32px",
  padding: "0 80px 24px 80px",
  [theme.breakpoints.down("md")]: {
    padding: "0 24px 24px 24px",
  },
}));

const UserManagementPage = () => {
  return (
    <Layout
      headerBottomSection={
        <BoxHeaderBottomInner>
          {USERS_MANAGEMENT_TABS.map((el) => (
            <NavTab key={el?.text} text={el?.text} navTo={el?.navTo} />
          ))}
        </BoxHeaderBottomInner>
      }
    >
      <Grid
        sx={{
          marginTop: "4px",
          position: "relative",
          zIndex: 10,
          display: "flex",
        }}
        container
        columns={12}
        columnSpacing={"24px"}
        rowSpacing={"24px"}
      >
        <Outlet />
      </Grid>
    </Layout>
  );
};

export default UserManagementPage;
