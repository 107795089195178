import React, { useEffect, useState } from "react";
import useDebouncedSearch from "@/hooks/useDebouncedSearch.ts";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  MenuItem,
  Typography,
} from "@mui/material";
import { Control, Controller, ControllerProps } from "react-hook-form";
import SVGSelectArrowDown from "@/assets/icons/SVGSelectArrowDown.tsx";
import { StyledMenu } from "@/components/styled";
import SearchInput from "@/components/SearchInput";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import { getProjectsByAccountNameReq } from "@/api/projectsApi";
import { ProjectRecord } from "@/types";
import SvgSimpleCheckMark from "@/assets/icons/SVGSimpleCheckMark.tsx";

const ProjectSelect = ({
  name,
  control,
  controlProps,
  placeholder,
}: {
  name: string;
  placeholder: string;
  control: Control<any, any>;
  controlProps?: Partial<ControllerProps>;
}) => {
  const [items, setItems] = useState([] as ProjectRecord[]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  // const isRequired = !!controlProps?.rules?.required;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { searchValue, handleDebounceSearchValue } = useDebouncedSearch(
    () => {},
  );
  useEffect(() => {
    if (open) {
      const fetchItems = async () => {
        try {
          const response = await getProjectsByAccountNameReq(searchValue);
          setItems(response?.data?.projects);
        } catch (e) {
          console.error(e);
        }
      };
      fetchItems();
    }
  }, [searchValue, open]);

  return (
    <FormControl
      component="fieldset"
      sx={{ width: "100%", paddingBottom: "24px" }}
    >
      <FormLabel component="legend">
        <Box
          sx={{
            fontSize: "12px",
            fontWeight: 500,
            color: "#373533",
            paddingBottom: "2px",
            display: "flex",
          }}
        >
          Project
          <Typography sx={{ color: "red", paddingLeft: "4px" }}>*</Typography>
        </Box>
      </FormLabel>
      <Controller
        name={name}
        control={control}
        {...controlProps}
        render={({ field: { onChange, value } }) => (
          <>
            <Button
              variant="outlinedGray"
              endIcon={<SVGSelectArrowDown style={{ color: "#4E4B48" }} />}
              sx={{
                textTransform: "none",
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
              }}
              onClick={handleClick}
            >
              {!!value ? (
                <Typography
                  variant={"subtitleRegular"}
                  sx={{
                    color: "textGray2",
                  }}
                >
                  {value?.customerName}
                </Typography>
              ) : (
                placeholder
              )}
            </Button>
            <StyledMenu
              id="basic-menu"
              anchorEl={anchorEl}
              sx={{
                "& .MuiPaper-root": { width: anchorEl?.offsetWidth },
              }}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <Box sx={{ padding: "0 16px 8px 16px" }}>
                <SearchInput onDebounce={handleDebounceSearchValue} />
              </Box>
              <Box sx={{ overflow: "auto", maxHeight: "200px" }}>
                {items?.map((el) => {
                  return (
                    <MenuItem
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        maxHeight: "48px",
                      }}
                      key={el?.id}
                      onClick={() => {
                        onChange(el);
                        handleClose();
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#4E4B48",
                          }}
                        >
                          {capitalizeFirstLetter(el?.customerName ?? "")}
                        </Typography>
                        {value?.id === el?.id && <SvgSimpleCheckMark />}
                      </Box>
                    </MenuItem>
                  );
                })}
              </Box>
            </StyledMenu>
          </>
        )}
      />
    </FormControl>
  );
};

export default ProjectSelect;
