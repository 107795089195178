import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  MenuItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SVGFilter from "@/assets/icons/SVGFilter.tsx";
import { updateUserReq } from "@/api/adminApi";
import { StyledMenu, StyledTableActionButton } from "@/components/styled";
import { capitalizeFirstLetter } from "@/utils/capitalizeFirstLetter.ts";
import SearchInput from "@/components/SearchInput";

type Props = {
  placeholder: string;
  items: { color?: string; value: string; label: string }[];
  setFilterValues: (values: string[]) => void;
};

const TableFilter = ({ placeholder, items, setFilterValues }: Props) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickItem = (value: string) => {
    const updatedSelectedItems = selectedItems.includes(value)
      ? selectedItems.filter((item) => item !== value)
      : [...selectedItems, value];
    setSelectedItems(updatedSelectedItems);
    setFilterValues(updatedSelectedItems);
  };

  const isSelected = (value: string) => selectedItems.includes(value);

  const isActive = selectedItems?.length;

  return (
    <>
      <StyledTableActionButton
        onClick={handleClick}
        variant={isActive ? "outlined" : "outlinedGray"}
      >
        <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
          <SVGFilter />
          {!downSm && (
            <Typography
              variant={"subtitleRegular"}
              sx={{
                color: "textLightGray2",
                whiteSpace: "nowrap",
              }}
            >
              {placeholder}
            </Typography>
          )}
        </Box>
      </StyledTableActionButton>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {items.map((el) => {
          const selected = isSelected(el?.value);
          return (
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                maxHeight: "48px",
              }}
              key={el.value}
              onClick={() => handleClickItem(el.value)}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Checkbox checked={selected} />
                {el?.color && (
                  <Box
                    sx={{
                      width: "14px",
                      height: "14px",
                      backgroundColor: el.color,
                      borderRadius: "99px",
                    }}
                  />
                )}
                <Typography
                  sx={{ fontSize: "16px", fontWeight: 600, color: "#4E4B48" }}
                >
                  {capitalizeFirstLetter(el.label ?? "")}
                </Typography>
              </Box>
            </MenuItem>
          );
        })}
      </StyledMenu>
    </>
  );
};

export default TableFilter;
